import Vue from 'vue'

const userStorage = {
	set: async (value) => {
		const info = await Vue.ls.get('ag-user-info')

		Vue.ls.set('ag-user-info', { ...info, ...value })
	},
	get: () => Vue.ls.get('ag-user-info'),
	remove: () => Vue.ls.remove('ag-user-info'),
	getSliderSize: () => {
		const { sliderSize } = Vue.ls.get('ag-user-info') || {}
		return sliderSize
	},
	getMenuCollapsed: () => {
		const { menuCollapsed } = Vue.ls.get('ag-user-info') || {}
		return menuCollapsed
	},
	getMerchantIndex: () => {
		const { merchantIndex } = Vue.ls.get('ag-user-info') || {}
		return merchantIndex || 0
	},
	getFcmToken: () => {
		const { fcmToken } = Vue.ls.get('ag-user-info') || {}
		return fcmToken
	},
	getFcmDevice: () => {
		const { fcmDevice } = Vue.ls.get('ag-user-info') || {}
		return fcmDevice
	},
	getProductsPageSize: () => {
		const { productsPageSize } = Vue.ls.get('ag-user-info') || {}
		return productsPageSize
	},
	getLoginMode: () => {
		const { loginMode } = Vue.ls.get('ag-user-info') || 'default'
		return loginMode
	},
	getProfilePicture: () => {
		const { profile_picture } = Vue.ls.get('ag-user-info') || null
		return profile_picture
	},
}

export default userStorage
