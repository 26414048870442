<template>
	<a-popconfirm
		:visible="visible"
		ok-text="Fechar"
		cancel-text="Cancelar"
		@confirm="handleOk"
		@cancel="cancel"
	>
		<a-icon
			slot="icon"
			type="exclamation-circle"
			style="color: orange; line-height: 24px;"
		/>
		<template v-slot:title>
			<a-row
				type="flex"
				justify="space-between"
				:gutter="16"
				align="top"
				style="padding-bottom: 8px;"
			>
				<a-col style="line-height: 28px;">
					Fechar o restaurante por
				</a-col>
				<a-col>
					<a-input-group compact>
						<a-input-number
							v-model="time"
							:min="min"
							:max="max"
							:step="step"
							style="width: 64px;"
							:auto-focus="true"
						/>
						<a-select v-model="option" style="width: 100px;" @change="handleChange">
							<a-select-option value="MINUTES">
								Minutos
							</a-select-option>
							<a-select-option value="HOURS">
								{{ hourText }}
							</a-select-option>
						</a-select>
					</a-input-group>
				</a-col>
			</a-row>
		</template>
		<slot></slot>
	</a-popconfirm>
</template>

<script>
export default {
	name: 'SetClosedTimePopconfirm',
	data() {
		return {
			option: 'MINUTES',
			time: 5,
			visible: false,
		}
	},
	computed: {
		min: function () {
			return this.option === 'MINUTES' ? 5 : 1
		},
		max: function () {
			return this.option === 'MINUTES' ? 55 : 4
		},
		step: function () {
			return this.option === 'MINUTES' ? 5 : 1
		},
		hourText: function () {
			return this.time === 1 ? 'Hora' : 'Horas'
		},
	},
	methods: {
		setShowModal() {
			this.visible = true
		},
		handleOk() {
			this.visible = false
			const closed_time = this.option === 'MINUTES'? this.time: this.time * 60
			this.$emit('save', closed_time)
		},
		cancel() {
			this.visible = false
		},
		handleChange(value) {
			this.time = value === 'MINUTES'? 5: 1
		},
	},
}
</script>