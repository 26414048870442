export default {
	appName: 'Agiliza',
	apiUrl: process.env.VUE_APP_API_URL,
	imgBaseUrl: process.env.VUE_IMG_BASE_URL,
	defaultPageSize: 100,
	oauth2: {
		client_id: process.env.VUE_APP_OAUTH2_CLIENT_ID,
		client_secret: process.env.VUE_APP_OAUTH2_CLIENT_PASSWORD,
	},
	// localstorage options
	storageOptions: {
		namespace: 'portal__',
		name: 'ls',
		storage: 'local',
	},
}
