import router from '@/router'
import { store } from '@/store'

import { ifAuthenticated } from '@/utils/authenticator'

import {
	OrdersRoutes,
	TablesRoutes,
	MenuRoutes,
	ReportsRoutes,
	SettingsRoutes,
	HelpRoutes,
	StepsFormsRoutes,
	ProductsRoutes,
	GroupsRoutes,
	HistoryOrdersRoutes,
} from './routes'

const ifAuthorized = async (to, from, next) => {
	if (!store.getters['account/isAuth']) next('/login')

	if (!store.getters['ux/onlineStatus']) next()
	else {
		const auth = to.matched.filter((record) => record.meta.authorization)
		if (auth.length > 0) {
			if (store.getters['merchants/accessType'] === undefined) {
				await store.dispatch('merchants/fetchMerchants')
			}

			auth
				.pop()
				.meta.authorization.includes(
					store.getters['merchants/accessType']
				)
				? next()
				: next({ path: '/home' })
		} else next()
	}
}

const routes = [
	{
		path: '/home',
		component: () => import('./pages/basicLayout.vue'),
		name: 'home',
		meta: {
			title: '',
			icon: 'home',
			authorization: ['OWNER', 'MANAGER', 'WAITER'],
		},
		redirect: '/home/orders',
		beforeEnter: ifAuthorized,
		children: [
			OrdersRoutes,
			TablesRoutes,
			HistoryOrdersRoutes,
			MenuRoutes,
			ProductsRoutes,
			GroupsRoutes,
			ReportsRoutes,
			SettingsRoutes,
			HelpRoutes,
		],
	},
	{
		path: '/merchant',
		component: () => import('@/modules/core/pages/SimpleLayout.vue'),
		name: 'merchant',
		redirect: '/merchant/steps',
		beforeEnter: ifAuthenticated,
		children: [StepsFormsRoutes],
	},
]

router.addRoutes(routes)
