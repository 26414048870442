const GroupsRoutes = {
	path: 'groups',
	name: 'groups',
	component: () => import('../pages/groupsPage/groupsPage.vue'),
	meta: {
		title: 'Grupos operacionais',
		icon: 'hdd',
		authorization: ['OWNER', 'MANAGER'],
	},
}

export default GroupsRoutes
