import { axios } from '@/utils'
import detectClient from '@/utils/detectClient'
import { store } from '@/store'

export default class MessagingService {
	constructor(messaging) {
		this.messaging = messaging

		this.messaging.usePublicVapidKey(
			'BGNjqoHphvZxO2KCG2qg5bVaehcmEjADyKyfmWToZ5tu2SCWpCh8t6QnqPszI3yHqO0Nk1d1TtTc9FepIuJqrVw'
		)
		this.messaging.onTokenRefresh(function () {
			this.messaging
				.getToken()
				.then(function (refreshedToken) {
					this.sendTokenToServer(refreshedToken)
				})
				.catch(function (err) {
					console.error('Unable to retrieve refreshed token ', err)
				})
		})
	}

	onMessaging() {
		this.messaging.onMessage(function (payload) {
			store.dispatch('notifications/setNotification', payload)
		})
	}

	requestPermission() {
		return new Promise((resolve) => {
			try {
				this.messaging
					.requestPermission()
					.then(() => {
						detectClient()
						return resolve()
					})
					.catch((error) => {
						if (error.code === 'messaging/permission-blocked') {
							console.error(
								'Please Unblock Notification Request Manually'
							)
						} else if (
							error.code === 'messaging/permission-default'
						) {
							alert(
								'As notificações precisam ser habilitadas manualmente!'
							)
						} else {
							console.error('Error Occurred', error)
						}
					})
			} catch (err) {
				console.error('Unable to get permission to notify.', err)
			}
		})
	}

	async getTokenAsync() {
		try {
			let currentToken = await this.messaging.getToken()
			if (currentToken) {
				await this.sendTokenToServerAsync(currentToken)
				return currentToken
			} else {
				console.error(
					'No Instance ID token available. Request permission to generate one.'
				)
				this.setTokenSentToServerFlg('')
				return false
			}
		} catch (err) {
			console.error('An error occurred while retrieving token. ', err)
			this.setTokenSentToServerFlg('')
			return false
		}
	}

	async deleteTokenAsync() {
		try {
			this.setTokenSentToServerFlg('')
			let currentToken = await this.messaging.getToken()
			await this.messaging.deleteToken(currentToken)
		} catch (err) {
			console.error('Unable to delete token. ', err)
		}
	}

	async sendTokenToServerAsync(currentToken) {
		try {
			if (!this.isTokenSentToServer(currentToken))
				this.sendTokenToServer(currentToken)
		} catch (err) {
			console.error('Unable to send token to server', err)
		}
	}

	isTokenSentToServer(token) {
		return store.getters['account/fcmDevice'] === token
	}

	async setTokenSentToServerFlg(token, device = '') {
		store.dispatch(
			'account/setDevice',
			{ fcmToken: token, fcmDevice: device },
			{ root: true }
		)
	}

	async sendTokenToServer(token) {
		const { browser } = window.jscd

		const data = {
			name: browser,
			application_id: 'management_web',
			registration_id: token,
		}

		try {
			const res = await axios.post('/v1/devices/', data)
			await this.registerDevice(res.id)
			this.setTokenSentToServerFlg(token, res.id)
		} catch (err) {
			console.error('Unable to send token to server', err)
		}
	}

	async registerDevice(device_id) {
		const merchantId = store.getters['merchants/selectedMerchantId']

		await axios.get(`/v1/merchants/${merchantId}/`, {
			headers: {
				'device-uuid': device_id,
			},
		})
	}
}
