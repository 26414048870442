var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-row',[(_vm.user.password_temp)?_c('a-alert',{staticStyle:{"margin-bottom":"15px"},attrs:{"type":"warning","message":"Você está utilizando uma senha temporária. Defina uma nova para uma maior segurança.","show-icon":"","closable":""}}):_vm._e()],1),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":"10"}},[_c('h2',[_vm._v("Senha")]),_c('p',[_vm._v(" Após a atualização de senha, você será redirecionado para a página de login onde poderá entrar novamente informando a nova senha. ")])]),_c('a-col',{staticStyle:{"padding-right":"32px"},attrs:{"span":"14"}},[_c('h3',[_vm._v("Mudar senha atual")]),_c('a-form',{attrs:{"form":_vm.form,"layout":"vertical"},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)}}},[_c('a-row',[_c('a-col',[_c('a-form-item',{attrs:{"label":"Senha atual","extra":"A senha atual é necessária para mudar para uma nova."}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
									'old_password',
									{
										rules: [
											{
												required: true,
												message:
													'Por favor, informe a senha atual!',
											} ],
									} ]),expression:"[\n\t\t\t\t\t\t\t\t\t'old_password',\n\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\t\t\tmessage:\n\t\t\t\t\t\t\t\t\t\t\t\t\t'Por favor, informe a senha atual!',\n\t\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t]"}],attrs:{"placeholder":"Digite aqui sua senha atual"}})],1)],1)],1),_c('a-row',[_c('a-col',[_c('a-form-item',{attrs:{"label":"Nova senha"}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
									'new_password',
									{
										rules: [
											{
												required: true,
												message:
													'Por favor, informe a nova senha!',
											},
											{
												min: 6,
												message:
													'Tamanho mínimo de 6 caracteres!',
											} ],
									} ]),expression:"[\n\t\t\t\t\t\t\t\t\t'new_password',\n\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\t\t\tmessage:\n\t\t\t\t\t\t\t\t\t\t\t\t\t'Por favor, informe a nova senha!',\n\t\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\t\tmin: 6,\n\t\t\t\t\t\t\t\t\t\t\t\tmessage:\n\t\t\t\t\t\t\t\t\t\t\t\t\t'Tamanho mínimo de 6 caracteres!',\n\t\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t]"}],attrs:{"placeholder":"Digite aqui sua nova senha"}})],1)],1)],1),_c('a-row',{staticStyle:{"padding":"25px 0"},attrs:{"type":"flex","justify":"start","gutter":16}},[_c('a-col',[_c('a-form-item',[_c('a-button',{staticStyle:{"width":"120px"},attrs:{"type":"primary","html-type":"submit","icon":"save"}},[_vm._v(" Salvar ")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }