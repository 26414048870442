<template>
	<div class="container">
		<a-row class="header" type="flex" justify="end">
			<a-upload
				name="file"
				:multiple="true"
				:show-upload-list="false"
				:before-upload="beforeUpload"
				accept=".jpg,.jpeg,.png"
				:custom-request="() => {}"
			>
				<a-button class="buttons" type="dashed" icon="upload">
					Click para adicionar
				</a-button>
			</a-upload>
			<a-button
				v-if="isRemoving"
				class="buttons"
				icon="close"
				@click="onCancel"
			>
				Cancelar
			</a-button>
			<a-button
				v-else-if="showDelete && !isRemoving"
				class="buttons"
				icon="delete"
				@click="() => (isRemoving = !isRemoving)"
			>
				Remover
			</a-button>
		</a-row>
		<div v-if="isLoading || loading" class="loading-spin">
			<a-spin>
				<a-icon
					slot="indicator"
					type="loading"
					style="font-size: 50px;"
					spin
				/>
			</a-spin>
		</div>
		<div v-else-if="images.length > 0">
			<p v-if="isRemoving" class="message">
				Marque as imagens que deseja remover
			</p>
			<a-row type="flex" justify="start" class="body" :gutter="[8, 8]">
				<a-col v-for="img in images" :key="img.id">
					<a-card
						:class="[
							'card',
							checkeds.includes(img.id) ? 'selected' : '',
						]"
						:style="`background-image: url(${img.image})`"
					>
						<a-checkbox
							v-if="isRemoving"
							class="checkbox"
							@change="(e) => handleCheckbox(img.id, e)"
						/>
					</a-card>
				</a-col>
			</a-row>
			<a-row
				v-if="isRemoving"
				type="flex"
				justify="center"
				class="footer"
			>
				<a-button
					type="primary"
					icon="delete"
					style="width: 200px;"
					:disabled="count === 0"
					@click="onDelete"
				>
					Remover {{ `(${count})` }}
				</a-button>
			</a-row>
		</div>
		<a-empty v-else :image="simpleImage" style="margin-top: 56px;">
			<span slot="description">Nenhuma imagem adcionada</span>
		</a-empty>
	</div>
</template>

<script>
import { merchantService } from '@/modules/partner/services'
import { mapGetters } from 'vuex'
import { Empty } from 'ant-design-vue'

export default {
	name: 'ImagesBoard',
	props: {
		images: {
			type: Array,
			required: true,
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			checkeds: [],
			count: 0,
			isRemoving: false,
			isLoading: false,
		}
	},
	computed: {
		...mapGetters({
			selectedMerchantId: 'merchants/selectedMerchantId',
		}),
		showDelete: function () {
			return this.images.length > 0
		},
	},
	beforeCreate() {
		this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE
	},
	methods: {
		handleCheckbox(id, e) {
			if (e.target.checked) this.checkeds.push(id)
			else this.checkeds = this.checkeds.filter((i) => i !== id)

			this.count = this.checkeds.length
		},
		onCancel() {
			this.checkeds = []
			this.count = 0
			this.isRemoving = false
		},
		async beforeUpload(files) {
			try {
				const payload = {
					merchantId: this.selectedMerchantId,
					files: [files],
				}
				this.isLoading = true
				await merchantService.uploadImages(payload)
				this.$emit('update')
				return false
			} catch (error) {
				//
			} finally {
				this.isLoading = false
			}

			return false
		},
		async onDelete() {
			try {
				this.isLoading = true
				await merchantService.deleteImages({
					merchantId: this.selectedMerchantId,
					delImages: this.checkeds,
				})
				this.$emit('update')

				this.isRemoving = false
				this.count = 0
			} catch (error) {
				console.log(error)
			} finally {
				this.isLoading = false
			}
		},
	},
}
</script>

<style lang="less" scoped>
@import url('@/config/globalStyles.less');
.container {
	padding: 0 20px 20px 0;

	.header {
		margin: -40px 15px 15px 0px;
		padding-bottom: 6px;
		border-bottom: 1px solid hsv(0, 0, 85%);

		.buttons {
			margin-left: 10px;
			float: right;
		}
	}
	.message {
		text-align: center;
		margin: 24px auto;
		color: rgba(0, 0, 0, 0.65);
	}
	.body {
		flex-wrap: wrap;

		.card {
			padding: 8px;
			width: 200px;
			height: 200px;
			border-radius: 5px;
			overflow: hidden;
			background-size: cover;
			background-position: center;
			background-clip: content-box;

			.checkbox {
				position: absolute;
				top: 12px;
				right: 16px;
			}
		}
	}
	.footer {
		margin: 20px 0 10px 0;
	}

	.selected {
		border-color: @primary;
		box-shadow: 0px 0px 12px 5px rgba(245, 125, 49, 0.35);
	}
}
</style>
