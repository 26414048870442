import { render, staticRenderFns } from "./settingsProfilePage.vue?vue&type=template&id=51ad0e35&scoped=true&"
import script from "./settingsProfilePage.vue?vue&type=script&lang=js&"
export * from "./settingsProfilePage.vue?vue&type=script&lang=js&"
import style0 from "./settingsProfilePage.vue?vue&type=style&index=0&id=51ad0e35&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51ad0e35",
  null
  
)

export default component.exports