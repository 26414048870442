<template>
	<div>
		<a-row v-if="show" class="extra">A partir de</a-row>
		<a-row>
			<span class="prefix">R$</span>
			<span class="value" :style="{ fontSize: `${size}px` }">
				{{ formated }}
			</span>
		</a-row>
	</div>
</template>

<script>
import { toCurrencyFormat, currencytoNumber } from '@/utils/utils'

export default {
	name: 'MoneyValue',
	props: {
		value: {
			type: [String, Number],
			default: '0.00',
		},
		extra: {
			type: String,
			default: '0.00',
		},
		size: {
			type: Number,
			default: 18,
		},
	},
	computed: {
		show: function () {
			return currencytoNumber(this.extra) > 0.0
		},
		formated: function () {
			const value = parseFloat(this.value) + parseFloat(this.extra)

			return toCurrencyFormat(value)
		},
		styleValue: function () {
			return { 'font-size': `${this.size}px` }
		},
	},
}
</script>

<style lang="less" scoped>
@import url('@/config/globalStyles.less');

.prefix {
	font-size: 10px;
	font-weight: normal;
	margin: 0 2px;
	color: rgba(0, 0, 0, 0.55);
}
.value {
	font-weight: 501;
	color: #000;
}
.extra {
	font-size: 12px;
	font-weight: bolder;
	color: rgba(0, 0, 0, 0.4);
	line-height: 1;
}
</style>
