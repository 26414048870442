var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',{staticClass:"header"},[_vm._v("Cadastrar conta")]),_c('div',{staticClass:"body"},[_c('a-form',{staticClass:"form",attrs:{"form":_vm.form},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)}}},[_c('a-form-item',{staticStyle:{"margin-bottom":"8px"},attrs:{"label":"Nome","has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'first_name',
						{
							rules: [
								{
									required: true,
									message: 'Por favor, informe um nome',
								} ],
						} ]),expression:"[\n\t\t\t\t\t\t'first_name',\n\t\t\t\t\t\t{\n\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\tmessage: 'Por favor, informe um nome',\n\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t],\n\t\t\t\t\t\t},\n\t\t\t\t\t]"}],attrs:{"placeholder":"Informe o seu primeiro nome"}})],1),_c('a-form-item',{staticStyle:{"margin-bottom":"8px"},attrs:{"label":"Sobrenome","has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'last_name',
						{
							rules: [
								{
									required: true,
									message:
										'Por favor, informe seu sobrenome',
								} ],
						} ]),expression:"[\n\t\t\t\t\t\t'last_name',\n\t\t\t\t\t\t{\n\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\tmessage:\n\t\t\t\t\t\t\t\t\t\t'Por favor, informe seu sobrenome',\n\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t],\n\t\t\t\t\t\t},\n\t\t\t\t\t]"}],attrs:{"placeholder":"Informe o seu sobrenome"}})],1),_c('a-form-item',{staticStyle:{"margin-bottom":"8px"},attrs:{"label":"Email","has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'email',
						{
							rules: [
								{
									type: 'email',
									message: 'Este email não é válido!',
								},
								{
									required: true,
									message: 'Por favor, informe um email!',
								} ],
						} ]),expression:"[\n\t\t\t\t\t\t'email',\n\t\t\t\t\t\t{\n\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\ttype: 'email',\n\t\t\t\t\t\t\t\t\tmessage: 'Este email não é válido!',\n\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\tmessage: 'Por favor, informe um email!',\n\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t],\n\t\t\t\t\t\t},\n\t\t\t\t\t]"}],attrs:{"placeholder":"Informe o email de acesso para o seu usuário"}})],1),_c('a-form-item',{staticStyle:{"margin-bottom":"16px"},attrs:{"label":"Senha"}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'password',
						{
							rules: [
								{
									required: true,
									message:
										'Por favor, informe uma senha!',
								},
								{
									min: 6,
									message:
										'Tamanho mínimo de 6 caracteres!',
								} ],
						} ]),expression:"[\n\t\t\t\t\t\t'password',\n\t\t\t\t\t\t{\n\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\tmessage:\n\t\t\t\t\t\t\t\t\t\t'Por favor, informe uma senha!',\n\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\tmin: 6,\n\t\t\t\t\t\t\t\t\tmessage:\n\t\t\t\t\t\t\t\t\t\t'Tamanho mínimo de 6 caracteres!',\n\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t],\n\t\t\t\t\t\t},\n\t\t\t\t\t]"}],attrs:{"placeholder":"Forneça uma senha de acesso para o seu usuário"}})],1),_c('a-button',{staticStyle:{"margin-top":"32px"},attrs:{"type":"primary","html-type":"submit","loading":_vm.isLoading,"block":""}},[_vm._v(" Continuar ")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }