import { axios } from '@/utils'
import config from '@/config/defaultSettings'
import { store } from '@/store'
import qs from 'qs'

const requestConfig = {
	headers: {
		'content-type': 'application/x-www-form-urlencoded;',
	},
}

export default {
	auth,
	gauth,
	register,
	logout,
	refreshToken,
	getUser,
	acceptTerms,
	updateAvatar,
	updateUser,
	setPassword,
	forgotPassword,
	getDevices,
	revokeDeviceAccess,
}

async function auth(username, password) {
	const data = qs.stringify({
		grant_type: 'password',
		client_id: config.oauth2.client_id,
		client_secret: config.oauth2.client_secret,
		username: username,
		password: password,
	})

	return await axios.post('/auth/token/', data, requestConfig)
}

async function gauth(token) {
	const data = qs.stringify({
		grant_type: 'convert_token',
		client_id: config.oauth2.client_id,
		client_secret: config.oauth2.client_secret,
		backend: 'google-oauth2',
		token,
	})

	return await axios.post('/auth/convert-token/', data, requestConfig)
}

async function getUser() {
	return await axios.get('/v1/me/')
}

async function acceptTerms() {
	return await axios.post('/v1/me/accept_partner_terms/')
}

async function register(values) {
	const data = qs.stringify({
		grant_type: 'password',
		client_id: config.oauth2.client_id,
		client_secret: config.oauth2.client_secret,
		first_name: values.first_name,
		last_name: values.last_name,
		email: values.email,
		password: values.password,
	})

	return await axios.post('/v1/register/', data, requestConfig)
}

async function logout(token) {
	const data = qs.stringify({
		grant_type: 'refresh_token',
		client_id: config.oauth2.client_id,
		client_secret: config.oauth2.client_secret,
		token,
	})

	const res = await axios.post('/auth/revoke-token/', data, requestConfig)
	delete axios.defaults.headers.common['Authorization']

	return res
}

async function refreshToken(token) {
	const data = qs.stringify({
		client_id: config.oauth2.client_id,
		client_secret: config.oauth2.client_secret,
		grant_type: 'refresh_token',
		refresh_token: token,
	})

	delete axios.defaults.headers.common['Authorization']
	return await axios.post('/auth/token/', data, requestConfig)
}

async function updateUser(data) {
	return await axios.patch('/v1/me/', data)
}

async function updateAvatar(img) {
	if (img) {
		let data = new FormData()
		const substrings = img.type.split('/')
		const fileName = +new Date() + '.' + substrings[1]
		data.append(
			'profile_picture',
			new File([img], fileName, {
				type: img.type,
				lastModified: Date.now(),
			})
		)

		return await axios.patch('/v1/me/', data, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
	} else {
		return await axios.patch('/v1/me/', { profile_picture: null })
	}
}

async function setPassword(values) {
	const { old_password, new_password } = values

	const data = qs.stringify({
		client_id: config.oauth2.client_id,
		client_secret: config.oauth2.client_secret,
		old_password: old_password,
		new_password: new_password,
	})

	return await axios.post('/v1/me/set_password/', data, requestConfig)
}

async function forgotPassword(email) {
	const data = qs.stringify({
		client_id: config.oauth2.client_id,
		client_secret: config.oauth2.client_secret,
		email: email,
	})

	return await axios.post('/v1/forgot_password/', data, requestConfig)
}

async function getDevices() {
	const device = store.getters['account/fcmDevice']

	return await axios.get(`/v1/devices`, {
		headers: {
			'device-uuid': device,
		},
	})
}

async function revokeDeviceAccess(device_id) {
	const device = store.getters['account/fcmDevice']

	return await axios.delete(`/v1/devices/${device_id}`, {
		headers: {
			'device-uuid': device,
		},
	})
}
