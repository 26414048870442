const HelpRoutes = {
	path: 'help',
	name: 'help',
	component: () => import('../pages/helpPage/helpPage.vue'),
	meta: {
		title: 'Ajuda',
		icon: 'question-circle',
		authorization: ['OWNER', 'MANAGER', 'WAITER'],
	},
}

export default HelpRoutes
