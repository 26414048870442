import axios from 'axios'
import { store } from '@/store'
import configs from '@/config/defaultSettings'

var http = axios.create({
	baseURL: configs.apiUrl,
	timeout: 10000,
})

const err = async (error) => {
	if (error.response) {
		const { data, status } = error.response

		if (status === 401) {
			if (data && data.code === 'unauthorized') return Promise.resolve()

			try {
				await store.dispatch('account/refreshToken')
			} catch (err) {
				await store.dispatch('account/logout')
			} finally {
				location.reload()
			}
		} else if ([400, 403, 404, 405, 429].includes(status)) {
			if (data && data.error === 'invalid_grant') return Promise.reject()
			else return Promise.reject(data)
		} else if (status === 500) {
			console.error(error.response)
		}
	}

	return Promise.reject(error)
}

http.interceptors.request.use((config) => {
	const token = store.getters['account/accessToken']

	if (token) {
		config.headers['Authorization'] = 'Bearer ' + token
	}
	return config
}, err)

http.interceptors.response.use((response) => response.data, err)

export { http as axios }
