import moment from 'moment'

const state = {
	pressed: false,
	online: navigator.onLine,
	historySelectedTab: 'HISTORY',
	historyRangeDates: [],
	groupPageFilterOption: 'name',
	groupPageSortMode: 'name',
	ratingsRangeDates: [],
	ratingsFilterOption: 'all',
}

const mutations = {
	toggle(state) {
		state.pressed = !state.pressed
	},
	updateOnlineStatus(state, e) {
		state.online = e.type === 'online'
	},
	setHistorySelectedTab(state, value) {
		state.historySelectedTab = value
	},
	setHistoryRangeDates(state, dates) {
		state.historyRangeDates = [...dates]
	},
	setGroupPageFilterOption(state, value) {
		state.groupPageFilterOption = value
	},
	setGroupPageSortMode(state, value) {
		state.groupPageSortMode = value
	},
	setRatingsRangeDates(state, dates) {
		state.ratingsRangeDates = dates
	},
	setRatingsFilterOption(state, value) {
		state.ratingsFilterOption = value
	},
}

const getters = {
	collapsed: (state) => state.pressed,
	onlineStatus: (state) => state.online,
	historySelectedTab: (state) => state.historySelectedTab,
	groupPageSortMode: (state) => state.groupPageSortMode,
	groupPageFilterOption: (state) => state.groupPageFilterOption,
	ratingsFilterOption: (state) => state.ratingsFilterOption,
	historyRangeDates: (state) => {
		const range = state.historyRangeDates
		return range.map((date) => moment(date))
	},
	ratingsRangeDates: (state) => {
		const range = state.ratingsRangeDates
		return range.map((date) => moment(date))
	},
}

export const ux = {
	namespaced: true,
	state,
	mutations,
	getters,
}
