<template>
	<div>
		<div class="header">Usar conta</div>
		<div>
			<a-form :form="form" @submit.prevent="handleSubmit">
				<a-form-item has-feedback>
					<a-input
						v-decorator="[
							'email',
							{
								rules: [
									{
										type: 'email',
										message: 'Este email não é válido!',
									},
									{
										required: true,
										message:
											'Por favor, informe o seu email!',
									},
								],
							},
						]"
						type="email"
						placeholder="Email"
					>
						<a-icon
							slot="prefix"
							type="mail"
							style="color: rgba(0, 0, 0, 0.25);"
						/>
					</a-input>
				</a-form-item>
				<a-form-item>
					<a-input-password
						v-decorator="[
							'password',
							{
								rules: [
									{
										required: true,
										message: 'Por favor, informe a senha!',
									},
								],
							},
						]"
						type="password"
						placeholder="senha"
					>
						<a-icon
							slot="prefix"
							type="lock"
							style="color: rgba(0, 0, 0, 0.25);"
						/>
					</a-input-password>
				</a-form-item>
				<a-form-item>
					<a-checkbox
						v-decorator="[
							'remember',
							{
								valuePropName: 'checked',
								initialValue: true,
							},
						]"
					>
						Salvar acesso
					</a-checkbox>
				</a-form-item>
				<a-button
					type="primary"
					html-type="submit"
					:loading="isLoading"
					block
				>
					Continuar
				</a-button>
			</a-form>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'UseAccountForm',
	data() {
		return {
			form: this.$form.createForm(this),
		}
	},
	computed: {
		...mapGetters({
			isLoading: 'account/isLoading',
		}),
	},
	methods: {
		...mapActions('account', ['auth']),
		handleSubmit() {
			this.form.validateFieldsAndScroll(async (err, values) => {
				if (!err) {
					try {
						const payload = {
							username: values.email,
							password: values.password,
							remember: values.remember,
						}

						await this.auth(payload)
						this.$router.push({ name: 'partner-info' })
					} catch (_) {
						//
					}
				}
			})
		},
	},
}
</script>

<style lang="less" scoped>
.header {
	margin-bottom: 24px;
	font-size: 22px;
	font-weight: 501;
	text-align: center;
	color: rgba(0, 0, 0, 0.75);
}
</style>
