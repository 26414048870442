/* eslint-disable no-unused-vars */
import notification from 'ant-design-vue/es/notification'
import { store } from '@/store'
import config from '@/config/defaultSettings'

function loadOrders(order_type) {
	const data = {
		merchantId: store.getters['merchants/selectedMerchantId'],
		params: {
			order_type,
			page: 1,
			page_size: config.defaultPageSize,
		},
	}

	store.dispatch('orders/getOrders', data)
}

function showNotification(notific) {
	const { title, body } = notific

	notification.open({
		message: title,
		description: body,
		duration: 6,
	})
}

const state = {
	notifications: [],
}

const actions = {
	setNotification({ commit, dispatch }, payload) {
		const { data, notification } = payload

		switch (data.action) {
			case 'ORDERS':
				showNotification(notification)
				commit('PUSH_NOTIFICATION', {
					message: notification.title,
					description: notification.body,
					state: false,
					type: 'PENDING',
					icon: 'form',
				})

				loadOrders('PENDING')
				loadOrders('ANALYZED')
				break
			case 'PAYING':
				showNotification(notification)
				commit('PUSH_NOTIFICATION', {
					message: notification.title,
					description: notification.body,
					state: false,
					type: 'PENDING',
					icon: 'credit-card',
				})

				loadOrders('PENDING')
				loadOrders('ANALYZED')
				break
			case 'PAID':
				showNotification(notification)
				commit('PUSH_NOTIFICATION', {
					message: notification.title,
					description: notification.body,
					state: false,
					type: 'ANALYZED',
					icon: 'credit-card',
				})

				loadOrders('PENDING')
				loadOrders('ANALYZED')
				break
			case 'PLACE':
				showNotification(notification)
				break
			case 'OPEN':
			case 'CLOSE':
				showNotification(notification)
				dispatch('merchants/updateMerchantTemp', data.merchant_id, {
					root: true,
				})
				break
		}
	},
	resetNotifications({ commit }, type) {
		commit('RESET_NOTIFICATIONS', type)
	},
}

const mutations = {
	PUSH_NOTIFICATION(state, payload) {
		state.notifications.push(payload)
	},
	RESET_NOTIFICATIONS(state, type) {
		state.notifications = state.notifications.filter(
			(notif) => notif.type !== type
		)
	},
}

const getters = {
	notifications: (state) => state.notifications,
	countNotifications: (state) => state.notifications.length,
}

export const notifications = {
	namespaced: true,
	state,
	actions,
	mutations,
	getters,
}
