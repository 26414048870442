<template>
	<div>
		<a-row v-if="!onlineStatus" class="offline">
			<a-col :span="2">
				<a-avatar
					:size="56"
					icon="disconnect"
					:style="{ backgroundColor: '#fefefe', color: '#ff5555' }"
				/>
			</a-col>
			<a-col>
				<h4>Computador desconectado</h4>
				<p>
					Certifique-se que seu computador está conectado à internet
				</p>
			</a-col>
		</a-row>
		<a-row v-if="showBackOnline" class="online" align="middle">
			<a-col :span="2">
				<a-avatar
					:size="56"
					icon="loading"
					:style="{ backgroundColor: '#fefefe', color: '#2aff80' }"
				/>
			</a-col>
			<a-col>
				<h4>Conectando...</h4>
			</a-col>
		</a-row>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'OfflineAlert',
	data() {
		return {
			showBackOnline: false,
		}
	},
	computed: {
		...mapGetters({
			onlineStatus: 'ux/onlineStatus',
		}),
	},
	watch: {
		onlineStatus(value) {
			if (value) {
				this.showBackOnline = true
				setTimeout(() => {
					this.showBackOnline = false
				}, 2000)
			}
		},
	},
}
</script>

<style lang="less" scoped>
.offline {
	margin: 8px 0;
	padding: 8px 16px;
	background-color: #ff5555;
	color: seashell;
	border-radius: 5px;

	h4 {
		color: seashell;
	}
}

.online {
	margin: 8px 0;
	padding: 8px 16px;
	background-color: #2aff80;
	color: seashell;
	border-radius: 5px;

	h4 {
		padding-top: 16px;
	}
}
</style>
