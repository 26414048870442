<template>
	<a-row type="flex" justify="space-around" align="middle" class="container">
		<div class="loading-container">
			<a-row
				type="flex"
				justify="space-around"
				align="middle"
				class="image"
			>
				<img src="img/loading-logo.png" alt="" width="45" height="42" />
			</a-row>
			<a-row
				type="flex"
				justify="space-around"
				align="middle"
				class="progress-bar"
			>
				<a-progress
					:stroke-color="{
						from: '#ff7e5f',
						to: '#feb47b',
					}"
					:percent="progress"
					:stroke-width="5"
					status="active"
					:show-info="false"
					style="height: 4px;"
				/>
			</a-row>
		</div>
	</a-row>
</template>

<script>
import { mapGetters } from 'vuex'
const stops = [25, 55, 85, 100]

export default {
	name: 'LoadingProgress',
	data() {
		return {
			progress: 0,
		}
	},
	computed: {
		...mapGetters({
			onlineStatus: 'ux/onlineStatus',
		}),
	},
	watch: {
		onlineStatus(online) {
			if (online) this.laodBar()
		},
	},
	created() {
		if (this.onlineStatus) this.laodBar()
	},
	methods: {
		async laodBar() {
			await stops.forEach((partial, index) =>
				setTimeout(() => {
					this.progress = partial
					if (index === 3) setTimeout(() => this.$emit('done'), 500)
				}, index * 1200)
			)
		},
	},
}
</script>

<style lang="less" scoped>
.container {
	width: 100%;
	height: 100%;
	padding-top: 0 !important;

	.loading-container {
		width: 380px;
		flex-direction: column;

		.image {
			widows: 100%;
		}

		.progress-bar {
			width: 80%;
			margin: 32px auto;
		}
	}
}
</style>
