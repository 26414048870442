<template>
	<a-layout-footer class="footer">
		<a-row class="copyright">
			<a-icon type="copyright" /> Copyright
			<span>{{ appName }}</span>
			{{ new Date().getFullYear() }}
		</a-row>
	</a-layout-footer>
</template>

<script>
import config from '@/config/defaultSettings'

export default {
	name: 'BasicFooter',
	data: function () {
		return {
			appName: config.appName,
		}
	},
}
</script>

<style lang="less" scoped>
.footer {
	background: #515151;
	color: #ccc;
	text-align: center;
	border-top: 1px solid #eaecef;
	margin-top: 50px;

	.copyright {
		// color: rgba(0, 0, 0, 0.45);
		font-size: 13px;
	}
}
</style>
