import { axios } from '@/utils/axios'
const urlBase = '/v1/merchants/'

export default {
	getAll,
	getTable,
	editTable,
	switchTable,
}

async function getAll(merchantId) {
	return await axios.get(`${urlBase}${merchantId}/places/`)
}

async function getTable(payload) {
	const { merchantId, tableId } = payload

	return await axios.get(`${urlBase}${merchantId}/places/${tableId}/`)
}

async function editTable(payload) {
	const { merchantId, tableId, name } = payload

	return await axios.patch(`${urlBase}${merchantId}/places/${tableId}/`, {
		name,
	})
}

async function switchTable(payload) {
	const { merchantId, tableId, ordersIds } = payload
	const data = [...ordersIds]

	return await axios.post(
		`${urlBase}${merchantId}/places/${tableId}/transfer_orders/`,
		data
	)
}
