<template>
	<a-popover
		v-model="visible"
		trigger="click"
		placement="bottomRight"
		overlay-class-name="header-notice-wrapper"
		:get-popup-container="() => $refs.noticeRef.parentElement"
		:auto-adjust-overflow="true"
		:arrow-point-at-center="true"
		:overlay-style="{ width: '450px', top: '50px' }"
	>
		<template slot="content">
			<a-spin :spinning="isLoading">
				<a-list v-if="count > 0">
					<a-list-item
						v-for="(notice, index) in notifications"
						:key="index"
					>
						<router-link
							:to="getLink(notice.type)"
							@click="() => (visible = false)"
						>
							<a-list-item-meta :title="notice.description">
								<a-avatar
									slot="avatar"
									style="
										background-color: white;
										color: #3574b6;
									"
									:icon="notice.icon"
								/>
							</a-list-item-meta>
						</router-link>
					</a-list-item>
				</a-list>
				<div v-else>
					<a-row type="flex" justify="center">
						<a-icon
							type="inbox"
							style="font-size: 64px; color: rgba(0, 0, 0, 0.15);"
						/>
					</a-row>
					<a-row type="flex" justify="center">
						<span style="color: rgba(0, 0, 0, 0.25);">
							Sem notificações
						</span>
					</a-row>
				</div>
			</a-spin>
		</template>
		<span ref="noticeRef" class="header-notice">
			<a-badge :count="count">
				<a-icon style="font-size: 20px; color: #414141;" type="bell" />
			</a-badge>
		</span>
	</a-popover>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'NoticeIcon',
	data() {
		return {
			visible: false,
		}
	},
	computed: {
		...mapGetters({
			isLoading: 'orders/isLoading',
			count: 'notifications/countNotifications',
			notifications: 'notifications/notifications',
		}),
	},
	methods: {
		getLink(type) {
			return type === 'PENDING' ? '/orders/pending/' : '/orders/analyzed'
		},
	},
}
</script>

<style lang="less" scoped>
.header-notice-wrapper {
	top: 50px !important;
}
</style>
<style lang="less" scoped>
.header-notice {
	display: inline-block;
	transition: all 0.3s;

	span {
		vertical-align: initial;
	}
}
</style>
