<template>
	<div>
		<a-row>
			<a-alert
				v-if="user.password_temp"
				type="warning"
				message="Você está utilizando uma senha temporária. Defina uma nova para uma maior segurança."
				show-icon
				closable
				style="margin-bottom: 15px;"
			/>
		</a-row>
		<a-row :gutter="24">
			<a-col span="10">
				<h2>Senha</h2>
				<p>
					Após a atualização de senha, você será redirecionado para a
					página de login onde poderá entrar novamente informando a
					nova senha.
				</p>
			</a-col>
			<a-col span="14" style="padding-right: 32px;">
				<h3>Mudar senha atual</h3>
				<a-form
					:form="form"
					layout="vertical"
					@submit.prevent="handleSubmit"
				>
					<a-row>
						<a-col>
							<a-form-item
								label="Senha atual"
								extra="A senha atual é necessária para mudar para uma nova."
							>
								<a-input-password
									v-decorator="[
										'old_password',
										{
											rules: [
												{
													required: true,
													message:
														'Por favor, informe a senha atual!',
												},
											],
										},
									]"
									placeholder="Digite aqui sua senha atual"
								/>
							</a-form-item>
						</a-col>
					</a-row>
					<a-row>
						<a-col>
							<a-form-item label="Nova senha">
								<a-input-password
									v-decorator="[
										'new_password',
										{
											rules: [
												{
													required: true,
													message:
														'Por favor, informe a nova senha!',
												},
												{
													min: 6,
													message:
														'Tamanho mínimo de 6 caracteres!',
												},
											],
										},
									]"
									placeholder="Digite aqui sua nova senha"
								/>
							</a-form-item>
						</a-col>
					</a-row>
					<a-row
						type="flex"
						justify="start"
						style="padding: 25px 0;"
						:gutter="16"
					>
						<a-col>
							<a-form-item>
								<a-button
									type="primary"
									style="width: 120px;"
									html-type="submit"
									icon="save"
								>
									Salvar
								</a-button>
							</a-form-item>
						</a-col>
					</a-row>
				</a-form>
			</a-col>
		</a-row>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'SettingsSecutityPage',
	data() {
		return {
			form: this.$form.createForm(this),
		}
	},
	computed: {
		...mapGetters({
			user: 'account/user',
		}),
	},
	methods: {
		...mapActions('account', ['setPassword', 'forgotPassword', 'logout']),
		handleSubmit() {
			this.form.validateFields(async (err, values) => {
				if (!err) {
					try {
						this.$message.loading({
							content: 'Atualizando...',
							key: 'updatable',
						})

						await this.setPassword(values)
						await this.logout()
						location.reload()
					} catch (error) {
						this.$message.destroy()
					}
				}
			})
		},
		async handleForgotPassword() {
			try {
				this.$message.loading({
					content: 'Validando socilitação...',
					key: 'updatable',
				})
				const res = await this.forgotPassword(this.user.email)
				this.$message.success({
					content: res.message,
					key: 'updatable',
					duration: 8,
				})
			} catch (error) {
				this.$message.destroy()
			}
		},
	},
}
</script>
