<template>
	<div class="actions">
		<a-tooltip
			v-if="slide"
			:title="getSlideTooltip()"
			:style="slideDisabled? 
					{ 
						lineHeight: '0.75', 
						margin: '0px',
					} : {}"
		>
			<a-switch
				size="small"
				class="buttons"
				:checked="isAvailable"
				:disabled="slideDisabled"
				@change="switchEvent"
			/>
		</a-tooltip>
		<a-tooltip v-if="duplicate" :title="`Duplicar ${description}`">
			<a-icon type="copy" class="buttons" @click="duplicateEvent" />
		</a-tooltip>
		<a-tooltip v-if="edit" :title="`Editar ${description}`">
			<a-icon type="edit" class="buttons" @click="editEvent" />
		</a-tooltip>
		<a-popconfirm
			v-if="remove"
			:title="`Deseja remover ${description}?`"
			ok-text="Sim"
			cancel-text="Não"
			placement="bottomRight"
			@confirm="deleteEvent"
		>
			<a-tooltip :title="`Remover ${description}`">
				<a-icon
					type="delete"
					class="buttons"
					style="padding-right: 0; margin-right: 0;"
				/>
			</a-tooltip>
		</a-popconfirm>
	</div>
</template>

<script>
export default {
	name: 'ActionsButtons',
	props: {
		isAvailable: {
			type: Boolean,
			default: false,
		},
		description: {
			type: String,
			default: 'item',
		},
		slide: {
			type: Boolean,
			default: true,
		},
		duplicate: {
			type: Boolean,
			default: true,
		},
		edit: {
			type: Boolean,
			default: true,
		},
		remove: {
			type: Boolean,
			default: true,
		},
		slideDisabled: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		switchEvent(checked) {
			this.$emit('switch', checked)
		},
		duplicateEvent() {
			this.$emit('duplicate')
		},
		editEvent() {
			this.$emit('edit')
		},
		deleteEvent() {
			this.$emit('delete')
		},
		getSlideTooltip(){
			if(this.slideDisabled)
				return `O ${this.description} está indisponível no estoque`
			else {
				return this.isAvailable
					? `Desativar ${this.description}`
					: `Ativar ${this.description}`
			}
		}
	},
}
</script>

<style lang="less" scoped>
.actions {
	display: flex;
	width: 100%;
	justify-content: flex-end;
	font-size: 14px;
	padding-right: 0 !important;
	align-self: center;

	.buttons {
		font-size: 18px;
		margin: 0 8px 0 8px;
		cursor: pointer;
	}
}
</style>
