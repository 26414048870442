const ReportsRoutes = {
	path: 'reports',
	name: 'reports',
	component: () => import('../pages/reportsPage/reportsPage.vue'),
	meta: {
		title: 'Relatórios',
		icon: 'pie-chart',
		authorization: ['OWNER', 'MANAGER'],
	},
	hideChildrenInMenu: true,
	redirect: '/reports/financial',
	children: [
		{
			path: '/reports/financial',
			name: 'FinancialReport',
			component: () => import('../pages/reportsPage/financialReport.vue'),
			meta: { title: 'Relatórios Financeiros', icon: 'area-chart' },
		},
		{
			path: '/reports/invoices',
			name: 'InvoicesReport',
			component: () => import('../pages/reportsPage/invoicesReport.vue'),
			meta: { title: 'Relatório de Faturas', icon: 'bar-chart' },
		},
		{
			path: '/reports/ratings',
			name: 'RatingsReport',
			component: () => import('../pages/reportsPage/ratingsReport.vue'),
			meta: { title: 'Avaliações dos clientes', icon: 'star' },
		},
	],
}

export default ReportsRoutes
