<template>
	<a-layout-header class="header">
		<div>
			<a-button type="link" class="collapse-button" @click="toggle">
				<a-icon :type="collapsed ? 'menu-unfold' : 'menu-fold'" />
			</a-button>
			<Logo
				link-to="/home"
				style="display: inline-block; margin-left: 16px;"
			/>
		</div>
		<UserMenu />
	</a-layout-header>
</template>

<script>
import { Logo } from '@/modules/core/components'
import UserMenu from './userMenu'
import { mapMutations, mapGetters } from 'vuex'

export default {
	name: 'BasicHeader',
	components: {
		Logo,
		UserMenu,
	},
	computed: {
		...mapGetters({
			collapsed: 'ux/collapsed',
		}),
	},
	methods: {
		...mapMutations('ux', ['toggle']),
	},
}
</script>

<style lang="less" scoped>
.header {
	background: #fff;
	padding: 0 30px 0 0;
	box-shadow: 0 2px 8px #f0f1f2;
	position: fixed;
	z-index: 10;
	min-width: 100%;

	display: flex;
	justify-content: space-between;

	.collapse-button {
		margin-left: 12px;
		font-size: 20px;
		color: #444;
	}

	.divider {
		height: 24px;
	}
}
</style>
