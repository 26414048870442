export const capitalizeFirstLetter = (str) => {
	return str.charAt(0).toUpperCase() + str.slice(1)
}

export const toNormalize = (str) =>
	str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

export const validateCNPJ = (cnpj) => {
	cnpj = cnpj.replace(/[^\d]+/g, '')

	if (cnpj.toString().length != 14 || /^(\d)\1{10}$/.test(cnpj)) return false

	// Valida DVs
	let size = cnpj.length - 2
	let numbers = cnpj.substring(0, size)
	let digits = cnpj.substring(size)
	let sum = 0
	let pos = size - 7
	for (let i = size; i >= 1; i--) {
		sum += numbers.charAt(size - i) * pos--
		if (pos < 2) pos = 9
	}
	let result = sum % 11 < 2 ? 0 : 11 - (sum % 11)
	if (result != digits.charAt(0)) return false

	size = size + 1
	numbers = cnpj.substring(0, size)
	sum = 0
	pos = size - 7
	for (let i = size; i >= 1; i--) {
		sum += numbers.charAt(size - i) * pos--
		if (pos < 2) pos = 9
	}
	result = sum % 11 < 2 ? 0 : 11 - (sum % 11)
	if (result != digits.charAt(1)) return false

	return true
}

export const validateCPF = (cpf) => {
	cpf = cpf.replace(/\D/g, '')
	if (cpf.toString().length != 11 || /^(\d)\1{10}$/.test(cpf)) return false

	var result = true

	;[9, 10].forEach(function (j) {
		var soma = 0,
			r
		cpf.split(/(?=)/)
			.splice(0, j)
			.forEach(function (e, i) {
				soma += parseInt(e) * (j + 2 - (i + 1))
			})
		r = soma % 11
		r = r < 2 ? 0 : 11 - r
		if (r != cpf.substring(j, j + 1)) result = false
	})
	return result
}

export const mapCompanyKeys = {
	tipo: 'type',
	nome: 'company_name',
	fantasia: 'fantasy_name',
	telefone: 'phone',
}

export const mapAddressKeys1 = {
	numero: 'street_number',
	logradouro: 'street_name',
	cep: 'postal_code',
	bairro: 'neighborhood',
	municipio: 'city',
	uf: 'state',
	complemento: 'complement',
}

export const mapAddressKeys2 = {
	logradouro: 'street_name',
	bairro: 'neighborhood',
	localidade: 'city',
	uf: 'state',
}

export const orderByPosition = (a, b) => {
	return a.position - b.position
}

export const orderByCreatedAt = (a, b) => {
	return new Date(b.created_at) - new Date(a.created_at)
}

export const orderByDueDate = (a, b) => {
	return new Date(b.due_date) - new Date(a.due_date)
}

export const orderByLastAccess = (a, b) => {
	return new Date(b.last_time_used) - new Date(a.last_time_used)
}

export const validateEmail = (email) => {
	const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	return re.test(String(email).toLowerCase())
}

export const toCurrencyFormat = (
	value,
	format = 'pt-BR',
	currency = 'BRL',
	moneySign = false
) => {
	let formated = new Intl.NumberFormat(format, {
		style: 'currency',
		currency,
	}).format(value)

	if (!moneySign) formated = formated.replace(/R\$/g, '')

	return formated
}

export const currencytoNumber = (price) => {
	const value = String(price).replace(/\./, '').replace(/,/, '.')
	return Math.abs(value).toFixed(2)
}
