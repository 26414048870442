const MenuRoutes = {
	path: 'menu',
	name: 'menu',
	component: () => import('../pages/menuPage/menuPage.vue'),
	meta: {
		title: 'Cardápio',
		icon: 'profile',
		authorization: ['OWNER', 'MANAGER'],
	},
}

export default MenuRoutes
