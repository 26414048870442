<template>
	<div>
		<a-alert
			v-if="showLocalStatus"
			:message="localStatus.message"
			:description="localStatus.description"
			:type="localStatus.type"
			style="margin: 8px 0;"
			show-icon
		/>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

const alerts = [
	{
		status: 'STR',
		message: 'Análise contratual',
		description:
			'Estamos analisando a documentação para este restaurante. Em breve, todas a funcionalidades estarão disponíveis',
		type: 'warning',
	},
	{
		status: 'ATH',
		message: 'Configurações Básicas',
		description:
			'Forneça, em configurações, as informações de operação deste restaurante',
		type: 'warning',
	},
	{
		status: 'PPY',
		message: 'Fatura em atraso',
		description:
			'Em nossos sistemas consta que este restaurante possui faturas em atraso',
		type: 'warning',
	},
	{
		status: 'DSB',
		message: 'Restaurante desativado',
		description:
			'Este restaurante foi desativado do Agiliza e não possui mais nenhum vínculo contratual',
		type: 'warning',
	},
]

export default {
	name: 'AlertField',
	data() {
		return {
			alerts,
		}
	},
	computed: {
		...mapGetters({
			local_status: 'merchants/local_status',
		}),
		showLocalStatus: function () {
			return ['STR', 'ATH', 'PPY', 'DSB'].includes(this.local_status)
		},
		localStatus: function () {
			return alerts.find((field) => field.status === this.local_status)
		},
	},
	watch: {
		onlineStatus(value) {
			if (value) {
				this.showBackOnline = true
				setTimeout(() => {
					this.showBackOnline = false
				}, 1000)
			}
		},
	},
}
</script>

<style lang="less" scoped></style>
