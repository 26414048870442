<template>
	<div>
		<MerchantDropdown />
		<div class="user-menu-icon">
			<NoticeIcon />
		</div>
		<a-dropdown :trigger="['click']" style="z-index: 1030;">
			<span action ant-dropdown-link user-dropdown-menu>
				<a-avatar
					:size="40"
					icon="user"
					:src="userAvatar"
					style="cursor: pointer;"
				/>
			</span>
			<a-menu
				slot="overlay"
				mode="inline"
				class="user-dropdown-menu-wrapper"
			>
				<a-menu-item-group key="0" style="text-align: center;">
					<template slot="title">
						<a-avatar :size="50" icon="user" :src="userAvatar" />
						<span class="user-name">{{ userName }}</span>
						<span class="user-email">{{ user.email }} </span>
						<a-tag style="margin-top: 4px;">
							{{ rolePermission }}
						</a-tag>
					</template>
				</a-menu-item-group>
				<a-menu-divider />
				<a-menu-item key="1">
					<router-link :to="{ name: 'UserSettingsProfile' }">
						<a-icon type="tool" />
						<span>Gerenciar sua conta do Agiliza</span>
					</router-link>
				</a-menu-item>
				<a-menu-divider />
				<a-menu-item-group key="2" style="text-align: center;">
					<template slot="title">
						<a-popconfirm
							placement="rightBottom"
							ok-text="sim"
							cancel-text="Não"
							style="width: 90px; display: block; margin: 0 auto;"
							@confirm="confirmLogout"
						>
							<template slot="title">
								<p>{{ confirmTextQuestion }}</p>
							</template>
							<!-- <a-icon type="logout" /> -->
							<a-button>
								Sair
							</a-button>
						</a-popconfirm>
					</template>
				</a-menu-item-group>
			</a-menu>
		</a-dropdown>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { NoticeIcon } from '@/modules/core/components'
import MerchantDropdown from './merchantDropdown'

const roles = [
	{
		name: 'Proprietário',
		type: 'OWNER',
	},
	{
		name: 'Gerente',
		type: 'MANAGER',
	},
	{
		name: 'Garçom',
		type: 'WAITER',
	},
]

export default {
	name: 'UserMenu',
	components: {
		NoticeIcon,
		MerchantDropdown,
	},
	data() {
		return {
			confirmTextQuestion: 'Você realmente deseja sair?',
			roles,
		}
	},
	computed: {
		...mapGetters({
			user: 'account/user',
			accessType: 'merchants/accessType',
			loginMode: 'account/loginMode',
		}),
		userAvatar: function () {
			const { profile_picture } = this.user

			return profile_picture
		},
		userName: function () {
			const { first_name, last_name } = this.user

			return `${first_name} ${last_name}`
		},
		rolePermission: function () {
			const role =
				this.roles.find((role) => role.type === this.accessType) || {}

			return role.name
		},
	},
	mounted() {
		const user = this.$firebase.auth().currentUser
		if (
			user &&
			user.photoURL &&
			this.loginMode === 'google' &&
			this.user.profile_picture === null
		) {
			const { photoURL } = user
			this.SET_USER({ profile_picture: photoURL })
		}
	},
	methods: {
		...mapActions('account', ['logout']),
		...mapMutations('account', ['SET_USER']),
		async confirmLogout() {
			try {
				await this.logout()
				location.reload()
			} catch (error) {
				//
			}
		},
	},
}
</script>

<style lang="less" scoped>
.user-menu-icon {
	margin: 0 32px;
	padding-top: 4px;
	display: inline;

	&:hover {
		cursor: pointer;
	}
}

.user-dropdown-menu {
	span {
		user-select: none;
	}
}

.ant-popover {
	z-index: 1050;
}

.user-dropdown-menu-wrapper.ant-dropdown-menu {
	padding: 4px 0;
	width: 230px;

	.ant-dropdown-menu-item {
		width: 100%;
	}

	.ant-dropdown-menu-item > .anticon:first-child,
	.ant-dropdown-menu-item > a > .anticon:first-child,
	.ant-dropdown-menu-submenu-title
		> .anticon:first-child
		.ant-dropdown-menu-submenu-title
		> a
		> .anticon:first-child {
		min-width: 12px;
		margin-right: 8px;
	}

	.user-name {
		display: block;
		font-size: 13px;
		font-weight: bold;
		color: rgba(0, 0, 0, 0.65);
	}

	.user-email {
		display: block;
		font-size: 13px;
		font-weight: 400;
		color: rgba(0, 0, 0, 0.5);
	}
}
</style>
