import { axios } from '@/utils'
import { store } from '@/store'

export default {
	addMerchant,
	getMerchant,
	getAllMerchants,
	updateLogo,
	getCompany,
	getContract,
	updateMerchant,
	closeMerchantTemp,
	getMerchantTemporaryClosing,
	getImages,
	uploadImages,
	deleteImages,
	getIntervals,
	setIntervals,
	getProducts,
	addProduct,
	switchProduct,
	editProduct,
	deleteProduct,
	getPaymentMethods,
	updatePaymentMethods,
	getAccess,
	editAccess,
	deleteAccess,
	inviteGuest,
	addClosingScheduled,
	getClosingScheduleds,
	deleteClosingScheduled,
	editClosingScheduled,
	notifyCustomers,
	getSocialNetworks,
	addSocialNetwork,
	deleteSocialNetworks,
	getInvoices,
	getProductsGroupsList,
	getProductGroup,
	addProductGroup,
	editProductGroup,
	deleteProductGroup,
	activeAllProductsGroups,
	addProductToGroup,
	deleteProductFromGroup,
	getRatingsResume,
	getRatingsList,
}

async function addMerchant(data) {
	return await axios.post('/v1/merchants/', data)
}

async function getMerchant(merchantId) {
	const device = store.getters['account/fcmDevice']

	return await axios.get(`/v1/merchants/${merchantId}/`, {
		headers: {
			'device-uuid': device,
		},
	})
}

async function getCompany(merchantId) {
	return await axios.get(`/v1/merchants/${merchantId}/company/`)
}

async function getContract(merchantId) {
	return await axios.get(`/v1/merchants/${merchantId}/contract/`)
}

async function getAllMerchants() {
	return await axios.get('/v1/merchants/')
}

async function updateMerchant(payload) {
	const { merchantId, data } = payload

	return await axios.patch(`/v1/merchants/${merchantId}/`, formatData(data), {
		contentType: false,
		processData: false,
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	})
}

async function updateLogo(payload) {
	const { merchantId, img } = payload

	const substrings = img.type.split('/')
	const fileName = +new Date() + '.' + substrings[1]
	let data = new FormData()
	data.append(
		'logo',
		new File([img], fileName, { type: img.type, lastModified: Date.now() })
	)

	return await axios.patch(`/v1/merchants/${merchantId}/`, data, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	})
}

async function getProducts(merchantId) {
	return await axios.get(`/v1/merchants/${merchantId}/products/`)
}

async function addProduct(payload) {
	const { merchantId, product } = payload

	const formData = formatProduct(product)

	return await axios.post(`/v1/merchants/${merchantId}/products/`, formData, {
		contentType: false,
		processData: false,
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	})
}

async function switchProduct(data) {
	const { merchantId, productId, is_available } = data

	return await axios.patch(
		`/v1/merchants/${merchantId}/products/${productId}/`,
		{
			is_available: is_available,
		},
		{
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		}
	)
}

async function editProduct(payload) {
	const { merchantId, productId, data } = payload

	return await axios.patch(
		`/v1/merchants/${merchantId}/products/${productId}/`,
		formatProduct(data),
		{
			contentType: false,
			processData: false,
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}
	)
}

async function deleteProduct(payload) {
	const { merchantId, productId } = payload
	return await axios.delete(
		`/v1/merchants/${merchantId}/products/${productId}/`
	)
}

async function getIntervals(merchantId) {
	return await axios.get(`/v1/merchants/${merchantId}/intervals/`)
}

async function setIntervals(payload) {
	const { merchantId, intervals } = payload

	return await axios.post(`/v1/merchants/${merchantId}/intervals/`, intervals)
}

async function getImages(merchantId) {
	return await axios.get(`/v1/merchants/${merchantId}/images/`)
}

async function uploadImages(payload) {
	const { merchantId, files } = payload

	const data = new FormData()
	files.forEach((file) => data.append('images', file))

	return axios.post(`/v1/merchants/${merchantId}/images/`, data, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	})
}

function deleteImages(payload) {
	const { merchantId, delImages } = payload

	return axios.delete(`/v1/merchants/${merchantId}/images/`, {
		data: [...delImages],
	})
}

async function closeMerchantTemp(payload) {
	const { merchantId, closed_time } = payload

	return await axios.post(`/v1/merchants/${merchantId}/temporary_closing/`, {
		close_for_minutes: closed_time,
	})
}

async function getMerchantTemporaryClosing(merchantId) {
	return await axios.get(`/v1/merchants/${merchantId}/temporary_closing/`)
}

async function getPaymentMethods(payload) {
	const { merchantId, availables } = payload

	return await axios.get(`/v1/merchants/${merchantId}/payment_methods/`, {
		params: {
			availables: availables,
		},
	})
}

async function updatePaymentMethods(payload) {
	const { merchantId, data } = payload

	return await axios.post(
		`/v1/merchants/${merchantId}/payment_methods/`,
		data
	)
}

async function getAccess(merchantId) {
	return await axios.get(`/v1/merchants/${merchantId}/access/`)
}

async function editAccess(payload) {
	const { merchantId, accessId, data } = payload

	return await axios.patch(
		`/v1/merchants/${merchantId}/access/${accessId}/`,
		data
	)
}

async function deleteAccess(payload) {
	const { merchantId, accessId } = payload

	return await axios.delete(`/v1/merchants/${merchantId}/access/${accessId}/`)
}

async function inviteGuest(payload) {
	const { merchantId, data } = payload

	return await axios.post(`/v1/merchants/${merchantId}/access/`, data)
}

async function addClosingScheduled(payload) {
	const { merchantId, data } = payload

	return await axios.post(
		`/v1/merchants/${merchantId}/scheduled_closing/`,
		data
	)
}

async function getClosingScheduleds(merchantId) {
	return await axios.get(`/v1/merchants/${merchantId}/scheduled_closing/`)
}

async function deleteClosingScheduled(payload) {
	const { merchantId, closingId } = payload

	return await axios.delete(
		`/v1/merchants/${merchantId}/scheduled_closing/${closingId}/`
	)
}

async function editClosingScheduled(payload) {
	const { merchantId, closingId, data } = payload

	return await axios.patch(
		`/v1/merchants/${merchantId}/scheduled_closing/${closingId}/`,
		data
	)
}

async function notifyCustomers(payload) {
	const { merchantId, message } = payload

	return await axios.post(
		`/v1/merchants/${merchantId}/notify_active_customers/`,
		{
			message,
		}
	)
}

async function getSocialNetworks(merchantId) {
	return await axios.get(`/v1/merchants/${merchantId}/social_networks/`)
}

async function addSocialNetwork(payload) {
	const { merchantId, data } = payload

	return await axios.post(
		`/v1/merchants/${merchantId}/social_networks/`,
		data
	)
}

async function deleteSocialNetworks(payload) {
	const { merchantId, networkIds } = payload

	return await axios.delete(`/v1/merchants/${merchantId}/social_networks/`, {
		data: networkIds,
	})
}

async function getInvoices(payload) {
	const { merchantId, year } = payload

	return await axios.get(`/v1/merchants/${merchantId}/invoices/`, {
		params: {
			year,
		},
	})
}

async function getProductsGroupsList(merchantId) {
	return await axios.get(`v1/merchants/${merchantId}/productgroups`)
}

async function getProductGroup(payload) {
	const { merchantId, groupId } = payload

	return await axios.get(
		`v1/merchants/${merchantId}/productgroups/${groupId}`
	)
}

async function addProductGroup(payload) {
	const { merchantId, data } = payload

	return await axios.post(`v1/merchants/${merchantId}/productgroups`, data)
}

async function editProductGroup(payload) {
	const { merchantId, groupId, data } = payload

	return await axios.patch(
		`v1/merchants/${merchantId}/productgroups/${groupId}`,
		data
	)
}

async function deleteProductGroup(payload) {
	const { merchantId, groupId } = payload

	return await axios.delete(
		`v1/merchants/${merchantId}/productgroups/${groupId}`
	)
}

async function activeAllProductsGroups(merchantId) {
	return await axios.post(`v1/merchants/${merchantId}/productgroups/activate`)
}

async function addProductToGroup(payload) {
	const { merchantId, groupId, data } = payload

	return await axios.post(
		`v1/merchants/${merchantId}/productgroups/${groupId}/add_product`,
		data
	)
}

async function deleteProductFromGroup(payload) {
	const { merchantId, groupId, data } = payload

	return await axios.post(
		`v1/merchants/${merchantId}/productgroups/${groupId}/remove_product`,
		data
	)
}

function getRatingsResume(merchantId) {
	return axios.get(`/v1/merchants/${merchantId}/ratings_resume`)
}

function getRatingsList(payload) {
	const { merchantId, params } = payload
	return axios.get(`/v1/merchants/${merchantId}/ratings`, { params })
}

//==============  funcoes locais  ============================//
function formatProduct(item) {
	var formData = new FormData()

	item.price = Number(item.price).toFixed(2)

	for (const key in item) {
		if (key === 'image') {
			if (item[key]) {
				const substrings = item.image.type.split('/')
				const fileName = +new Date() + '.' + substrings[1]
				item.image = new File([item.image], fileName, {
					type: item.image.type,
					lastModified: Date.now(),
				})
			} else continue

			formData.append('image', item[key])
		} else formData.set(key, item[key])
	}

	return formData
}

function formatData(data) {
	var formData = new FormData()

	for (const key in data) formData.set(key, data[key])

	return formData
}
