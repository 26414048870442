<template>
	<div class="content">
		<a-row type="flex" justify="space-around">
			<a-col span="8">
				<a-card
					title="Recuperar senha"
					:body-style="{ padding: '8px 16px 0 16px' }"
					style="border-radius: 5px;"
				>
					<a-form
						:form="form"
						layout="horizontal"
						@submit.prevent="handleSubmit"
					>
						<a-form-item label="Email">
							<a-input
								v-decorator="[
									'email',
									{
										rules: [
											{
												type: 'email',
												message:
													'Este email não é válido!',
											},
											{
												required: true,
												message:
													'Por favor, informe um email!',
											},
										],
									},
								]"
								:auto-focus="true"
							/>
						</a-form-item>
						<a-form-item>
							<a-button
								type="primary"
								style="width: 100%;"
								html-type="submit"
								icon=""
							>
								Enviar
							</a-button>
						</a-form-item>
					</a-form>
				</a-card>
			</a-col>
		</a-row>
		<a-row type="flex" justify="space-around" style="margin-top: 16px;">
			<div>
				Já possue login e senha?
				<router-link to="/login">
					<span style="margin-left: 4px;">
						Entrar
					</span>
				</router-link>
			</div>
		</a-row>
	</div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
	name: 'ResetPasswordPage',
	data() {
		return {
			form: this.$form.createForm(this),
		}
	},
	methods: {
		...mapActions('account', ['forgotPassword']),
		handleSubmit() {
			this.form.validateFields(async (err, values) => {
				if (!err) {
					try {
						this.$message.loading({
							content: 'Validando solicitação...',
							key: 'updatable',
						})

						const { email } = values
						const res = await this.forgotPassword(email)

						this.$message.success({
							content: res.message,
							key: 'updatable',
							duration: 8,
						})
					} catch (error) {
						//
					}
				}
			})
		},
	},
}
</script>

<style lang="less" scoped>
.content {
	position: relative;
	top: 150px;
	height: 50vh;
}
</style>
