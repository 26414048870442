<template>
	<div>
		<PageHeader :title="$route.meta.title">
			<a-button type="link" icon="left" @click="$router.push('/home')">
				Voltar
			</a-button>
		</PageHeader>
		<a-card
			:body-style="{ padding: '16px 0', height: '100%' }"
			style="height: 100%; border-radius: 5px;"
		>
			<div class="account-settings-info-main">
				<div class="account-settings-info-left">
					<a-menu
						mode="inline"
						type="inner"
						:selected-keys="selectedKeys"
						style="border: none;"
					>
						<a-menu-item
							v-for="item in settingsMenuList"
							:key="item.path"
						>
							<router-link :to="{ name: item.pathName }">
								<a-icon :type="item.icon" />
								<span>{{ item.name }}</span>
							</router-link>
						</a-menu-item>
					</a-menu>
				</div>
				<div class="account-settings-info-right">
					<div class="account-settings-info-title">
						<span>{{ $route.meta.title }}</span>
					</div>
					<router-view />
				</div>
			</div>
		</a-card>
	</div>
</template>

<script>
import { PageHeader } from '@/modules/partner/components'
import { settingsMenuList } from './config'
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'UserSettingsPage',
	components: {
		PageHeader,
	},
	data() {
		return {
			selectedKeys: [],
			settingsMenuList,
		}
	},
	computed: {
		...mapGetters({
			user: 'account/user',
			merchants: 'merchants/merchants',
		}),
	},
	watch: {
		$route() {
			this.updateMenu()
		},
	},
	beforeMount() {},
	created() {
		this.updateMenu()

		if (this.merchants.length === 0) {
			Promise.all([this.getUser(), this.fetchMerchants()])
		}
	},
	methods: {
		...mapActions({
			fetchMerchants: 'merchants/fetchMerchants',
			getUser: 'account/getUser',
		}),
		updateMenu() {
			const routes = this.$route.matched.concat()
			this.selectedKeys = [routes.pop().path]
		},
	},
}
</script>

<style lang="less" scoped>
.account-settings-info-main {
	width: 100%;
	display: flex;
	height: 100%;

	&.mobile {
		display: block;
		.account-settings-info-left {
			border-right: unset;
			border-bottom: 1px solid hsv(0, 0, 85%);
			width: 100%;
			height: 50px;
			overflow-x: auto;
			overflow-y: scroll;
		}
		.account-settings-info-right {
			padding: 20px 40px;
		}
	}
	.account-settings-info-left {
		border-right: 1px solid hsv(0, 0, 85%);
		width: 200px;
	}
	.account-settings-info-right {
		flex: 1 1;
		padding: 8px 16px 8px 32px;
		.account-settings-info-title {
			color: rgba(0, 0, 0, 0.85);
			font-size: 20px;
			font-weight: 500;
			line-height: 28px;
			margin-bottom: 12px;
		}
		.account-settings-info-view {
			padding-top: 12px;
		}
	}
}
</style>
