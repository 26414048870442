<template>
	<div id="app">
		<router-view />
	</div>
</template>

<script>
import './use'
import config from '@/config/defaultSettings'
import { mapMutations } from 'vuex'

export default {
	name: 'App',
	watch: {
		$route: {
			immediate: true,
			handler(to) {
				const title = to.meta.title
				document.title = `${title ? `${title} - ` : ''}${
					config.appName
				}`
			},
		},
	},
	mounted() {
		window.addEventListener('online', this.updateOnlineStatus)
		window.addEventListener('offline', this.updateOnlineStatus)
	},
	beforeDestroy() {
		window.removeEventListener('online', this.updateOnlineStatus)
		window.removeEventListener('offline', this.updateOnlineStatus)
	},
	methods: {
		...mapMutations('ux', ['updateOnlineStatus']),
	},
}
</script>

<style>
#app {
	min-width: 1100px;
}
</style>
