import { merchantService } from '../services'
import { userStorage } from '@/utils'

const state = {
	loading: false,
	loadingMerchants: false,
	loadingProducts: false,
	loadingRatings: false,
	merchants: [],
	merchantIndex: userStorage.getMerchantIndex(),
	products: [],
	paymentMethods: [],
	ratingsList: [],
	authorized: true,
}

const actions = {
	async fetchMerchants({ commit, dispatch, state }) {
		try {
			commit('SET_LOADING_MERCHANTS')

			const res = await merchantService.getAllMerchants()

			await commit('SET_MERCHANTS', res)

			return res
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		} finally {
			state.downloadProgress = 0
			commit('STOP_LOADING_MERCHANTS')
		}
	},
	async addMerchant({ commit, dispatch }, merchant) {
		try {
			commit('SET_LOADING')

			return await merchantService.addMerchant(merchant)
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		} finally {
			commit('STOP_LOADING')
		}
	},
	async getMerchant({ dispatch }, merchantId) {
		try {
			return await merchantService.getMerchant(merchantId)
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		}
	},
	async getCompany({ dispatch }, merchantId) {
		try {
			return await merchantService.getCompany(merchantId)
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		}
	},
	async getContract({ dispatch }, merchantId) {
		try {
			return await merchantService.getContract(merchantId)
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		}
	},
	async updateMerchant({ commit, dispatch, state }, payload) {
		try {
			const res = await merchantService.updateMerchant(payload)

			const data = {
				index: state.merchantIndex,
				merchant: res,
			}

			commit('UPDATE_MARKET', data)

			return res
		} catch (err) {
			dispatch('alert/error', err, { root: true })

			return Promise.reject()
		}
	},
	async updateCompany({ dispatch }, payload) {
		try {
			return await merchantService.updateCompany(payload)
		} catch (err) {
			dispatch('alert/error', err, { root: true })

			return Promise.reject()
		}
	},
	async updateLogo({ commit, dispatch }, payload) {
		try {
			const res = await merchantService.updateLogo(payload)

			commit('UPDATE_MARKET', res)

			return res
		} catch (err) {
			dispatch('alert/error', err, { root: true })

			return Promise.reject()
		}
	},
	async switchMerchant({ commit, dispatch }, merchantId) {
		try {
			await commit('SWITCH_MARKET', merchantId)
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		} finally {
			commit('STOP_LOADING')
		}
	},
	async closeMerchantTemp({ commit, dispatch, state }, closed_time) {
		try {
			let res = await merchantService.closeMerchantTemp(closed_time)

			const payload = {
				index: state.merchantIndex,
				merchant: res,
			}

			await commit('UPDATE_MARKET', payload)

			return res
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		} finally {
			commit('STOP_LOADING')
		}
	},
	async updateMerchantTemp({ commit, dispatch, state }, merchantId) {
		try {
			let res = await merchantService.getMerchantTemporaryClosing(
				merchantId
			)

			const payload = {
				index: state.merchantIndex,
				merchant: res,
			}

			await commit('UPDATE_MARKET', payload)

			return res
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		} finally {
			commit('STOP_LOADING')
		}
	},
	async getProducts({ commit, dispatch, state }) {
		try {
			commit('SET_LOADING_PRODUCTS')

			const merchantId = state.merchants[state.merchantIndex].id
			const products = await merchantService.getProducts(merchantId)

			commit('SET_PRODUCTS', products)
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		} finally {
			commit('STOP_LOADING_PRODUCTS')
		}
	},
	async addProduct({ commit, dispatch, state }, product) {
		try {
			commit('SET_LOADING')
			const merchantId = state.merchants[state.merchantIndex].id

			const res = await merchantService.addProduct({
				merchantId,
				product,
			})

			commit('ADD_PRODUCT', res)

			return res
		} catch (err) {
			console.log(err)
			dispatch('alert/error', err, { root: true })
			return Promise.reject(err)
		} finally {
			commit('STOP_LOADING')
		}
	},
	switchProduct({ dispatch, commit }, payload) {
		try {
			commit('UPDATE_PRODUCT', {
				id: payload.productId,
				is_available: payload.is_available,
			})

			merchantService.switchProduct(payload)
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		}
	},
	async editProduct({ commit, dispatch }, payload) {
		try {
			commit('SET_LOADING')

			const res = await merchantService.editProduct(payload)

			commit('UPDATE_PRODUCT', res)

			return res
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		} finally {
			commit('STOP_LOADING')
		}
	},
	async deleteProduct({ commit, dispatch }, payload) {
		try {
			commit('SET_LOADING')

			return await merchantService.deleteProduct(payload)
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		} finally {
			commit('STOP_LOADING')
		}
	},
	async getPaymentMethods({ commit, dispatch }, payload) {
		try {
			commit('SET_LOADING')

			const res = await merchantService.getPaymentMethods(payload)
			await commit('SET_PAYMENT_METHODS', res)

			return res
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		} finally {
			commit('STOP_LOADING')
		}
	},
	async updatePaymentMethods({ dispatch }, payload) {
		try {
			const res = await merchantService.updatePaymentMethods(payload)
			return res
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		}
	},
	async getAccess({ dispatch }, merchantId) {
		try {
			const res = await merchantService.getAccess(merchantId)
			return res
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		}
	},
	async inviteGuest({ commit }, payload) {
		commit('STOP_LOADING')
		const res = await merchantService.inviteGuest(payload)
		return res
	},
	async editAccess({ dispatch }, payload) {
		try {
			const res = await merchantService.editAccess(payload)
			return res
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		}
	},
	async deleteAccess({ dispatch }, payload) {
		try {
			const res = await merchantService.deleteAccess(payload)
			return res
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		}
	},
	async watchMerchantIsOpen({ commit }) {
		try {
			const res = await merchantService.getAllMerchants()

			commit('SET_MERCHANTS', res)
		} catch (err) {
			console.log(err)
		}
	},
	async notifyCustomers({ dispatch }, payload) {
		try {
			return await merchantService.notifyCustomers(payload)
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		}
	},
	async getSocialNetworks({ dispatch }, merchantId) {
		try {
			return await merchantService.getSocialNetworks(merchantId)
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		}
	},
	async addSocialNetwork({ dispatch }, payload) {
		try {
			return await merchantService.addSocialNetwork(payload)
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		}
	},
	async deleteSocialNetworks({ dispatch }, payload) {
		try {
			return await merchantService.deleteSocialNetworks(payload)
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		}
	},
	async getInvoices({ dispatch }, payload) {
		try {
			return await merchantService.getInvoices(payload)
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		}
	},
	async getProductsGroupsList({ dispatch }, payload) {
		try {
			return await merchantService.getProductsGroupsList(payload)
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		}
	},
	async getProductGroup({ dispatch }, payload) {
		try {
			return await merchantService.getProductGroup(payload)
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		}
	},
	async addProductGroup({ dispatch }, payload) {
		try {
			return await merchantService.addProductGroup(payload)
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		}
	},
	async editProductGroup({ dispatch }, payload) {
		try {
			return await merchantService.editProductGroup(payload)
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		}
	},
	async deleteProductGroup({ dispatch }, payload) {
		try {
			return await merchantService.deleteProductGroup(payload)
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		}
	},
	async activeAllProductsGroups({ dispatch }, payload) {
		try {
			return await merchantService.activeAllProductsGroups(payload)
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		}
	},
	async addProductToGroup({ dispatch }, payload) {
		try {
			return await merchantService.addProductToGroup(payload)
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		}
	},
	async deleteProductFromGroup({ dispatch }, payload) {
		try {
			return await merchantService.deleteProductFromGroup(payload)
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		}
	},
	async getRatingsResume({ dispatch }, payload) {
		try {
			return await merchantService.getRatingsResume(payload)
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		}
	},
	async getRatingsList({ dispatch, commit, state }, payload) {
		try {
			state.loadingRatings = true
			const res = await merchantService.getRatingsList(payload)
			commit('SET_RATINGS_LIST', res)
			return res
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		} finally {
			state.loadingRatings = false
		}
	},
}

const mutations = {
	SET_PARTNER(state, partner) {
		state.partner = { ...partner }
	},
	async SET_MERCHANTS(state, merchants) {
		state.merchants = [...merchants]

		if (merchants.length > 0) {
			let curr = await userStorage.getMerchantIndex()

			if (curr >= merchants.length) {
				curr = merchants.length - 1
				await userStorage.set({ merchantIndex: curr })
			}

			if (!merchants[curr].is_active) {
				const index = merchants.findIndex(
					(merchant) => merchant.is_active
				)

				if (index >= 0) {
					state.merchantIndex = index
					await userStorage.set({ merchantIndex: index })
					state.authorized = true
				} else {
					state.authorized = false
					state.merchantIndex = curr
				}
			} else {
				state.authorized = true
				state.merchantIndex = curr
			}
		} else state.authorized = false
	},
	async SWITCH_MARKET(state, merchantId) {
		const index = state.merchants.findIndex((el) => el.id === merchantId)

		state.merchantIndex = index
		await userStorage.set({ merchantIndex: index })
	},
	UPDATE_MARKET(state, payload) {
		const { index, merchant } = payload

		for (const key in merchant) state.merchants[index][key] = merchant[key]
	},
	SET_PRODUCTS(state, products) {
		state.products = [...products]
	},
	ADD_PRODUCT(state, product) {
		state.products.push(product)
	},
	UPDATE_PRODUCT(state, product) {
		const index = state.products.findIndex(
			(elememt) => elememt.id === product.id
		)

		for (const key in product) state.products[index][key] = product[key]
	},
	SET_PAYMENT_METHODS(state, methods) {
		state.paymentMethods = [...methods]
	},
	SET_LOADING(state) {
		state.loading = true
	},
	STOP_LOADING(state) {
		state.loading = false
	},
	SET_LOADING_PRODUCTS(state) {
		state.loadingProducts = true
	},
	STOP_LOADING_PRODUCTS(state) {
		state.loadingProducts = false
	},
	SET_LOADING_MERCHANTS(state) {
		state.loadingMerchants = true
	},
	STOP_LOADING_MERCHANTS(state) {
		state.loadingMerchants = false
	},
	SET_RATINGS_LIST(state, { results }) {
		state.ratingsList = [...results]
	},
}

const getters = {
	isLoading: (state) => state.loading,
	isLoadingMerchants: (state) => state.loadingMerchants,
	isLoadingProducts: (state) => state.loadingProducts,
	partner: (state) => state.partner,
	merchants: (state) => state.merchants,
	selectedMerchantIndex: (state) => state.merchantIndex,
	selectedMerchantId: (state) => {
		return state.merchants.length > 0
			? state.merchants[state.merchantIndex].id
			: undefined
	},
	merchantName: (state) => {
		return state.merchants.length > 0
			? state.merchants[state.merchantIndex].name
			: ''
	},
	allProducts: (state) => {
		return state.products
	},
	allPaymentMethods: (state) => {
		return state.paymentMethods
	},
	local_status: (state) =>
		state.merchants.length > 0
			? state.merchants[state.merchantIndex].local_status
			: '',
	isAuthorized: (state) => state.authorized,
	isMerchantActive: (state) => {
		if (state.merchants.length === 0) return false

		const blocked = ['STR', 'DSB']
		const { local_status } = state.merchants[state.merchantIndex]

		return !blocked.includes(local_status)
	},
	accessType: (state) => {
		return state.merchants.length > 0
			? state.merchants[state.merchantIndex].access_type
			: 'OWNER'
	},
	ratingsList: (state) => state.ratingsList,
	loadingRatings: (state) => state.loadingRatings,
}

export const merchants = {
	namespaced: true,
	state,
	actions,
	mutations,
	getters,
}
