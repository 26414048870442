<template>
	<a-row class="page-header">
		<a-col :span="8">
			<span>{{ title }}</span>
		</a-col>
		<a-col :span="16" class="page-header-container">
			<slot></slot>
		</a-col>
	</a-row>
</template>

<script>
export default {
	name: 'PageHeader',
	props: {
		title: {
			type: String,
			required: true,
		},
	},
}
</script>

<style lang="less" scoped>
.page-header {
	display: flex;
	padding-bottom: 6px;
	margin-bottom: 15px;
	border-bottom: 1px solid hsv(0, 0, 85%);

	span {
		display: inline;
		font-size: 28px;
		font-weight: 500;
		color: rgba(0, 0, 0, 0.85);
	}

	.page-header-container {
		display: flex;
		justify-content: flex-end;
	}
}
</style>
