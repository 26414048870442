var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-row',[_c('a-col',[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)}}},[_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":"12"}},[_c('a-form-item',{attrs:{"label":"CPF","has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
									'cpf',
									{
										initialValue: _vm.user.cpf,
										rules: [
											{
												required: true,
												message:
													'Por favor, informe o número do seu CPF!',
											},
											{
												validator: _vm.onValidateCPF,
											} ],
									} ]),expression:"[\n\t\t\t\t\t\t\t\t\t'cpf',\n\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\tinitialValue: user.cpf,\n\t\t\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\t\t\tmessage:\n\t\t\t\t\t\t\t\t\t\t\t\t\t'Por favor, informe o número do seu CPF!',\n\t\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\t\tvalidator: onValidateCPF,\n\t\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t]"},{name:"mask",rawName:"v-mask",value:('###.###.###-##'),expression:"'###.###.###-##'"}],attrs:{"placeholder":"Informe o seu CPF"}})],1)],1),_c('a-col',{attrs:{"span":"12"}},[_c('a-form-item',{attrs:{"label":"Celular (whatsapp)","has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
									'phone',
									{
										initialValue: _vm.user.phone,
										rules: [
											{
												required: true,
												message:
													'Por favor, informe um número de celular!',
											},
											{
												pattern: /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})\-?(\d{4}))$/,
												message:
													'Por favor, informe um número válido',
											} ],
									} ]),expression:"[\n\t\t\t\t\t\t\t\t\t'phone',\n\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\tinitialValue: user.phone,\n\t\t\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\t\t\tmessage:\n\t\t\t\t\t\t\t\t\t\t\t\t\t'Por favor, informe um número de celular!',\n\t\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\t\tpattern: /^(?:(?:\\+|00)?(55)\\s?)?(?:\\(?([1-9][0-9])\\)?\\s?)?(?:((?:9\\d|[2-9])\\d{3})\\-?(\\d{4}))$/,\n\t\t\t\t\t\t\t\t\t\t\t\tmessage:\n\t\t\t\t\t\t\t\t\t\t\t\t\t'Por favor, informe um número válido',\n\t\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t]"},{name:"mask",rawName:"v-mask",value:([
									'(##) ####-####',
									'(##) #####-####' ]),expression:"[\n\t\t\t\t\t\t\t\t\t'(##) ####-####',\n\t\t\t\t\t\t\t\t\t'(##) #####-####',\n\t\t\t\t\t\t\t\t]"}],attrs:{"placeholder":"Informe o número do celular para o seu usuário"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":10}},[_c('a-form-item',{attrs:{"label":"RG/CNH","has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
									'rg_number',
									{
										initialValue: _vm.user.rg_number,
										rules: [
											{
												required: true,
												message:
													'Por favor, informe o numero do RG/CNH!',
											},
											{
												max: 11,
												message:
													'Limite máximo de 11 caracteres',
											} ],
									} ]),expression:"[\n\t\t\t\t\t\t\t\t\t'rg_number',\n\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\tinitialValue: user.rg_number,\n\t\t\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\t\t\tmessage:\n\t\t\t\t\t\t\t\t\t\t\t\t\t'Por favor, informe o numero do RG/CNH!',\n\t\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\t\tmax: 11,\n\t\t\t\t\t\t\t\t\t\t\t\tmessage:\n\t\t\t\t\t\t\t\t\t\t\t\t\t'Limite máximo de 11 caracteres',\n\t\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t]"}],attrs:{"placeholder":"Informe somente números para RG/CNH","type":"tel"}})],1)],1),_c('a-col',{attrs:{"span":9}},[_c('a-form-item',{attrs:{"label":"Emissor","has-feedback":""}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
									'rg_agency',
									{
										initialValue: _vm.user.rg_agency,
										rules: [
											{
												required: true,
												message:
													'Por favor, selecione um orgão emissor!',
											} ],
									} ]),expression:"[\n\t\t\t\t\t\t\t\t\t'rg_agency',\n\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\tinitialValue: user.rg_agency,\n\t\t\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\t\t\tmessage:\n\t\t\t\t\t\t\t\t\t\t\t\t\t'Por favor, selecione um orgão emissor!',\n\t\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t]"}],staticClass:"select",attrs:{"placeholder":"Selecione um orgão emissor"}},_vm._l((_vm.emitterList),function(emitter,index){return _c('a-select-option',{key:index,attrs:{"value":emitter[0]}},[_vm._v(" "+_vm._s(emitter[0] + ' - ' + emitter[1])+" ")])}),1)],1)],1),_c('a-col',{attrs:{"span":5}},[_c('a-form-item',{attrs:{"label":"Estado","has-feedback":""}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
									'rg_state',
									{
										initialValue: _vm.user.rg_state,
										rules: [
											{
												required: true,
												message:
													'Por favor, selecione um estado!',
											} ],
									} ]),expression:"[\n\t\t\t\t\t\t\t\t\t'rg_state',\n\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\tinitialValue: user.rg_state,\n\t\t\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\t\t\tmessage:\n\t\t\t\t\t\t\t\t\t\t\t\t\t'Por favor, selecione um estado!',\n\t\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t]"}],staticClass:"select",attrs:{"placeholder":"Selecione um estado"}},_vm._l((_vm.stateList),function(state){return _c('a-select-option',{key:state,attrs:{"value":state}},[_vm._v(" "+_vm._s(state)+" ")])}),1)],1)],1)],1),_c('a-row',{staticClass:"buttons",staticStyle:{"padding":"25px 0"},attrs:{"type":"flex","justify":"center"}},[_c('a-form-item',[_c('a-col',[_c('a-button',{staticStyle:{"width":"300px"},attrs:{"type":"primary","html-type":"submit","icon":"save"}},[_vm._v(" Salvar ")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }