<template>
	<div>
		<a-row type="flex" justify="start" class="content" :gutter="64">
			<a-col span="13">
				<Question @change="handleChange" />
			</a-col>
			<a-col span="11" class="form-container">
				<div v-if="answer">
					<a-card class="form-card" :body-style="{ padding: '24px' }">
						<UseAccountForm />
					</a-card>
					<SocialLogin
						class="form-card"
						style="margin-top: 16px;"
						@auth="onAuth"
					/>
				</div>
				<a-card
					v-else
					class="form-card"
					:body-style="{ padding: '24px' }"
				>
					<RegisterAccountForm />
				</a-card>
			</a-col>
		</a-row>
	</div>
</template>

<script>
import { Question, RegisterAccountForm, UseAccountForm } from './components'
import { SocialLogin } from '../../components'

export default {
	name: 'RegisterPage',
	components: {
		Question,
		RegisterAccountForm,
		UseAccountForm,
		SocialLogin,
	},
	data() {
		return {
			answer: true,
		}
	},
	methods: {
		handleChange(value) {
			this.answer = value
		},
		onAuth(validated) {
			if (validated) this.$router.push({ name: 'partner-info' })
		},
	},
}
</script>

<style lang="less" scoped>
.content {
	margin-top: 80px;

	.form-container {
		margin-bottom: 64px;
		.form-card {
			margin: 0 auto;
			min-width: 320px;
			max-width: 400px;
			border-radius: 5px;
		}
	}
}
</style>
