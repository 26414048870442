import { capitalizeFirstLetter } from '@/utils'
import { merchantService } from '../services'

const merchant = {
	name: '',
	presentation: '',
	main_category: '',
	amount_places: 1,
}

const company = {
	cnpj: '',
	type: 'MATRIZ',
	company_name: '',
	fantasy_name: '',
	phone: '',
}

const address = {
	postal_code: '',
	street_name: '',
	street_number: null,
	complement: '',
	neighborhood: '',
	state: '',
	city: '',
	country: 'BR',
}

const bank_account = {
	account: '',
	agency: '',
	bank: '',
	is_juridic: true,
	is_savings: false,
}

const state = {
	merchant: { ...merchant },
	company: { ...company },
	address: { ...address },
	bank_account: { ...bank_account },
	social_networks: [],
}

const actions = {
	addCompany({ commit }, payload) {
		commit('ADD_COMPANY', payload)
	},
	async addMerchant({ commit, dispatch, state }, payload) {
		try {
			await commit('ADD_MARKET', payload)

			const res = await merchantService.addMerchant({ ...state })

			commit('RESET_STATE')
			return res
		} catch (err) {
			dispatch('alert/error', err, { root: true })
			return Promise.reject()
		}
	},
}

const mutations = {
	ADD_COMPANY(state, payload) {
		state.company = { ...payload.company }
		state.address = { ...payload.address }
		state.bank_account = { ...payload.bank_account }
		state.merchant.name = capitalizeFirstLetter(
			payload.company.fantasy_name
		)
	},
	ADD_MARKET(state, payload) {
		state.merchant = { ...payload.merchant }

		state.merchant.name = capitalizeFirstLetter(state.merchant.name)
		state.merchant.presentation = capitalizeFirstLetter(
			state.merchant.presentation
		)
	},
	RESET_STATE(state) {
		state.merchant = { ...merchant }
		state.company = { ...company }
		state.address = { ...address }
		state.bank_account = { ...bank_account }
	},
}

const getters = {
	merchant: (state) => state.merchant,
	company: (state) => state.company,
	address: (state) => state.address,
	bank_account: (state) => state.bank_account,
}

export const registerForms = {
	namespaced: true,
	state,
	actions,
	mutations,
	getters,
}
