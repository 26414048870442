import { axios } from '@/utils/axios'
const urlBase = '/v1/merchants/'

export default {
	getOrders,
	acceptOrder,
	rejectOrder,
	cancelOrder,
	deliverOrder,
	requestPayment,
	receivePayment,
	registerElements,
	cancelOrderElements,
}

async function getOrders(payload) {
	const { merchantId, params } = payload

	return await axios.get(`${urlBase}${merchantId}/orders/`, {
		params: params,
	})
}

async function acceptOrder(payload) {
	const { merchantId, orderId } = payload

	return await axios.post(`${urlBase}${merchantId}/orders/${orderId}/accept/`)
}

async function rejectOrder(payload) {
	const { merchantId, orderId } = payload

	return await axios.post(
		`${urlBase}${merchantId}/orders/${orderId}/reject/`,
		payload.data
	)
}

async function cancelOrder(payload) {
	const { merchantId, orderId, data } = payload

	return await axios.post(
		`${urlBase}${merchantId}/orders/${orderId}/cancel/`,
		{
			cancellation_reason: data.reason,
		}
	)
}

async function cancelOrderElements(payload) {
	const { merchantId, orderId, data } = payload

	return await axios.post(
		`${urlBase}${merchantId}/orders/${orderId}/cancel_elements/`,
		data
	)
}

async function deliverOrder(payload) {
	const { merchantId, orderId } = payload

	return await axios.post(
		`${urlBase}${merchantId}/orders/${orderId}/deliver/`,
		{
			elements_id: payload.elements_id,
		}
	)
}

async function receivePayment(payload) {
	const { merchantId, orderId, paymentMethodId } = payload

	return await axios.post(
		`${urlBase}${merchantId}/orders/${orderId}/confirm_payment/`,
		{
			payment_method_id: paymentMethodId,
		}
	)
}

async function requestPayment(payload) {
	const { merchantId, orderId } = payload

	return await axios.post(
		`${urlBase}${merchantId}/orders/${orderId}/request_payment/`
	)
}

async function registerElements(payload) {
	const { merchantId, orderId, elements_id } = payload

	return await axios.post(
		`${urlBase}${merchantId}/orders/${orderId}/register_elements/`,
		{ elements_id }
	)
}
