/* eslint-disable no-unused-vars */
import notification from 'ant-design-vue/es/notification'

const state = {}

const actions = {
	success({ commit }, { message, description }) {
		notification.success({
			message: message,
			description: description,
			duration: 4,
		})
	},
	error({ commit }, error) {
		if(!error) return
		if(
			error.response && 
			error.response.status === 401
		) return

		const {message, description} = error

		notification.error({
			message: message,
			description: description,
			duration: 8,
		})
	},
	info({ commit }, { message, description }) {
		notification.info({
			message: message,
			description: description,
			duration: 6,
		})
	},
	warning({ commit }, { message, description }) {
		notification.warning({
			message: message,
			description: description,
			duration: 8,
		})
	},
}

const mutations = {}

export const alert = {
	namespaced: true,
	state,
	actions,
	mutations,
}
