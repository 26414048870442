import Vue from 'vue'
import Storage from 'vue-ls'
import config from '@/config/defaultSettings'

import antd from 'ant-design-vue/es'
import 'ant-design-vue/dist/antd.less'

import VueCountdownTimer from 'vuejs-countdown-timer'

Vue.use(antd)
Vue.use(Storage, config.storageOptions)
Vue.use(VueCountdownTimer)

import moment from 'moment'
import 'moment/locale/pt-br'
Vue.prototype.moment = moment
