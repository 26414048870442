<template>
	<div v-if="isAuthorized" class="container">
		<a-tooltip
			v-if="selectedMerchant && hasAccess"
			placement="bottomRight"
			:title="tooltipText"
		>
			<SetClosedTimePopconfirm
				v-if="selectedMerchant.is_open"
				ref="setClosedTimePopconfirm"
				@save="handleCloseTemp"
			>
				<a-switch
					size="small"
					:checked="selectedMerchant.is_open"
					class="swicth"
					:disabled="disableSwitch"
					:loading="switchLoading"
					@click="() => $refs.setClosedTimePopconfirm.setShowModal()"
				/>
			</SetClosedTimePopconfirm>
			<div v-else style="display: inline;">
				<a-badge v-if="showTimer" status="processing" color="#52c41a" />
				<a-switch
					size="small"
					:checked="selectedMerchant.is_open"
					class="swicth"
					:disabled="disableSwitch"
					:loading="switchLoading"
					@change="() => handleCloseTemp(0)"
				/>
			</div>
		</a-tooltip>
		<a-tooltip
			v-else-if="selectedMerchant && !hasAccess"
			placement="left"
			:title="selectedMerchant.is_open ? 'Aberto' : 'Fechado'"
		>
			<a-badge
				:status="selectedMerchant.is_open ? 'success' : 'default'"
			/>
		</a-tooltip>
		<a-dropdown
			v-if="selectedMerchant"
			:trigger="['click']"
			placement="bottomRight"
			@visibleChange="handleChange"
		>
			<a-button type="link" class="drop-button" ghost>
				{{ selectedMerchant.name }}
				<a-icon type="down" :rotate="isActive ? 180 : 0" />
			</a-button>
			<a-menu
				slot="overlay"
				mode="inline"
				:open-keys.sync="openKeys"
				@click="onClick"
			>
				<a-menu-item-group v-if="showTimer" key="g0">
					<template slot="title">
						<a-icon
							type="history"
							style="
								font-size: 13px;
								margin-right: 8px;
								color: #52c41a;
							"
						/>
						<div
							style="
								display: inline;
								font-size: 11px;
								color: #52c41a;
								font-weight: 501;
							"
						>
							{{ countdownText }}
							<vue-countdown-timer
								:start-time="new Date(selectedMerchant.now)"
								:end-time="
									new Date(selectedMerchant.closed_until)
								"
								:end-text="''"
								:interval="1000"
								:hour-txt="'horas'"
								:minutes-txt="'minutos'"
								:seconds-txt="'segundos'"
								style="display: inline;"
								@start_callback="startCallBack()"
								@end_callback="endCallBack()"
							>
								<template slot="countdown" slot-scope="scope">
									<span v-if="scope.props.hours > 1">{{
										`${scope.props.hours} ${scope.props.hourTxt}`
									}}</span>
									<span
										v-if="
											scope.props.hours > 0 &&
											scope.props.minutes > 0
										"
										>{{ `, ` }}</span
									>
									<span v-if="scope.props.minutes > 0">{{
										`${formatText(
											scope.props.minutes,
											scope.props.minutesTxt
										)} `
									}}</span>
									<span
										v-if="
											scope.props.minutes > 0 &&
											scope.props.seconds > 0
										"
										>{{ ` e ` }}</span
									>
									<span v-if="scope.props.seconds > 0">{{
										`${formatText(
											scope.props.seconds,
											scope.props.secondsTxt
										)}`
									}}</span>
								</template>
							</vue-countdown-timer>
						</div>
					</template>
				</a-menu-item-group>
				<a-menu-item-group v-if="manyMerchants" key="g1">
					<template slot="title">
						<span
							style="font-size: 11px; color: rgba(0, 0, 0, 0.75);"
						>
							ALTERNAR RESTAURANTE
						</span>
					</template>
					<a-menu-item
						v-for="merchant in unselectedMerchants"
						:key="merchant.id"
						style="padding-left: 4px;"
						:disabled="!merchant.is_active"
					>
						<div style="display: inline;">
							<a-avatar
								size="large"
								shape="square"
								icon="shop"
								:src="merchant.logo"
							/>
							<span style="margin-left: 8px; font-weight: 501;">
								{{ merchant.name }}
							</span>
							<a-tag
								:color="getTagColor(merchant)"
								style="
									margin-left: 8px;
									font-size: 11px;
									border-radius: 16px;
								"
							>
								{{ getTagDescription(merchant) }}
							</a-tag>
						</div>
					</a-menu-item>
				</a-menu-item-group>
				<a-divider v-if="manyMerchants" style="margin: 8px 0 0 0;" />
				<a-menu-item-group key="g2">
					<template slot="title">
						<span
							style="font-size: 11px; color: rgba(0, 0, 0, 0.75);"
						>
							AÇÕES
						</span>
					</template>
					<a-menu-item key="addMerchant" style="padding: 5px 8px;">
						<div style="display: inline;">
							<a-icon type="plus" style="font-size: 16px;" />
							<span style="margin-left: 8px;">
								Adicionar restaurante
							</span>
						</div>
					</a-menu-item>
					<a-sub-menu key="sub4">
						<span slot="title">
							<a-icon type="warning" style="font-size: 16px;" />
							<span style="margin-right: 8px;">
								Informar encerramento em
							</span>
						</span>
						<a-menu-item v-for="time in closingTimes" :key="time">
							<div style="display: inline;">
								<a-icon
									type="clock-circle"
									style="font-size: 16px;"
								/>
								<span style="margin-left: 8px;">
									{{ `${time} minutos` }}
								</span>
							</div>
						</a-menu-item>
					</a-sub-menu>
				</a-menu-item-group>
			</a-menu>
		</a-dropdown>
		<a-icon
			v-else
			type="loading"
			style="font-weight: bold; color: #f57d31;"
		/>
		<TermsModal
			ref="termsModal"
			@ok="acceptTerms"
			@cancel="handleNotAuthorizedCancel"
		/>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SetClosedTimePopconfirm from './setClosedTimePopconfirm'
import TermsModal from './termsModal'

const closingTimes = [30, 15, 5]

export default {
	name: 'MerchantDropdown',
	components: {
		SetClosedTimePopconfirm,
		TermsModal,
	},
	data() {
		return {
			isActive: false,
			openKeys: ['sub1'],
			closingTimes,
			countdownText: '',
			switchLoading: false,
			showTimer: false,
		}
	},
	computed: {
		...mapGetters('ux', ['onlineStatus']),
		...mapGetters('account', ['acceptedTerms', 'passwordTemp']),
		...mapGetters('merchants', [
			'accessType',
			'selectedMerchantIndex',
			'merchants',
			'isAuthorized',
			'isMerchantActive',
		]),
		manyMerchants: function () {
			return this.allMerchants.length > 1
		},
		selectedMerchant: function () {
			return this.allMerchants[this.selectedMerchantIndex]
		},
		unselectedMerchants: function () {
			return this.allMerchants.filter(
				(merchant) => merchant.id !== this.selectedMerchant.id
			)
		},
		disableSwitch: function () {
			return (
				this.selectedMerchant && !this.selectedMerchant.within_interval
			)
		},
		tooltipText: function () {
			if (!this.selectedMerchant) return ''

			return this.selectedMerchant.within_interval
				? this.selectedMerchant.forced_close
					? 'Reabrir restaurante'
					: 'Fechar temporariamente'
				: 'Fora do horário de funcionamento'
		},
		allMerchants: function () {
			return [...this.merchants]
		},
		hasAccess: function () {
			const allowed = ['OWNER', 'MANAGER']

			return allowed.includes(this.accessType)
		},
	},
	watch: {
		onlineStatus(online) {
			if (online && this.merchants.length === 0) this.init()
		},
	},
	mounted() {
		this.$destroyAll()
		this.init()
	},
	beforeDestroy: function () {
		clearInterval(this.interval)
	},
	methods: {
		...mapActions('merchants', [
			'fetchMerchants',
			'switchMerchant',
			'closeMerchantTemp',
			'updateMerchantTemp',
			'watchMerchantIsOpen',
			'notifyCustomers',
		]),
		...mapActions('account', ['logout', 'acceptTerms']),
		async init() {
			if (!this.isAuthorized) this.handleNotAuthorized()
			else if (this.passwordTemp)
				this.$router.push({ name: 'userSettingsSecurity' })
			else if (this.isMerchantActive) this.initMerchant()
		},
		initMerchant() {
			this.setFirebaseMessaging()

			if (!this.acceptedTerms) this.$refs.termsModal.showModal()

			if (this.selectedMerchant.forced_closing) {
				if (!this.selectedMerchant.now)
					this.selectedMerchant.now = new Date()

				this.showTimer = true

				clearInterval(this.interval)
			} else {
				this.interval = setInterval(
					function () {
						if (this.onlineStatus) this.watchMerchantIsOpen()
					}.bind(this),
					60000
				)
			}
		},
		handleChange(value) {
			this.isActive = value
		},
		async onClick(event) {
			const { key } = event

			if (this.merchants.some((merchant) => merchant.id === key))
				return this.handleSwitchToMerchant(key)

			if (event.key === 'addMerchant')
				this.$router.push('/steps/company-info/')

			if (closingTimes.includes(key)) this.handleNotifyCustomers(key)
		},
		async handleSwitchToMerchant(merchantId) {
			try {
				await this.$messaging.setTokenSentToServerFlg('')
				await this.switchMerchant(merchantId)

				this.$messaging.getTokenAsync()
				this.isActive = false

				this.$router.go({
					path: '/orders/pending',
					force: true,
				})
			} catch (error) {
				return Promise.reject(error)
			}
		},
		async handleNotifyCustomers(key) {
			try {
				this.$message.loading({
					content: 'Enviando notificações aos clientes...',
					key: 'updatable',
				})

				const payload = {
					merchantId: this.selectedMerchant.id,
					message: `Estaremos encerrando nossas atividades em ${key} min`,
				}

				await this.notifyCustomers(payload)
			} catch (error) {
				return Promise.reject(error)
			}
		},
		async setFirebaseMessaging() {
			await this.$messaging.requestPermission().then(async () => {
				await this.$messaging.getTokenAsync()
			})

			this.$messaging.onMessaging()
		},
		async handleCloseTemp(closed_time) {
			this.switchLoading = true
			const payload = {
				merchantId: this.selectedMerchant.id,
				closed_time: closed_time,
			}
			const res = await this.closeMerchantTemp(payload)

			for (const key in res) this.selectedMerchant[key] = res[key]

			this.switchLoading = false
			this.showTimer = true
		},
		startCallBack() {
			this.countdownText = 'Reabertura em'
		},
		endCallBack() {
			this.countdownText = 'Reabrindo restaurante...'

			setTimeout(async () => {
				this.showTimer = false
				const res = await this.updateMerchantTemp(
					this.selectedMerchant.id
				)

				for (const key in res) this.selectedMerchant[key] = res[key]

				this.countdownText = ''
			}, 1000)
		},
		formatText(value, key) {
			const word = value < 2 ? key.replace(/s$/, '') : key

			return `${value} ${word}`
		},
		getTagColor(merchant) {
			if (merchant.is_active) {
				return merchant.is_open ? 'green' : ''
			} else return 'red'
		},
		getTagDescription(merchant) {
			if (merchant.is_active) {
				return merchant.is_open ? 'Aberto' : 'Fechado'
			} else return 'sem acesso'
		},
		async handleNotAuthorized() {
			this.$confirm({
				title: 'Deseja cadastrar um restaurante?',
				content:
					'Sua conta não possui acesso a nenhum estabelecimento cadastrado',
				okText: 'Sim',
				cancelText: 'Não',
				onOk: this.handleNotAuthorizedOk,
				onCancel: this.handleNotAuthorizedCancel,
			})
		},
		async handleNotAuthorizedOk() {
			this.$router.push('/steps/partner-info/')
		},
		async handleNotAuthorizedCancel() {
			try {
				await this.logout()
				this.$router.push('/login')
			} catch (error) {
				console.error(error)
			}
		},
	},
}
</script>

<style lang="less" scoped>
@import url('@/config/globalStyles.less');

.container {
	display: inline;
	border: 1px solid hsv(0, 0, 85%);
	padding: 8px 16px;
	border-radius: 50px;

	.drop-button {
		padding: 0;
		margin: 0;
		color: rgba(0, 0, 0, 0.65);
		font-weight: 501;
		font-size: 16px;
		padding-right: 0px;
	}

	.swicth {
		margin: -3px 8px 0 0;
	}

	&:hover {
		border-color: @primary;
		.drop-button {
			color: rgba(0, 0, 0, 0.85);
		}
	}
}
</style>
