const OrderRoutes = {
	path: 'orders',
	name: 'orders',
	component: () => import('../pages/ordersPage/ordersPage.vue'),
	meta: {
		title: 'Pedidos',
		icon: 'form',
		authorization: ['OWNER', 'MANAGER', 'WAITER'],
	},
	redirect: '/orders/pending/',
	children: [
		{
			path: '/orders/pending/',
			name: 'PendingOrders',
			component: () => import('../pages/ordersPage/pendingOrders.vue'),
			meta: { title: 'Pedidos Pendentes', icon: 'hourglass' },
		},
		{
			path: '/orders/analyzed',
			name: 'AnalyzedOrders',
			component: () => import('../pages/ordersPage/analyzedOrders.vue'),
			meta: { title: 'Pedidos Analisados', icon: 'check-circle' },
		},
	],
}

export default OrderRoutes
