<template>
	<a-layout-header class="header">
		<Logo link-to="/" />
		<router-link
			v-if="$route.meta.showLoginButton"
			to="/login"
			class="button-container"
		>
			<a-button type="primary" ghost>
				Entrar
			</a-button>
		</router-link>
	</a-layout-header>
</template>

<script>
import Logo from './logo'

export default {
	name: 'SimpleHeader',
	components: {
		Logo,
	},
}
</script>

<style lang="less" scoped>
.header {
	padding: 0;
	margin: 0;
	background: transparent;
	position: relative;
	z-index: 10;
	max-width: 100%;
	display: flex;
	justify-content: space-between;
}
</style>
