<template>
	<a-row :gutter="24">
		<a-col span="8">
			<h3>Revogar acesso</h3>
			<p>
				Você pode revogar o acesso dos dispositivos relacionados a sua
				conta. Caso revoge o acesso deste dispositivo, você será
				deslogado do sistema.
			</p>
		</a-col>
		<a-col span="16" style="padding-right: 32px;">
			<h3>Dispositivos em uso</h3>
			<a-list
				v-if="loading"
				item-layout="horizontal"
				:data-source="[1, 2, 3]"
			>
				<a-list-item
					slot="renderItem"
					key="item.title"
					slot-scope="item"
				>
					<a-skeleton
						:loading="true"
						:title="false"
						:paragraph="{ rows: 2, width: ['100%', '65%'] }"
						active
					>
						{{ item }}
					</a-skeleton>
				</a-list-item>
			</a-list>
			<a-list v-else item-layout="horizontal" :data-source="devices">
				<a-list-item
					slot="renderItem"
					slot-scope="item"
					style="padding: 8px 0;"
				>
					<ActionsButtons
						slot="actions"
						:edit="false"
						:duplicate="false"
						:slide="false"
						description="dispositivo"
						style="color: rgba(0, 0, 0, 0.7);"
						@delete="handleDelete(item)"
					/>
					<a-list-item-meta
						v-if="item"
						:description="`Acessado em ${moment(
							item.last_time_used
						).format('DD/MM/YYYY HH:MM')} - ${item.localization}`"
					>
						<a-avatar
							v-if="item.application_id === 'management_web'"
							slot="avatar"
							icon="desktop"
							:style="getAppIconStyle(item.application_id)"
							shape="square"
							:size="48"
						/>
						<a-avatar
							v-else
							slot="avatar"
							icon="mobile"
							:style="getAppIconStyle(item.application_id)"
							shape="square"
							:size="48"
						/>
						<div slot="title">
							<span>
								{{ `${item.name} (${item.os})` }}
							</span>
							<a-tag
								v-if="item.current_device"
								color="#87d068"
								style="margin-left: 8px;"
							>
								Este dispositivo
							</a-tag>
						</div>
					</a-list-item-meta>
				</a-list-item>
			</a-list>
		</a-col>
	</a-row>
</template>

<script>
import { accountService } from '../../services'
import { ActionsButtons } from '@/modules/partner/components'
import { orderByLastAccess } from '@/utils/utils'
import { mapActions } from 'vuex'

export default {
	name: 'SettingsDevicesPage',
	components: {
		ActionsButtons,
	},
	data() {
		return {
			loading: false,
			devices: [],
		}
	},

	watch: {
		$route: {
			handler: 'getData',
			immediate: true,
		},
	},
	methods: {
		...mapActions('account', ['logout']),
		async getData() {
			try {
				this.loading = true
				const res = await accountService.getDevices()
				this.devices = res.sort(orderByLastAccess)
			} catch (error) {
				//
			} finally {
				this.loading = false
			}
		},
		async handleDelete(device) {
			try {
				const { id, current_device } = device

				this.loading = true

				if (current_device) {
					await this.logout()
					location.reload()
				} else {
					await accountService.revokeDeviceAccess(id)

					this.devices = this.devices.filter(
						(device) => device.id !== id
					)

					this.$message.success({
						content: 'Dispositivo removido',
						duration: 4,
					})
				}
			} catch (error) {
				//
			} finally {
				this.loading = false
			}
		},
		getAppIcon(application) {
			return application === 'management_web' ? 'desktop' : 'mobile'
		},
		getAppIconStyle(application) {
			const colors = {
				management_web: '#f57d31',
				menu_mobile: '#f57d31',
				management_mobile: '#3574b6',
			}

			return { color: colors[application], 'background-color': '#e8e8e8' }
		},
	},
}
</script>
