import { store } from '../store'

const ifNotAuthenticated = (_, __, next) => {
	return !store.getters['account/isAuth'] ? next() : next('/home')
}

const ifAuthenticated = (_, __, next) => {
	return store.getters['account/isAuth'] ? next() : next('/login')
}

export { ifNotAuthenticated, ifAuthenticated }
