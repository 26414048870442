import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import SecureLS from 'secure-ls'

import { alert, account, notifications } from '@/modules/core/store'
import {
	ux,
	tables,
	menu,
	orders,
	merchants,
	registerForms,
} from '@/modules/partner/store'

Vue.use(Vuex)
const ls = new SecureLS({
	encodingType: 'aes',
})

export const store = new Vuex.Store({
	modules: {
		alert,
		account,
		merchants,
		ux,
		tables,
		menu,
		orders,
		notifications,
		registerForms,
	},
	plugins: [
		createPersistedState({
			key: 'agiliza-portal',
			paths: ['account', 'registerForms', 'ux', 'tables'],
			storage: {
				getItem: (key) => ls.get(key),
				setItem: (key, value) => ls.set(key, value),
				removeItem: (key) => ls.remove(key),
			},
		}),
	],
})
