<template>
	<router-link :to="linkTo">
		<!-- <img width="35" src="@/assets/logo.png" alt="Agiliza" /> -->
		<img width="96" src="@/assets/tipografia.png" alt="" />
	</router-link>
</template>

<script>
export default {
	name: 'Logo',
	props: {
		linkTo: {
			type: String,
			required: true,
		},
	},
}
</script>

<style lang="less" scoped>
#logo {
	font-size: 1.3rem;
	font-weight: 600;
	color: #2c3e50;
	position: relative;
	text-decoration: none;
}
</style>
