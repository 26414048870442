import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store'
import VueClipboard from 'vue-clipboard2'

Vue.config.productionTip = false

import firebaseConfig from './config/firebaseConfig'
import firebase from 'firebase/app'
import 'firebase/messaging'
import 'firebase/auth'

import './modules'
import { MessagingService } from '@/utils'

Vue.prototype.$firebase = firebase.initializeApp(firebaseConfig)
firebase.auth().useDeviceLanguage()

if (firebase.messaging.isSupported()) {
	Vue.prototype.$messaging = new MessagingService(
		Vue.prototype.$firebase.messaging()
	)
} else {
	alert(
		'Alerta: Este navegador não dá suporte ao recebimento de notificações!'
	)
}

Vue.use(VueClipboard)

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount('#app')
