var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_c('a-row',{attrs:{"type":"flex","justify":"space-around"}},[_c('a-col',{attrs:{"span":"8"}},[_c('a-card',{staticStyle:{"border-radius":"5px"},attrs:{"title":"Recuperar senha","body-style":{ padding: '8px 16px 0 16px' }}},[_c('a-form',{attrs:{"form":_vm.form,"layout":"horizontal"},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)}}},[_c('a-form-item',{attrs:{"label":"Email"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
								'email',
								{
									rules: [
										{
											type: 'email',
											message:
												'Este email não é válido!',
										},
										{
											required: true,
											message:
												'Por favor, informe um email!',
										} ],
								} ]),expression:"[\n\t\t\t\t\t\t\t\t'email',\n\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\ttype: 'email',\n\t\t\t\t\t\t\t\t\t\t\tmessage:\n\t\t\t\t\t\t\t\t\t\t\t\t'Este email não é válido!',\n\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\t\tmessage:\n\t\t\t\t\t\t\t\t\t\t\t\t'Por favor, informe um email!',\n\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t]"}],attrs:{"auto-focus":true}})],1),_c('a-form-item',[_c('a-button',{staticStyle:{"width":"100%"},attrs:{"type":"primary","html-type":"submit","icon":""}},[_vm._v(" Enviar ")])],1)],1)],1)],1)],1),_c('a-row',{staticStyle:{"margin-top":"16px"},attrs:{"type":"flex","justify":"space-around"}},[_c('div',[_vm._v(" Já possue login e senha? "),_c('router-link',{attrs:{"to":"/login"}},[_c('span',{staticStyle:{"margin-left":"4px"}},[_vm._v(" Entrar ")])])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }