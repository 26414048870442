<template>
	<a-layout class="page-wrapper">
		<a-layout-content class="container">
			<SimpleHeader />
			<div class="main-content">
				<OfflineAlert />
				<router-view />
			</div>
		</a-layout-content>
		<SimpleFooter />
	</a-layout>
</template>

<script>
import { SimpleFooter, SimpleHeader, OfflineAlert } from '../components'

export default {
	name: 'SimpleLayout',
	components: {
		SimpleFooter,
		SimpleHeader,
		OfflineAlert,
	},
}
</script>

<style lang="less" scoped>
.page-wrapper {
	padding: 0;
	width: 100%;
	height: 100%;
	min-height: 100vh;

	display: flex;
	flex-direction: column;
}

.container {
	margin: 0px auto;
	width: 80%;
	max-width: 1200px;
	height: 100%;
}

.main-content {
	padding: 0;
	overflow: hidden;
	position: relative;
}
</style>
