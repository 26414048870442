var firebaseConfig = {
	apiKey: 'AIzaSyBxKSzNmAvDqDQxP4VqOQ5OoWfg74wXGlA',
	authDomain: 'agiliza-976e4.firebaseapp.com',
	databaseURL: 'https://agiliza-976e4.firebaseio.com',
	projectId: 'agiliza-976e4',
	storageBucket: 'agiliza-976e4.appspot.com',
	messagingSenderId: '719167520135',
	appId: '1:719167520135:web:6928dba16e5ba0e61aa722',
	measurementId: 'G-MHNHX94425',
}

export default firebaseConfig
