const StepsFormsRoutes = {
	path: '/steps/',
	name: 'steps',
	component: () =>
		import('../pages/registerMerchantPage/RegisterMerchantPage.vue'),
	redirect: '/steps/partner-info/',
	children: [
		{
			name: 'partner-info',
			path: '/steps/partner-info/',
			component: () =>
				import('../pages/registerMerchantPage/PartnerInfoPage.vue'),
			meta: { title: 'Informações do proprietário' },
		},
		{
			path: '/steps/company-info',
			component: () =>
				import('../pages/registerMerchantPage/CompanyInfoPage.vue'),
			meta: { title: 'Informações do restaurante' },
		},
		{
			path: '/steps/merchant-info/',
			component: () =>
				import('../pages/registerMerchantPage/MerchantInfoPage.vue'),
			meta: { title: 'Apresentação do restaurante' },
		},
		{
			path: '/steps/agreement',
			component: () =>
				import('../pages/registerMerchantPage/AgreementPage.vue'),
			meta: { title: 'Finilizar cadastro' },
			props: {
				default: false,
			},
		},
	],
}

export default StepsFormsRoutes
