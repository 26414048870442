<template>
	<div>
		<a-row :gutter="16">
			<a-col :span="15">
				<a-form :form="form" @submit.prevent="handleSubmit">
					<a-row :gutter="16">
						<a-col span="12">
							<a-form-item label="Nome" has-feedback>
								<a-input
									v-decorator="[
										'first_name',
										{
											initialValue: user.first_name,
											rules: [
												{
													required: true,
													message:
														'Por favor, informe um nome!',
												},
											],
										},
									]"
									placeholder="Informe o seu nome"
								/>
							</a-form-item>
						</a-col>
						<a-col span="12">
							<a-form-item label="Sobrenome" has-feedback>
								<a-input
									v-decorator="[
										'last_name',
										{
											initialValue: user.last_name,
											rules: [
												{
													required: true,
													message:
														'Por favor, informe seu sobrenome!',
												},
											],
										},
									]"
									placeholder="Informe o seu sobrenome"
								/>
							</a-form-item>
						</a-col>
					</a-row>
					<a-row>
						<a-col>
							<a-form-item label="Email" has-feedback>
								<a-input
									v-decorator="[
										'email',
										{
											initialValue: user.email,
											rules: [
												{
													type: 'email',
													message:
														'Este email não é válido!',
												},
											],
										},
									]"
									disabled
								/>
							</a-form-item>
						</a-col>
					</a-row>
					<a-row
						type="flex"
						justify="center"
						class="buttons"
						style="padding: 25px 0;"
					>
						<a-form-item>
							<a-col>
								<a-button
									type="primary"
									style="width: 300px;"
									html-type="submit"
									icon="save"
								>
									Salvar
								</a-button>
							</a-col>
						</a-form-item>
					</a-row>
				</a-form>
			</a-col>
			<a-col :span="9">
				<a-row
					:style="{ minHeight: '140px', padding: '10px 0 15px 0' }"
				>
					<span class="avatar-label">Avatar:</span>
					<div
						class="ant-upload-preview"
						@click="$refs.modal.setShowModal('50%')"
					>
						<div class="mask">
							<a-icon type="edit" />
						</div>
						<img :src="preview" icon="user" />
					</div>
					<a-button
						type="link"
						icon="close"
						block
						@click="setAvatar(null)"
					>
						Remover imagem
					</a-button>
				</a-row>
			</a-col>
		</a-row>
		<AvatarModal ref="modal" @ok="setAvatar" />
	</div>
</template>

<script>
import { AvatarModal } from '@/modules/partner/components'
import { mask } from 'vue-the-mask'
import { mapGetters, mapActions } from 'vuex'

export default {
	name: '',
	components: {
		AvatarModal,
	},
	directives: { mask },
	data() {
		return {
			form: this.$form.createForm(this),
		}
	},
	computed: {
		...mapGetters({
			user: 'account/user',
		}),
		preview() {
			const { profile_picture } = this.user

			return profile_picture ? profile_picture : 'img/user.png'
		},
	},
	methods: {
		...mapActions('account', ['updateAvatar', 'updateUser']),
		async setAvatar(img) {
			try {
				this.$message.loading({
					content: 'Atualizando imagem...',
					key: 'updatable',
				})
				await this.updateAvatar(img)
				this.$message.success({
					content: 'Imagem atualizada',
					key: 'updatable',
					duration: 4,
				})
			} catch (error) {
				this.$message.destroy()
				console.log(error)
			}
		},
		handleSubmit() {
			this.form.validateFields(async (err, values) => {
				if (!err) {
					this.$message.loading({
						content: 'Atualizando...',
						key: 'updatable',
					})

					const { first_name, last_name } = values
					await this.updateUser({ first_name, last_name })

					this.$message.success({
						content: 'Atualizado',
						key: 'updatable',
						duration: 4,
					})
				}
			})
		},
	},
}
</script>

<style lang="less" scoped>
@import url('@/modules/partner/config/settingsStyles.less');
</style>
