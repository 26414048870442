import {
	UserSettingsPage,
	SettingsProfilePage,
	SettingsInfoPage,
	SettingsDevicesPage,
	SettingsSecurityPage,
} from '../pages/UserSettingsPage'

const UserSettingsRoutes = {
	path: '/settings/',
	component: UserSettingsPage,
	redirect: '/settings/profile/',
	children: [
		{
			path: '/settings/profile/',
			name: 'UserSettingsProfile',
			component: SettingsProfilePage,
			meta: { title: 'Editar perfil' },
		},
		{
			path: '/settings/info',
			name: 'UserSettingsInfo',
			component: SettingsInfoPage,
			meta: { title: 'Editar informações pessoais' },
		},
		{
			path: '/settings/devices',
			name: 'userSettingsDevices',
			component: SettingsDevicesPage,
			meta: { title: 'Gerenciar dispositivos contectados' },
		},
		{
			path: '/settings/security',
			name: 'userSettingsSecurity',
			component: SettingsSecurityPage,
			meta: { title: 'Segurança' },
		},
	],
}

export default UserSettingsRoutes
