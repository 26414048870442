import { LoginPage, ResetPasswordPage, RegisterPage } from '../pages'

const PublicRoutes = [
	{
		path: 'login',
		name: 'login',
		component: LoginPage,
		meta: { title: 'Entrar' },
	},
	{
		path: 'register',
		name: 'register',
		component: RegisterPage,
		meta: { title: 'Cadastre uma conta' },
	},
	{
		path: 'resetpassword',
		name: 'resetpassword',
		component: ResetPasswordPage,
		props: false,
		meta: { title: 'Redefinição de senha' },
	},
]

export default PublicRoutes
