<template>
	<div>
		<a-menu v-model="current" mode="inline" theme="light" class="menu">
			<a-menu-item
				v-for="item in authorizedMenu"
				:key="item.path"
				:disabled="checkDisabled() || item.name === 'Ajuda'"
			>
				<router-link :to="item.path">
					<a-icon :type="item.icon" />
					<span>{{ item.name }}</span>
				</router-link>
			</a-menu-item>
		</a-menu>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'SideMenu',
	props: {
		menuList: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			current: [],
		}
	},
	computed: {
		...mapGetters({
			local_status: 'merchants/local_status',
			accessType: 'merchants/accessType',
		}),
		authorizedMenu: function () {
			return this.menuList.filter((item) =>
				item.authorization.includes(this.accessType)
			)
		},
	},
	watch: {
		$route: function () {
			this.updateMenu()
		},
	},
	mounted() {
		this.updateMenu()
	},
	methods: {
		updateMenu() {
			const routes = this.$route.matched.slice(0, 2).concat()
			this.current = [routes.pop().path]
		},
		checkDisabled() {
			const blocked = ['STR', 'DSB']

			return blocked.includes(this.local_status) ? true : false
		},
	},
}
</script>

<style lang="less" scoped>
.menu {
	border: none;
	width: 99.9%;
}
</style>
