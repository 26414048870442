<template>
    <a-modal
		v-model="visible"
		title="Termos do Agiliza"
		cancel-text="Cancelar"
		ok-text="Continuar"
		:ok-button-props="{ props: { disabled: !checked } }"
		:mask-closable="false"
		@ok="handleOk"
		@cancel="handleCancel"
    >
		<a-row 
			type="flex" 
			justify="start"
			style="margin-left: 24px"
		>
				<p>
					Talvez você ainda não tenha lido os 
					<router-link to="/home/help">
						Termos do Agiliza
					</router-link>
				</p>
				<div style="margin: 8px 0 24px  24px;">
					<a-checkbox 
						v-model="checked"
						style="margin-right: 8px"
					/>
					<span style="margin-right: 4px">
						Concordo com os termos
						<span style="color: rgba(0,0,0,0.5); margin-top: -4px;">**</span>
					</span>
					
				</div>
				<p>
					<span style="color: rgba(0,0,0,0.5); margin: -4px 4px 0 0;">**</span>
					Ao cancelar ou fechar sem concordar com os termos, você será deslogado do sistema
				</p>
		</a-row>
    </a-modal>
</template>
<script>
export default {
  data() {
    return {
		visible: false,
		checked: false,
    };
  },
  methods: {
    showModal() {
		this.visible = true;
    },
    handleOk() {
		this.visible = false;
		this.$emit('ok')
    },
    handleCancel() {
		this.visible = false
		this.$emit('cancel')
    },
  },
};
</script>
