<template>
	<div class="footer">
		<a-row class="copyright">
			<a-icon type="copyright" /> Copyright {{ new Date().getFullYear() }}
			<span>{{ appName }}</span>
		</a-row>
	</div>
</template>

<script>
import config from '@/config/defaultSettings'

export default {
	name: 'SimpleFooter',
	data: function () {
		return {
			appName: config.appName,
		}
	},
}
</script>

<style lang="less" scoped>
.footer {
	padding: 1.5rem 0;
	margin: auto;
	text-align: center;
	border-top: 1px solid #eaecef;
	width: 73.33333%;

	.copyright {
		color: rgba(0, 0, 0, 0.45);
		font-size: 13px;
	}
}
</style>
