<template>
	<LoginForm />
</template>

<script>
import { LoginForm } from '../components'

export default {
	name: 'LoginPage',
	components: { LoginForm },
}
</script>
