export const settingsMenuList = [
	{
		name: 'Perfil',
		pathName: 'UserSettingsProfile',
		path: '/settings/profile',
		icon: 'user',
	},
	{
		name: 'Informações pessoais',
		pathName: 'UserSettingsInfo',
		path: '/settings/info',
		icon: 'idcard',
	},
	{
		name: 'Gerenciar Dispositivos',
		pathName: 'userSettingsDevices',
		path: '/settings/devices',
		icon: 'control',
	},
	{
		name: 'Segurança',
		pathName: 'userSettingsSecurity',
		path: '/settings/security',
		icon: 'safety',
	},
]
