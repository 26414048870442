import { orderService } from '../services'

const state = {
	loading: false,
	pendingOrders: [],
	analyzedOrders: [],
	history: [],
}

const actions = {
	async acceptOrder({ commit, dispatch }, order_id) {
		try {
			commit('SET_LOADING')

			const res = await orderService.acceptOrder(order_id)

			return res
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		} finally {
			commit('STOP_LOADING')
		}
	},
	async rejectOrder({ commit, dispatch }, payload) {
		try {
			commit('SET_LOADING')

			const res = await orderService.rejectOrder(payload)

			return res
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		} finally {
			commit('STOP_LOADING')
		}
	},
	async cancelOrder({ commit, dispatch }, payload) {
		try {
			commit('SET_LOADING')

			const res = await orderService.cancelOrder(payload)

			return res
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		} finally {
			commit('STOP_LOADING')
		}
	},
	async cancelOrderElements({ commit, dispatch }, payload) {
		try {
			commit('SET_LOADING')

			const res = await orderService.cancelOrderElements(payload)

			return res
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		} finally {
			commit('STOP_LOADING')
		}
	},
	async finalizeOrder({ commit, dispatch }, order_id) {
		try {
			commit('SET_LOADING')

			const res = await orderService.finalizeOrder(order_id)

			return res
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		} finally {
			commit('STOP_LOADING')
		}
	},
	async deliverOrder({ commit, dispatch }, payload) {
		try {
			commit('SET_LOADING')

			const res = await orderService.deliverOrder(payload)

			return res
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		} finally {
			commit('STOP_LOADING')
		}
	},
	async requestPayment({ commit, dispatch }, order_id) {
		try {
			commit('SET_LOADING')

			const res = await orderService.requestPayment(order_id)

			return res
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		} finally {
			commit('STOP_LOADING')
		}
	},
	async receivePayment({ commit, dispatch }, payload) {
		try {
			commit('SET_LOADING')

			const res = await orderService.receivePayment(payload)

			return res
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		} finally {
			commit('STOP_LOADING')
		}
	},
	async getOrders({ commit, dispatch }, payload) {
		try {
			commit('SET_LOADING')

			const { orders } = await orderService.getOrders(payload)

			commit('SET_ORDERS', {
				order_type: payload.params.order_type,
				orders,
			})

			return orders
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		} finally {
			commit('STOP_LOADING')
		}
	},
	async loadMoreOrders({ commit, dispatch }, payload) {
		try {
			commit('SET_LOADING')

			const { orders, next } = await orderService.getOrders(payload)

			commit('LOADMORE_ORDERS', {
				page: payload.params.page,
				orders,
			})

			return next
		} catch ({ code, message }) {
			if (code === 'not_found') return Promise.resolve()
			dispatch('alert/error', message, { root: true })
		} finally {
			commit('STOP_LOADING')
		}
	},
	async registerElement({ dispatch }, payload) {
		try {
			return await orderService.registerElements(payload)
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		}
	},
}
const mutations = {
	SET_LOADING(state) {
		state.loading = true
	},
	STOP_LOADING(state) {
		state.loading = false
	},
	async SET_ORDERS(state, payload) {
		const { orders, order_type } = payload

		if (order_type === 'PENDING') {
			state.pendingOrders = await orders
		} else if (order_type === 'ANALYZED') {
			state.analyzedOrders = await orders
		} else {
			state.history = await orders
		}
	},
	async LOADMORE_ORDERS(state, payload) {
		const { page, orders } = payload

		if (page === 1) state.history = orders
		else state.history.push(...orders)
	},
}

const getters = {
	isLoading: (state) => state.loading,
	pendingOrders: (state) => state.pendingOrders,
	analyzedOrders: (state) => state.analyzedOrders,
	history: (state) => state.history,
}

export const orders = {
	namespaced: true,
	state,
	actions,
	mutations,
	getters,
}
