<template>
	<div>
		<a-row
			type="flex"
			justify="space-around"
			align="middle"
			class="form_container"
		>
			<a-col :xs="24" :sm="14" :md="12" :lg="12" :xl="8">
				<a-card
					style="border-radius: 5px;"
					:body-style="{ padding: '24px' }"
				>
					<a-form
						:form="form"
						style="margin-bottom: -24px;"
						@submit.prevent="handleSubmit"
					>
						<a-form-item has-feedback>
							<a-input
								v-decorator="[
									'email',
									{
										rules: [
											{
												type: 'email',
												message:
													'Este email não é válido!',
											},
											{
												required: true,
												message:
													'Por favor, informe o seu email!',
											},
										],
									},
								]"
								type="email"
								placeholder="Email"
							>
								<a-icon
									slot="prefix"
									type="mail"
									style="color: rgba(0, 0, 0, 0.25);"
								/>
							</a-input>
						</a-form-item>
						<a-form-item>
							<a-input-password
								v-decorator="[
									'password',
									{
										rules: [
											{
												required: true,
												message:
													'Por favor, informe a senha!',
											},
										],
									},
								]"
								type="password"
								placeholder="senha"
							>
								<a-icon
									slot="prefix"
									type="lock"
									style="color: rgba(0, 0, 0, 0.25);"
								/>
							</a-input-password>
						</a-form-item>
						<a-form-item>
							<a-checkbox
								v-decorator="[
									'remember',
									{
										valuePropName: 'checked',
										initialValue: true,
									},
								]"
							>
								Salvar acesso
							</a-checkbox>
							<router-link
								style="float: right;"
								to="/resetpassword"
								class="login-form-forgot"
							>
								Esqueci a senha
							</router-link>
							<a-button
								type="primary"
								html-type="submit"
								:loading="isLoading"
								block
								style="margin-top: 16px;"
							>
								Entrar
							</a-button>
						</a-form-item>
					</a-form>
				</a-card>
			</a-col>
		</a-row>
		<a-row type="flex" justify="space-around" align="middle">
			<div>
				Não cadastrou seu restaurante ainda?
				<router-link to="/register">
					<span style="margin-left: 4px;">
						Cadastrar agora
					</span>
				</router-link>
			</div>
		</a-row>
		<a-row
			type="flex"
			justify="space-around"
			align="middle"
			style="margin-top: 16px;"
		>
			<a-col :xs="24" :sm="14" :md="12" :lg="12" :xl="8">
				<SocialLogin @auth="onAuth" />
			</a-col>
		</a-row>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SocialLogin from './socialLogin'

export default {
	name: 'LoginForm',
	components: {
		SocialLogin,
	},
	data() {
		return {
			form: this.$form.createForm(this),
		}
	},
	computed: {
		...mapGetters({
			isLoading: 'account/isLoading',
		}),
	},
	methods: {
		...mapActions('account', ['auth']),
		handleSubmit() {
			this.form.validateFieldsAndScroll(async (err, values) => {
				if (!err) {
					try {
						const payload = {
							username: values.email,
							password: values.password,
							remember: values.remember,
						}

						await this.auth(payload)
						this.$router.push('/home')
					} catch (_) {
						//
					}
				}
			})
		},
		onAuth(validated) {
			if (validated) this.$router.push('/home/orders/')
		},
	},
}
</script>

<style lang="less" scoped>
.form_container {
	padding-top: 8%;
	padding-bottom: 8px;
}
</style>
