const SettingsRoutes = {
	path: 'settings',
	name: 'settings',
	component: () => import('../pages/settingsPage/settingsPage.vue'),
	meta: {
		title: 'Configurações',
		icon: 'setting',
		authorization: ['OWNER', 'MANAGER'],
	},
	hideChildrenInMenu: true,
	redirect: '/settings/merchant',
	children: [
		{
			path: '/settings/merchant',
			name: 'merchantSettings',
			component: () =>
				import('../pages/settingsPage/merchantSettings.vue'),
			meta: { title: 'Configurar apresentação', icon: 'appstore' },
		},
		{
			path: '/settings/operation',
			name: 'OperationSettings',
			component: () =>
				import('../pages/settingsPage/operationSettings.vue'),
			meta: { title: 'Configurar funcionamento', icon: 'clock-circle' },
		},
		{
			path: '/settings/images',
			name: 'ImagesSettings',
			component: () => import('../pages/settingsPage/imagesSettings.vue'),
			meta: { title: 'Gerenciar fotos', icon: 'picture' },
		},
		{
			path: '/settings/members',
			name: 'MembersSettings',
			component: () =>
				import('../pages/settingsPage/membersSettings.vue'),
			meta: { title: 'Gerenciar membros', icon: 'team' },
		},
		{
			path: '/settings/payments',
			name: 'PaymentsSettings',
			component: () =>
				import('../pages/settingsPage/paymentSettings.vue'),
			meta: {
				title: 'Configurar formas de pagamento',
				icon: 'credit-card',
			},
		},
		{
			path: '/settings/contract',
			name: 'CompanySettings',
			component: () =>
				import('../pages/settingsPage/companySettings.vue'),
			meta: { title: 'Informações do contrato', icon: 'file' },
		},
	],
}

export default SettingsRoutes
