const TablesRoutes = {
	path: 'tables',
	component: () => import('../pages/tablesPage/tablesPage.vue'),
	name: 'tables',
	meta: {
		title: 'Mesas',
		icon: 'border',
		authorization: ['OWNER', 'MANAGER', 'WAITER'],
	},
	redirect: '/tables/tables-board/',
	children: [
		{
			path: '/tables/tables-board/',
			name: 'tablesBoard',
			component: () => import('../pages/tablesPage/tablesBoard.vue'),
			meta: { title: 'Quadro de mesas', icon: 'border' },
		},
		{
			path: '/tables/:id',
			name: 'table-view',
			component: () => import('../pages/tablesPage/tableView.vue'),
			meta: { title: 'Mesa', icon: 'border' },
		},
	],
}

export default TablesRoutes
