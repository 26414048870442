<template>
	<div
		class="day"
		@mousedown="onMouseDown"
		@mouseup="onMouseUp"
		@mousemove="onMouseMove"
	>
		<period
			v-for="(period, index) in localPeriods"
			:key="index"
			ref="localPeriods"
			:top="getTop(period[0])"
			:height="getHeight(period[1])"
			:title="getPeriodTitle(period)"
			@delete="onDelete"
			@replicate="onReplicate"
			@resize="onResize"
			@periodTitle="(title) => (editingTitle = title)"
			@onMouseLeave="onMouseLeave"
		/>
		<div v-if="height >= 1" class="period-helper" :style="helperStyle">
			<div class="period-helper-time">
				{{ startTime + ' - ' + endTime }}
			</div>
		</div>
	</div>
</template>

<script>
import period from './period'

export default {
	name: 'Day',
	components: {
		period,
	},
	props: {
		editable: {
			type: Boolean,
			required: true,
		},
		periodInterval: {
			type: Number,
			required: true,
		},
		periodPosition: {
			type: Number,
			required: true,
		},
		periodDuration: {
			type: Number,
			required: true,
		},
		periods: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			//resizing variables
			resizing: false,
			resizingSide: '',
			indexOfResising: -1,
			startPoint: 0,
			newPeriod: [],
			//add helper variables
			helping: false,
			position: 0,
			height: 0,
			//grabbing variables
			isGrabbing: false,
			//general variables
			editingTitle: '',
			localPeriods: [],
			mouseClickState: 'up',
		}
	},
	computed: {
		helperStyle: function () {
			return {
				top: this.position + 'px',
				height: this.height + 'px',
			}
		},
		startTime: function () {
			return this.periodFormat(this.position)
		},
		endTime: function () {
			return this.periodFormat(this.position + this.height)
		},
	},
	watch: {
		periods: function () {
			this.localPeriods = this.periods
		},
	},
	methods: {
		onMouseDown(e) {
			this.mouseClickState = 'down'

			if (this.editable) {
				if (e.target.className === 'day') {
					this.position =
						e.clientY - e.target.getBoundingClientRect().top
					this.height = this.periodInterval
					this.helping = true
				} else if (e.target.className === 'period-container') {
					this.isGrabbing = true
					this.startPoint = e.clientY
					this.indexOfResising = this.periods.findIndex(
						(el) => el[0] + ' - ' + el[1] === this.editingTitle
					)
				} else if (
					typeof e.target.className === 'object' &&
					typeof e.path[1].className === 'string'
				) {
					const resizeNames = [
						'resize-top-icon',
						'resize-bottom-icon',
					]
					const clicked = e.path[1].className.split(' ')
					if (resizeNames.includes(clicked[0])) {
						this.resizing = true
						this.resizingSide =
							clicked[0] === 'resize-top-icon' ? 'top' : 'bottom'
						this.startPoint = e.clientY
						this.indexOfResising = this.periods.findIndex(
							(el) => el[0] + ' - ' + el[1] === this.editingTitle
						)
					}
				}
			}
		},
		onMouseMove(e) {
			if (this.editable && this.mouseClickState === 'down') {
				if (this.helping) {
					if (e.target.className === 'day') {
						this.height =
							e.clientY -
							e.target.getBoundingClientRect().top -
							this.position
					} else if (e.target.className === 'period-helper') {
						this.height =
							e.clientY - e.target.getBoundingClientRect().top
					}

					if (this.height <= 0) this.height = this.periodInterval
				} else if (this.isGrabbing) {
					const period = this.editingTitle
						.replace(/\s/g, '')
						.split('-')

					if (this.indexOfResising > -1)
						this.moving(e.clientY, period)
				} else if (this.resizing) {
					const period = this.editingTitle
						.replace(/\s/g, '')
						.split('-')

					if (this.indexOfResising > -1) {
						if (this.resizingSide === 'top')
							this.resizeTop(e.clientY, period)
						else this.resizeBotton(e.clientY, period)
					}
				}
			}
		},
		async onMouseUp() {
			this.mouseClickState = 'up'

			if (this.editable) {
				if (this.helping) {
					this.addPeriod()
				} else if (this.resizing) {
					await this.updatePeriod()
					this.resizing = false
					this.resizingSide = ''
					this.startPoint = 0
					this.indexOfResising = -1
				} else if (this.isGrabbing) {
					await this.updatePeriod()
					this.isGrabbing = false
				}
			}

			this.helping = false
			this.height = 0
		},
		async onMouseLeave() {
			if (this.mouseClickState === 'down') return

			if (this.editable) {
				if (this.helping) {
					this.addPeriod()
				} else if (this.resizing) {
					await this.updatePeriod()
					this.resizing = false
					this.resizingSide = ''
					this.startPoint = 0
					this.indexOfResising = -1
				} else if (this.isGrabbing) {
					await this.updatePeriod()
					this.isGrabbing = false
				}
			}

			this.helping = false
			this.height = 0
		},
		onDelete(title) {
			this.$emit('delete', title)
		},
		onReplicate(title) {
			this.$emit('replicate', title)
		},
		onResize(title) {
			this.editingTitle = title
		},
		onGrabbing(title) {
			this.grabbingTitle = title
		},
		positionFormat(time) {
			var split = time.split(':')
			var hour = parseInt(split[0])
			var mn = parseInt(split[1])

			let position = 0
			position += hour * this.periodDuration
			position += (mn * this.periodDuration) / 60

			return position
		},
		getTop(start) {
			return this.positionFormat(start) * this.periodPosition
		},
		getHeight(end) {
			return this.positionFormat(end) * this.periodPosition
		},
		getPeriodTitle(period) {
			return period[0] + ' - ' + period[1]
		},
		periodFormat: function (position) {
			var hour = Math.floor(position / 40)
			var mn =
				Math.floor((position % 40) / this.periodInterval) *
				this.periodDuration

			if (hour < 10) {
				hour = '0' + hour
			}
			if (mn < 10) {
				mn = '0' + mn
			}

			if (hour == '24') {
				hour = '23'
				mn = '59'
			}

			return hour + ':' + mn
		},
		addPeriod() {
			if (this.isValid(this.periods))
				this.$emit('add', [this.startTime, this.endTime])
		},
		updatePeriod() {
			const periods = this.periods.filter(
				(el) => this.getPeriodTitle(el) !== this.editingTitle
			)

			if (this.isValidToUpdate(periods))
				this.$emit('edit', {
					title: this.editingTitle,
					newPeriod: this.newPeriod,
				})
			else if (this.$refs.localPeriods[this.indexOfResising])
				this.$refs.localPeriods[this.indexOfResising].setResizing(false)
		},
		isValid() {
			let valid = true

			this.periods.forEach((period) => {
				if (period[0] > this.startTime && period[0] < this.endTime) {
					valid = false
				}

				if (period[1] > this.startTime && period[1] < this.endTime) {
					valid = false
				}

				if (period[0] < this.startTime && period[1] > this.endTime) {
					valid = false
				}

				if (
					period[0] === this.startTime ||
					period[1] === this.endTime
				) {
					valid = false
				}
			})

			return valid
		},
		isValidToUpdate(outherPeriods) {
			let valid = true

			outherPeriods.forEach((period) => {
				if (
					period[0] > this.newPeriod[0] &&
					period[0] < this.newPeriod[1]
				) {
					valid = false
				}

				if (
					period[1] > this.newPeriod[0] &&
					period[1] < this.newPeriod[1]
				) {
					valid = false
				}

				if (
					period[0] < this.newPeriod[0] &&
					period[1] > this.newPeriod[1]
				) {
					valid = false
				}

				if (
					period[0] === this.newPeriod[0] ||
					period[1] === this.newPeriod[1]
				) {
					valid = false
				}
			})

			return valid
		},
		resizeTop(clientY, period) {
			const distance = clientY - this.startPoint
			let top = this.getTop(period[0]) + distance
			const bottom = this.getHeight(period[1]) - top

			if (bottom >= this.periodInterval && top > 0) {
				this.newPeriod = [this.periodFormat(top), period[1]]
				this.$refs.localPeriods[this.indexOfResising].setResizing(
					true,
					top,
					bottom,
					this.getPeriodTitle(this.newPeriod)
				)
			}
		},
		resizeBotton(clientY, period) {
			const top = this.getTop(period[0])
			let bottom =
				this.getHeight(period[1]) + clientY - this.startPoint - top

			if (bottom < this.periodInterval) bottom = this.periodInterval
			if (top + bottom > 963) bottom = 963 - top

			this.newPeriod = [period[0], this.periodFormat(bottom + top)]
			this.$refs.localPeriods[this.indexOfResising].setResizing(
				true,
				null,
				bottom,
				this.getPeriodTitle(this.newPeriod)
			)
		},
		moving(clientY, period) {
			const distance = clientY - this.startPoint
			let top = this.getTop(period[0]) + distance
			const bottom = this.getHeight(period[1]) - top + distance

			if (
				bottom >= this.periodInterval &&
				top > 0 &&
				top + bottom < 963
			) {
				this.newPeriod = [
					this.periodFormat(top),
					this.periodFormat(bottom + top),
				]
				this.$refs.localPeriods[this.indexOfResising].setResizing(
					true,
					top,
					bottom,
					this.getPeriodTitle(this.newPeriod)
				)
			}
		},
	},
}
</script>

<style lang="less" scoped>
@zone: 20px;

.day {
	width: 100%;
	height: @zone * 2 * 24 + 2;
	position: relative;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	.period-helper {
		position: absolute;
		background: rgba(50, 50, 50, 0.1);
		top: 2px;
		bottom: 2px;
		left: 2px;
		right: 2px;
		padding: 0 3px;
		border-radius: 5px;
		box-sizing: border-box;

		&-time {
			padding: 2px 0 0 5px;
			font-size: 11px;
			font-weight: 700;
			line-height: $padding;
			letter-spacing: -0.5px;
			color: #666;
		}
	}
}
</style>
