const stateList = [
	'AC',
	'AL',
	'AP',
	'AM',
	'BA',
	'CE',
	'DF',
	'ES',
	'GO',
	'MA',
	'MT',
	'MS',
	'MG',
	'PA',
	'PB',
	'PR',
	'PE',
	'PI',
	'RJ',
	'RN',
	'RS',
	'RO',
	'RR',
	'SC',
	'SP',
	'SE',
	'TO',
]

const emitterList = [
	['SSP', 'Secretaria de Segurança Pública'],
	['DETRAN', 'Detran'],
	['SDS', 'Secretaria de Defesa Social'],
	['PM', 'Polícia Militar'],
	['PC', 'Policia Civil'],
	['CNT', 'Carteira Nacional de Habilitação'],
	['DIC', 'Diretoria de Identificação Civil'],
	['CTPS', 'Carteira de Trabaho e Previdência Social'],
	['FGTS', 'Fundo de Garantia do Tempo de Serviço'],
	['IFP', 'Instituto Félix Pacheco'],
	['IPF', 'Instituto Pereira Faustino'],
	['IML', 'Instituto Médico Legal'],
	['MTE', 'Ministério do Trabalho e Emprego'],
	['MMA', 'Ministério da Marinha'],
	['MAE', 'Ministério da Aeronáutica'],
	['MEX', 'Ministério do Exército'],
	['POF', 'Polícia Federal'],
	['POM', 'Polícia Militar'],
	['SES', 'Carteira de Estrangeiro'],
	['SJS', 'Secretaria da Justiça e Segurança'],
	['SJTS', 'Secretaria da Justiça do Trabalho e Segurança'],
]

export { stateList, emitterList }
