import { accountService } from '@/modules/core/services'
import firebase from 'firebase/app'
import 'firebase/auth'
//provedor de acesso via Google
const provider = new firebase.auth.GoogleAuthProvider()

const state = {
	loading: false,
	access_token: '',
	refresh_token: '',
	fcmToken: '',
	fcmDevice: '',
	loginMode: '',
	user: {},
	remember: false,
}

const actions = {
	async auth({ commit, dispatch }, { username, password, remember }) {
		try {
			commit('LOGIN_REQUEST')

			let res = await accountService.auth(username, password)

			if (!res.access_token) throw new Error('access token is invalid')

			const { access_token, refresh_token } = res

			res = await commit('AUTH_SUCCESS', {
				access_token: access_token,
				refresh_token: refresh_token,
				remember: remember,
			})

			return res
		} catch (_) {
			const err = {
				message: 'Acesso negado',
				description: 'As credenciais forneceidas são inválidas',
			}

			dispatch('alert/error', err, { root: true })

			return Promise.reject()
		} finally {
			commit('RESET_LOADING')
		}
	},
	async getUser({ commit, dispatch }) {
		try {
			const user = await accountService.getUser()

			commit('SET_USER', user)
		} catch (err) {
			dispatch('alert/error', err, { root: true })

			return Promise.reject()
		}
	},
	async updateUser({ commit, dispatch }, user) {
		try {
			const res = await accountService.updateUser(user)

			commit('SET_USER', res)

			return res
		} catch (err) {
			dispatch('alert/error', err, { root: true })

			return Promise.reject()
		}
	},
	async updateAvatar({ commit, dispatch }, img) {
		try {
			const res = await accountService.updateAvatar(img)

			commit('SET_USER', res)

			return res
		} catch (err) {
			dispatch('alert/error', err, { root: true })

			return Promise.reject()
		}
	},
	async register({ commit, dispatch }, data) {
		try {
			commit('LOGIN_REQUEST')

			const res = await accountService.register(data)

			const { access_token, refresh_token } = res

			commit('AUTH_SUCCESS', {
				access_token: access_token,
				refresh_token: refresh_token,
				remember: true,
			})

			return res
		} catch (err) {
			dispatch('alert/error', err, { root: true })
			return Promise.reject()
		} finally {
			commit('RESET_LOADING')
		}
	},
	async logout({ state, commit, dispatch }) {
		try {
			if (state.refresh_token !== '') {
				await accountService.logout(state.refresh_token)

				commit('LOGOUT')
			}
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		} finally {
			commit('RESET_LOADING')
		}
	},
	async refreshToken({ state, dispatch, commit }) {
		try {
			state.access_token = null
			const res = await accountService.refreshToken(state.refresh_token)
			const { access_token, refresh_token } = res

			await commit('AUTH_SUCCESS', {
				access_token: access_token,
				refresh_token: refresh_token,
				remember: state.access_token !== '',
			})

			return res
		} catch (err) {
			dispatch('alert/error', err, { root: true })

			return Promise.reject()
		}
	},
	async gSocialAuth({ commit, dispatch }) {
		try {
			const { credential } = await firebase
				.auth()
				.signInWithPopup(provider)

			const res = await accountService.gauth(credential.accessToken)
			const { access_token, refresh_token } = res

			await commit('AUTH_SUCCESS', {
				access_token: access_token,
				refresh_token: refresh_token,
				loginMode: 'google',
				remember: true,
			})

			return res
		} catch (err) {
			if (err.code) {
				if (err.code === 'auth/popup-closed-by-user')
					return Promise.reject()
			} else {
				dispatch('alert/error', err, { root: true })
			}
		}
	},
	acceptTerms({ dispatch }) {
		try {
			accountService.acceptTerms()
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		}
	},
	async setPassword({ dispatch }, payload) {
		try {
			const res = await accountService.setPassword(payload)
			return res
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		}
	},
	async forgotPassword({ dispatch }, payload) {
		try {
			const res = await accountService.forgotPassword(payload)
			return res
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		}
	},
	async setDevice({ commit }, device) {
		commit('SET_DEVICE', device)
	},
}

const mutations = {
	LOGIN_REQUEST(state) {
		state.loading = true
	},
	async AUTH_SUCCESS(state, payload) {
		state.access_token = payload.access_token
		state.refresh_token = payload.refresh_token
		state.loginMode = payload.loginMode
		state.remember = payload.remember
	},
	async LOGOUT(state) {
		state.access_token = ''
		state.refresh_token = ''
		state.loginMode = ''
		state.fcmToken = ''
		state.fcmDevice = ''
	},
	RESET_LOADING(state) {
		state.loading = false
	},
	SET_USER(state, user) {
		state.user = { ...state.user, ...user }
	},
	SET_DEVICE(state, device) {
		const { fcmToken, fcmDevice } = device

		state.fcmToken = fcmToken
		state.fcmDevice = fcmDevice
	},
}

const getters = {
	isLoading: (state) => state.loading,
	accessToken: (state) => state.access_token,
	fcmDevice: (state) => state.fcmDevice,
	isAuth: (state) => state.access_token !== null && state.access_token !== '',
	user: (state) => state.user,
	acceptedTerms: (state) => state.user.accepted_partner_terms,
	passwordTemp: (state) => state.user.password_temp,
	loginMode: (state) => state.loginMode,
}

export const account = {
	namespaced: true,
	state,
	actions,
	mutations,
	getters,
}
