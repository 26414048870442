import router from '@/router'
import { ifNotAuthenticated, ifAuthenticated } from '@/utils/authenticator'
import { SimpleLayout } from './pages'
import { PublicRoutes, UserSettingsRoutes } from './routes'

router.addRoutes([
	{
		path: '/',
		name: 'index',
		component: SimpleLayout,
		redirect: 'login',
		beforeEnter: ifNotAuthenticated,
		children: PublicRoutes,
	},
	{
		path: '/user',
		component: SimpleLayout,
		name: 'userSettings',
		beforeEnter: ifAuthenticated,
		redirect: '/user/settings',
		children: [UserSettingsRoutes],
	},
	{
		path: '*',
		redirect: '/404',
		hidden: true,
	},
])
