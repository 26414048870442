const HistoryOrdersRoutes = {
	path: 'history',
	name: 'history',
	component: () => import('../pages/historyOrdersPage/historyOrdersPage.vue'),
	meta: {
		title: 'Histórico',
		icon: 'history',
		authorization: ['OWNER', 'MANAGER', 'WAITER'],
	},
}

export default HistoryOrdersRoutes
