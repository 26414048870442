import { axios } from '@/utils/axios'

const urlBase = '/v1/merchants/'

export default {
	getAllSections,
	addSection,
	removeSection,
	updateSection,
	switchSection,
	sortElements,
	addItem,
	deleteItem,
	switchItem,
	editItem,
	addSubsection,
	removeSubsection,
	switchSubsection,
	updateSubsection,
	addSubitem,
	removeSubitem,
	switchSubitem,
	updateSubitem,
}

async function getAllSections(merchantId) {
	return await axios.get(`${urlBase}${merchantId}/menu/`)
}

async function addSection(payload) {
	const { merchantId, newSection } = payload

	return await axios.post(`${urlBase}${merchantId}/sections/`, newSection)
}

async function removeSection(payload) {
	const { merchantId, sectionId } = payload

	return await axios.delete(`${urlBase}${merchantId}/sections/${sectionId}/`)
}

async function updateSection(payload) {
	const { merchantId, sectionId, data } = payload

	return await axios.patch(
		`${urlBase}${merchantId}/sections/${sectionId}/`,
		data
	)
}

async function switchSection(payload) {
	const { merchantId, sectionId, is_visible } = payload

	return await axios.patch(`${urlBase}${merchantId}/sections/${sectionId}/`, {
		is_visible,
	})
}

async function addItem(payload) {
	const { merchantId, newItem } = payload

	return await axios.post(`${urlBase}${merchantId}/items/`, newItem)
}

async function deleteItem(payload) {
	const { merchantId, itemId } = payload

	return await axios.delete(`${urlBase}${merchantId}/items/${itemId}/`)
}

async function switchItem(payload) {
	const { merchantId, itemId, is_available } = payload

	return await axios.patch(`${urlBase}${merchantId}/items/${itemId}/`, {
		is_available,
	})
}

async function editItem(payload) {
	const { merchantId, itemId, data } = payload

	return await axios.patch(`${urlBase}${merchantId}/items/${itemId}/`, data)
}

async function addSubsection(payload) {
	const { merchantId, newSubsection } = payload

	return await axios.post(
		`${urlBase}${merchantId}/subsections/`,
		newSubsection
	)
}

async function removeSubsection(payload) {
	const { merchantId, subsectionId } = payload

	return await axios.delete(
		`${urlBase}${merchantId}/subsections/${subsectionId}/`
	)
}

async function switchSubsection(payload) {
	const { merchantId, subsectionId, is_visible } = payload

	return await axios.patch(
		`${urlBase}${merchantId}/subsections/${subsectionId}/`,
		{ is_visible }
	)
}

async function updateSubsection(payload) {
	const { merchantId, subsectionId, data } = payload

	return await axios.patch(
		`${urlBase}${merchantId}/subsections/${subsectionId}/`,
		data
	)
}

async function addSubitem(payload) {
	const { merchantId, newSubitem } = payload

	return await axios.post(`${urlBase}${merchantId}/subitems/`, newSubitem)
}

async function removeSubitem(payload) {
	const { merchantId, subitemId } = payload

	return await axios.delete(`${urlBase}${merchantId}/subitems/${subitemId}/`)
}

async function switchSubitem(payload) {
	const { merchantId, subitemId, is_available } = payload

	return await axios.patch(`${urlBase}${merchantId}/subitems/${subitemId}/`, {
		is_available,
	})
}

async function updateSubitem(payload) {
	const { merchantId, subitemId, data } = payload

	return await axios.patch(
		`${urlBase}${merchantId}/subitems/${subitemId}/`,
		data
	)
}

async function sortElements(payload) {
	const { merchantId, elements, positions } = payload

	return await axios.post(
		`${urlBase}${merchantId}/${elements}/change_positions/`,
		positions
	)
}

// //==============  funcoes locais  ============================//
// function formatItem(item) {
// 	var formData = new FormData()

// 	item.price = Number(item.price).toFixed(2)

// 	for (const key in item) {
// 		if (key === 'image') {
// 			if (item[key]) {
// 				const substrings = item.image.type.split('/')
// 				const fileName = +new Date() + '.' + substrings[1]
// 				item.image = new File([item.image], fileName, {
// 					type: item.image.type,
// 					lastModified: Date.now(),
// 				})
// 			} else item.image = ''

// 			formData.append('image', item[key])
// 		}
// 		formData.set(key, item[key])
// 	}

// 	return formData
// }
