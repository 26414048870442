const ProductsRoutes = {
	path: 'products',
	name: 'products',
	component: () => import('../pages/productsPage/productsPage.vue'),
	meta: {
		title: 'Produtos',
		icon: 'appstore',
		authorization: ['OWNER', 'MANAGER'],
	},
}

export default ProductsRoutes
