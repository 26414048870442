<template>
	<div
		class="period"
		:style="isResizing ? newPosition : position"
		@mouseup="onMouseUp"
		@mousedown="mouseClickState = 'down'"
		@mousemove="onMouseEnter"
		@mouseleave="onMouseLeave"
	>
		<div class="period-container">
			<div v-if="showResize" class="resize-top">
				<a-icon
					type="pause"
					:rotate="90"
					class="resize-top-icon"
					@mouseover="onResize"
				/>
			</div>
			<a-row type="flex" justify="space-between">
				<a-col :span="20">
					<div v-if="!isTooShort" class="period-title">
						{{ isResizing ? newTitle : title }}
					</div>
				</a-col>
				<a-col :span="4">
					<div>
						<a-dropdown placement="bottomCenter">
							<a-icon
								type="ellipsis"
								class="period-actions"
								:style="
									isTooShort ? 'top: -8px;' : 'top: -3px;'
								"
							/>
							<a-menu slot="overlay">
								<a-menu-item key="1">
									<span @click="onDelete">
										<a-icon
											type="delete"
											style="padding-right: 10px;"
										/>
										Remover
									</span>
								</a-menu-item>
								<a-menu-item key="2">
									<span @click="onReplicate">
										<a-icon
											type="copy"
											style="padding-right: 10px;"
										/>
										Replicar
									</span>
								</a-menu-item>
							</a-menu>
						</a-dropdown>
					</div>
				</a-col>
			</a-row>
			<div
				v-show="showResize"
				class="resize-bottom"
				:style="resizeBottomStyle"
			>
				<a-icon
					type="pause"
					:rotate="90"
					class="resize-bottom-icon"
					@mouseover="onResize"
				/>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Period',
	props: {
		top: {
			type: Number,
			required: true,
		},
		height: {
			type: Number,
			required: true,
		},
		title: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			showResize: false,
			isResizing: false,
			newTop: 0,
			newBottom: 0,
			mouseClickstate: 'up',
		}
	},
	computed: {
		position: function () {
			return {
				top: this.top + 2 + 'px',
				height: this.height - this.top - 2 + 'px',
			}
		},
		resizeBottomStyle: function () {
			return this.isResizing
				? `top: ${this.newBottom - 46}px`
				: `top: ${this.height - this.top - 46}px`
		},
		isTooShort: function () {
			return this.height - this.top - 2 < 10
		},
		newPosition: function () {
			return {
				top: this.newTop + 2 + 'px',
				height: this.newBottom - 2 + 'px',
			}
		},
	},
	methods: {
		onMouseEnter() {
			this.showResize = true
			this.$emit('periodTitle', this.title)
		},
		onMouseUp() {
			this.showResize = false
			this.isResizing = false
			this.$emit('onMouseUp')
		},
		onMouseLeave() {
			if (this.mouseClickstate === 'down') return

			this.showResize = false
			this.isResizing = false
			this.$emit('onMouseLeave')
		},
		onDelete() {
			this.$emit('delete', this.title)
		},
		onReplicate() {
			this.$emit('replicate', this.title)
		},
		onResize() {
			this.$emit('resize', this.title)
		},
		setResizing(resizing, top, bottom, title) {
			this.isResizing = resizing
			this.newBottom = bottom
			this.newTop = top || this.top
			this.newTitle = title
		},
	},
}
</script>

<style lang="less" scoped>
.period {
	position: absolute;
	width: 100%;
	min-height: 10px;

	&-container {
		position: absolute;
		background: #f6ffedbf;
		border: 1px solid #52c41a;
		top: 2px;
		bottom: 2px;
		left: 2px;
		right: 2px;
		padding: 0 3px;
		border-radius: 3px;
		box-sizing: border-box;
		cursor: grab;
		cursor: -moz-grab;
		cursor: -webkit-grab;

		&:active {
			cursor: grabbing;
			cursor: -moz-grabbing;
			cursor: -webkit-grabbing;
		}
	}

	&-title {
		font-size: 11px;
		font-weight: bold;
		line-height: 14px;
		letter-spacing: -0.5px;
		word-break: break-all;
		color: #687466;
		// overflow: hidden;
	}

	&-actions {
		font-size: 16px;
		position: relative;
		z-index: 9;
		cursor: pointer;
	}

	.resize-top {
		position: relative;
		top: 4px;
		height: 2px;
		margin-top: 0px;
		font-size: 18px;
		color: #687466;
		text-align: center;

		&-icon {
			position: relative;
			top: -19px;

			:hover {
				cursor: ns-resize;
			}
		}
	}
	.resize-bottom {
		position: relative;
		height: 1px;
		font-size: 18px;
		color: #687466;
		text-align: center;

		&-icon {
			:hover {
				cursor: ns-resize;
			}
		}
	}
}
</style>
