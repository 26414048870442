<template>
	<a-breadcrumb style="padding: 10px 0px;">
		<a-breadcrumb-item v-for="item in breadList" :key="item.name">
			<router-link v-if="item.name !== current" :to="item.path">
				<a-icon :type="item.meta.icon" />
				<span v-if="item.name !== 'home'" class="route">
					{{ item.meta.title }}
				</span>
			</router-link>
			<span v-else-if="item.meta.title !== ''" id="last">
				<a-icon :type="item.meta.icon" />
				<span class="route">{{ item.meta.title }}</span>
				<span v-if="item.meta.title === 'Mesa'">
					{{ $route.params.name }}
				</span>
			</span>
		</a-breadcrumb-item>
	</a-breadcrumb>
</template>

<script>
export default {
	name: 'Breadcrumb',
	data() {
		return {
			current: '',
			breadList: [],
		}
	},
	watch: {
		$route: {
			immediate: true,
			handler: 'getBreadcrumb',
		},
	},
	created() {
		this.getBreadcrumb()
	},
	methods: {
		getBreadcrumb() {
			this.breadList = []
			this.current = this.$route.name
			this.$route.matched.forEach((item) => {
				this.breadList.push(item)
			})
		},
	},
}
</script>

<style lang="less" scoped>
.route {
	margin-left: 8px;
}
#last {
	color: rgba(0, 0, 0, 0.5);
}
</style>
