<template>
	<a-row type="flex" justify="start" class="container" :gutter="32">
		<a-col>
			<h1 class="question">Você já possui uma conta Agiliza?</h1>
		</a-col>
		<a-col>
			<p class="description">
				Se você criou uma conta através de qualquer um dos nossos
				aplicativos, pode utilizá-la para cadastrar seus restaurantes e se
				tornar um dos nossos parceiros
			</p>
		</a-col>
		<a-col>
			<a-button-group class="button">
				<a-button
					class="button-left"
					:type="yesButtonType"
					@click="handleClick"
				>
					Sim
				</a-button>
				<a-button
					class="button-right"
					:type="noButtonType"
					@click="handleClick"
				>
					Não
				</a-button>
			</a-button-group>
		</a-col>
	</a-row>
</template>

<script>
export default {
	name: 'Question',
	data() {
		return {
			answer: true,
		}
	},
	computed: {
		yesButtonType: function () {
			return this.answer ? 'primary' : ''
		},
		noButtonType: function () {
			return !this.answer ? 'primary' : ''
		},
	},
	methods: {
		handleClick() {
			this.answer = !this.answer
			this.$emit('change', this.answer)
		},
	},
}
</script>

<style lang="less" scoped>
.container {
	flex-direction: column;

	.question {
		margin-top: 8px;
		font-size: 30px;
		font-weight: bold;
		color: rgba(0, 0, 0, 0.75);
	}

	.description {
		max-width: 452px;
		line-height: 24px;
		color: rgba(0, 0, 0, 0.65);
	}

	.button {
		margin-top: 16px;

		.button-left {
			height: 32px;
			border-top-left-radius: 17px;
			border-bottom-left-radius: 17px;
		}
		.button-right {
			height: 32px;
			border-top-right-radius: 17px;
			border-bottom-right-radius: 17px;
		}
	}
}
</style>
