<template>
	<a-modal
		title="Modificar Imagem"
		:visible="showModal"
		:mask-closable="false"
		:confirm-loading="confirmLoading"
		:width="800"
		:footer="null"
		@cancel="cancelHandel"
	>
		<a-row>
			<a-col :xs="24" :md="12" :style="{ height: '350px' }">
				<VueCropper
					ref="cropper"
					:img="options.img"
					:info="true"
					:output-type="options.outputType"
					:full="options.full"
					:auto-crop="options.autoCrop"
					:auto-crop-width="options.autoCropWidth"
					:auto-crop-height="options.autoCropHeight"
					:fixed-box="options.fixedBox"
					:center-box="options.centerBox"
					@realTime="realTime"
				>
				</VueCropper>
			</a-col>
			<a-col :xs="24" :md="12" :style="{ height: '350px' }">
				<div
					class="avatar-upload-preview"
					:style="{ borderRadius: previewRadius }"
				>
					<img :src="previews.url" :style="previews.img" />
				</div>
			</a-col>
		</a-row>
		<br />
		<a-row>
			<a-col :lg="2" :md="2">
				<a-upload
					name="file"
					:before-upload="beforeUpload"
					:show-upload-list="false"
				>
					<a-button icon="upload">Selecionar</a-button>
				</a-upload>
			</a-col>
			<a-col :lg="{ span: 1, offset: 2 }" :md="2">
				<a-button icon="plus" @click="changeScale(1)" />
			</a-col>
			<a-col :lg="{ span: 1, offset: 1 }" :md="2">
				<a-button icon="minus" @click="changeScale(-1)" />
			</a-col>
			<a-col :lg="{ span: 1, offset: 1 }" :md="2">
				<a-button icon="undo" @click="rotateLeft" />
			</a-col>
			<a-col :lg="{ span: 1, offset: 1 }" :md="2">
				<a-button icon="redo" @click="rotateRight" />
			</a-col>
			<a-col :lg="{ span: 2, offset: 6 }" :md="2">
				<a-button type="primary" @click="finish()">Salvar</a-button>
			</a-col>
		</a-row>
	</a-modal>
</template>
<script>
import { VueCropper } from 'vue-cropper'

export default {
	name: 'AvatarModal',
	components: {
		VueCropper,
	},
	data() {
		return {
			showModal: false,
			confirmLoading: false,
			options: {
				img: '',
				full: true,
				outputType: 'png',
				autoCrop: true,
				autoCropWidth: 200,
				autoCropHeight: 200,
				fixed: false,
				fixedBox: true,
				centerBox: false,
			},
			previews: {},
			previewRadius: '',
		}
	},
	methods: {
		setShowModal(radius) {
			this.showModal = true
			this.previewRadius = radius
		},
		close() {
			this.showModal = false
		},
		cancelHandel() {
			this.close()
		},
		changeScale(num) {
			num = num || 1
			this.$refs.cropper.changeScale(num)
		},
		rotateLeft() {
			this.$refs.cropper.rotateLeft()
		},
		rotateRight() {
			this.$refs.cropper.rotateRight()
		},
		beforeUpload(file) {
			const reader = new FileReader()

			reader.readAsDataURL(file)
			reader.onload = () => {
				this.options.img = reader.result
			}

			return false
		},
		finish() {
			this.$refs.cropper.getCropBlob((data) => {
				this.$emit('ok', data)
				this.showModal = false
			})
		},
		realTime(data) {
			this.previews = data
		},
	},
}
</script>

<style lang="less" scoped>
.avatar-upload-preview {
	position: absolute;
	top: 50%;
	transform: translate(50%, -50%);
	width: 200px;
	height: 200px;
	box-shadow: 0 0 4px #ccc;
	overflow: hidden;
	border-radius: 5px;
	box-shadow: 0 0 8px #ccc;

	img {
		width: 100%;
		height: 100%;
	}
}
</style>
