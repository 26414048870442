import { tableService } from '../services'
import { userStorage } from '@/utils'

const state = {
	loading: false,
	tables: [],
	selectedTable: { id: '', name: '', orders: [] },
	sliderSize: 100,
}

const actions = {
	async fetchTables({ commit, dispatch, rootGetters }) {
		try {
			commit('SET_LOADING')

			const res = await tableService.getAll(
				rootGetters['merchants/selectedMerchantId']
			)

			commit('SET_TABLES', res)

			return Promise.resolve()
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		} finally {
			commit('STOP_LOADING')
		}
	},
	async selectTable({ commit, dispatch, rootGetters }, tableId) {
		try {
			commit('SET_LOADING')

			const payload = {
				merchantId: rootGetters['merchants/selectedMerchantId'],
				tableId,
			}

			const res = await tableService.getTable(payload)

			commit('SET_SELECTED_TABLE', res)

			return res
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		} finally {
			commit('STOP_LOADING')
		}
	},
	async switchTable({ commit, dispatch }, payload) {
		try {
			commit('SET_LOADING')

			const res = await tableService.switchTable(payload)

			commit('SET_SELECTED_TABLE', res)

			return Promise.resolve()
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		} finally {
			commit('STOP_LOADING')
		}
	},
	async editTable({ commit, dispatch }, payload) {
		try {
			commit('SET_LOADING')

			const res = await tableService.editTable(payload)
			res.index = payload.index

			commit('EDIT_TABLE', res)
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		} finally {
			commit('STOP_LOADING')
		}
	},
	sliderChange({ commit }, value) {
		commit('SET_SLIDER', value)
	},
}

const mutations = {
	SET_LOADING(state) {
		state.loading = true
	},
	STOP_LOADING(state) {
		state.loading = false
	},
	EDIT_TABLE(state, payload) {
		state.tables[payload.index].name = payload.name
	},
	SET_TABLES(state, tables) {
		state.tables = tables || []
	},
	SET_SELECTED_TABLE(state, table) {
		const { selectedTable } = state

		for (const key in selectedTable)
			if (table[key]) state.selectedTable[key] = table[key]
	},
	SET_SLIDER(state, value) {
		state.sliderSize = value
		userStorage.set({ slideSize: value })
	},
}

const getters = {
	isLoading: (state) => state.loading,
	allTables: (state) => state.tables,
	totalTables: (state) => state.tables.length,
	sliderSize: (state) => state.sliderSize,
	tableSelected: (state) => state.selectedTable,
}

export const tables = {
	namespaced: true,
	state,
	actions,
	mutations,
	getters,
}
