<template>
	<div>
		<p class="header">Cadastrar conta</p>
		<div class="body">
			<a-form :form="form" class="form" @submit.prevent="handleSubmit">
				<a-form-item
					label="Nome"
					has-feedback
					style="margin-bottom: 8px;"
				>
					<a-input
						v-decorator="[
							'first_name',
							{
								rules: [
									{
										required: true,
										message: 'Por favor, informe um nome',
									},
								],
							},
						]"
						placeholder="Informe o seu primeiro nome"
					/>
				</a-form-item>
				<a-form-item
					label="Sobrenome"
					has-feedback
					style="margin-bottom: 8px;"
				>
					<a-input
						v-decorator="[
							'last_name',
							{
								rules: [
									{
										required: true,
										message:
											'Por favor, informe seu sobrenome',
									},
								],
							},
						]"
						placeholder="Informe o seu sobrenome"
					/>
				</a-form-item>
				<a-form-item
					label="Email"
					has-feedback
					style="margin-bottom: 8px;"
				>
					<a-input
						v-decorator="[
							'email',
							{
								rules: [
									{
										type: 'email',
										message: 'Este email não é válido!',
									},
									{
										required: true,
										message: 'Por favor, informe um email!',
									},
								],
							},
						]"
						placeholder="Informe o email de acesso para o seu usuário"
					/>
				</a-form-item>
				<a-form-item label="Senha" style="margin-bottom: 16px;">
					<a-input-password
						v-decorator="[
							'password',
							{
								rules: [
									{
										required: true,
										message:
											'Por favor, informe uma senha!',
									},
									{
										min: 6,
										message:
											'Tamanho mínimo de 6 caracteres!',
									},
								],
							},
						]"
						placeholder="Forneça uma senha de acesso para o seu usuário"
					/>
				</a-form-item>
				<!-- <a-form-item>
					<a-checkbox @change="onCheckChange">
						Eu li e aceito os
						<router-link to="/terms" target="_blank">
							Termos do uso e Política de privacidade
						</router-link>
					</a-checkbox>
				</a-form-item> -->
				<a-button
					type="primary"
					html-type="submit"
					:loading="isLoading"
					block
					style="margin-top: 32px;"
				>
					Continuar
				</a-button>
			</a-form>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'RegisterAccountForm',
	data() {
		return {
			form: this.$form.createForm(this),
		}
	},
	computed: {
		...mapGetters({
			isLoading: 'account/isLoading',
		}),
	},
	methods: {
		...mapActions('account', ['register']),
		onCheckChange(e) {
			this.agree = e.target.checked
		},
		handleSubmit() {
			this.form.validateFieldsAndScroll(async (err, values) => {
				if (!err) {
					await this.register(values)
					this.$router.push({ name: 'partner-info' })
				}
			})
		},
	},
}
</script>

<style lang="less" scoped>
.header {
	margin-bottom: 16px;
	font-size: 22px;
	font-weight: 501;
	text-align: center;
	color: rgba(0, 0, 0, 0.75);
}
</style>
