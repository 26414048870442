<template>
	<div>
		<a-row>
			<a-col>
				<a-form :form="form" @submit.prevent="handleSubmit">
					<a-row :gutter="16">
						<a-col span="12">
							<a-form-item label="CPF" has-feedback>
								<a-input
									v-decorator="[
										'cpf',
										{
											initialValue: user.cpf,
											rules: [
												{
													required: true,
													message:
														'Por favor, informe o número do seu CPF!',
												},
												{
													validator: onValidateCPF,
												},
											],
										},
									]"
									v-mask="'###.###.###-##'"
									placeholder="Informe o seu CPF"
								/>
							</a-form-item>
						</a-col>
						<a-col span="12">
							<a-form-item
								label="Celular (whatsapp)"
								has-feedback
							>
								<a-input
									v-decorator="[
										'phone',
										{
											initialValue: user.phone,
											rules: [
												{
													required: true,
													message:
														'Por favor, informe um número de celular!',
												},
												{
													pattern: /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})\-?(\d{4}))$/,
													message:
														'Por favor, informe um número válido',
												},
											],
										},
									]"
									v-mask="[
										'(##) ####-####',
										'(##) #####-####',
									]"
									placeholder="Informe o número do celular para o seu usuário"
								/>
							</a-form-item>
						</a-col>
					</a-row>
					<a-row :gutter="16">
						<a-col :span="10">
							<a-form-item label="RG/CNH" has-feedback>
								<a-input
									v-decorator="[
										'rg_number',
										{
											initialValue: user.rg_number,
											rules: [
												{
													required: true,
													message:
														'Por favor, informe o numero do RG/CNH!',
												},
												{
													max: 11,
													message:
														'Limite máximo de 11 caracteres',
												},
											],
										},
									]"
									placeholder="Informe somente números para RG/CNH"
									type="tel"
								/>
							</a-form-item>
						</a-col>
						<a-col :span="9">
							<a-form-item label="Emissor" has-feedback>
								<a-select
									v-decorator="[
										'rg_agency',
										{
											initialValue: user.rg_agency,
											rules: [
												{
													required: true,
													message:
														'Por favor, selecione um orgão emissor!',
												},
											],
										},
									]"
									placeholder="Selecione um orgão emissor"
									class="select"
								>
									<a-select-option
										v-for="(emitter, index) in emitterList"
										:key="index"
										:value="emitter[0]"
									>
										{{ emitter[0] + ' - ' + emitter[1] }}
									</a-select-option>
								</a-select>
							</a-form-item>
						</a-col>
						<a-col :span="5">
							<a-form-item label="Estado" has-feedback>
								<a-select
									v-decorator="[
										'rg_state',
										{
											initialValue: user.rg_state,
											rules: [
												{
													required: true,
													message:
														'Por favor, selecione um estado!',
												},
											],
										},
									]"
									placeholder="Selecione um estado"
									class="select"
								>
									<a-select-option
										v-for="state in stateList"
										:key="state"
										:value="state"
									>
										{{ state }}
									</a-select-option>
								</a-select>
							</a-form-item>
						</a-col>
					</a-row>
					<a-row
						type="flex"
						justify="center"
						class="buttons"
						style="padding: 25px 0;"
					>
						<a-form-item>
							<a-col>
								<a-button
									type="primary"
									style="width: 300px;"
									html-type="submit"
									icon="save"
								>
									Salvar
								</a-button>
							</a-col>
						</a-form-item>
					</a-row>
				</a-form>
			</a-col>
		</a-row>
	</div>
</template>

<script>
import { emitterList, stateList } from '@/assets/emitterStateList'
import { mask } from 'vue-the-mask'
import { validateCPF } from '@/utils/utils'
import { mapGetters, mapActions } from 'vuex'

export default {
	name: '',
	directives: { mask },
	data() {
		return {
			form: this.$form.createForm(this),
			emitterList,
			stateList,
		}
	},
	computed: {
		...mapGetters({
			user: 'account/user',
		}),
	},
	methods: {
		...mapActions('account', ['updateUser']),
		handleSubmit() {
			this.form.validateFields(async (err, values) => {
				if (!err) {
					this.$message.loading({
						content: 'Atualizando...',
						key: 'updatable',
					})
					await this.updateUser(values)
					this.$message.success({
						content: 'Atualizado',
						key: 'updatable',
						duration: 4,
					})
				}
			})
		},
		onValidateCPF(rule, value, callback) {
			if (value && value.length == 14 && !validateCPF(value)) {
				callback('Por favor, informe um CPF válido!')
			} else {
				callback()
			}
		},
	},
}
</script>
