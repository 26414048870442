<template>
	<a-card style="border-radius: 5px;" :body-style="{ padding: '24px' }">
		<h4>Entrar usando sua</h4>
		<a-button
			icon="google"
			block
			style="margin-top: 8px;"
			:loading="loading"
			@click="loginWithGoogle"
		>
			conta do Google
		</a-button>
	</a-card>
</template>

<script>
import { mapActions } from 'vuex'

export default {
	name: 'SocialLogin',
	data() {
		return {
			loading: false,
		}
	},
	methods: {
		...mapActions('account', ['gSocialAuth']),
		async loginWithGoogle() {
			try {
				this.loading = true

				await this.gSocialAuth()
				this.$emit('auth', true)
			} catch (error) {
				//
			} finally {
				this.loading = false
			}
		},
	},
}
</script>
