<template>
	<div class="vws">
		<table class="table">
			<tr>
				<td v-for="element in dataTable" :key="element.day">
					<Day
						:editable="editable"
						:hour="hour"
						:period-interval="periodInterval"
						:period-position="periodPosition"
						:period-duration="periodDuration"
						:period-height="periodHeight"
						:periods="getPeriods(element.day)"
						@add="(period) => onAddPeriod(element.day, period)"
						@delete="
							(period_index) =>
								onDeletePeriod(element.day, period_index)
						"
						@replicate="onReplicatePeriod"
						@edit="(payload) => onEdit(element.day, payload)"
					/>
				</td>
			</tr>
		</table>

		<div class="grid">
			<div class="grid-head">
				<div v-for="day in days" :key="day" class="grid-day">
					{{ day }}
				</div>
			</div>
			<div v-for="lineHour in 25" :key="lineHour" class="grid-line">
				<div class="grid-hour">
					{{ formatHour(lineHour - 1) }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Day from './components/day'

export default {
	name: 'WeeklyScheduler',
	components: {
		Day,
	},
	props: {
		editable: {
			type: Boolean,
			default: true,
		},
		hour: {
			type: Number,
			default: 24,
			validator: function (value) {
				return [12, 24].indexOf(value) !== -1
			},
		},
		periodDuration: {
			type: Number,
			default: 30,
			validator: function (value) {
				return [15, 30, 60].indexOf(value) !== -1
			},
		},
		data: {
			type: Array,
			default: function () {
				return []
			},
		},
		periodOptions: {
			type: Boolean,
			default: true,
		},
		periodColors: {
			type: Array,
			default: function () {
				return []
			},
		},
		periodTitle: {
			type: String,
			default: '',
		},
		periodBackgroundColor: {
			type: String,
			default: '',
		},
		periodBorderColor: {
			type: String,
			default: '',
		},
		periodTextColor: {
			type: String,
			default: '',
		},
		periodRemoveIcon: {
			type: String,
			default: '',
		},
		periodDuplicateIcon: {
			type: String,
			default: '',
		},
		periodTitlePlaceholder: {
			type: String,
			default: 'Title',
		},
		days: {
			type: Array,
			default: function () {
				return [
					'Segunda',
					'Terça',
					'Quarta',
					'Quinta',
					'Sexta',
					'Sábado',
					'Domingo',
				]
			},
		},
	},
	computed: {
		periodInterval: function () {
			return 40 / (60 / this.periodDuration)
		},
		periodHeight: function () {
			return 24 * 40
		},
		periodPosition: function () {
			return 40 / this.periodDuration
		},
		dataTable: function () {
			let table = []

			for (let index = 0; index < this.days.length; index++)
				table.push({ day: index, periods: [] })

			table.forEach((part) => {
				const dayData = this.data.filter((e) => e.day === part.day)
				part.periods = dayData.length > 0 ? dayData[0].periods : []
			})

			return table
		},
	},
	methods: {
		onAddPeriod(index, period) {
			this.$emit('add', {
				day: index,
				period: period,
			})
		},
		onEdit(index, payload) {
			this.$emit('edit', {
				day: index,
				payload,
			})
		},
		onDeletePeriod(day, title) {
			this.$emit('delete', { day: day, period: title })
		},
		onReplicatePeriod(title) {
			this.$emit('replicate', title)
		},
		formatHour(hour) {
			if (hour >= 24) {
				hour = 0
			}

			if (hour < 10) {
				hour = '0' + hour
			}
			hour += ':00'

			return hour
		},
		getPeriods(index) {
			if (this.dataTable.length > 0) return this.dataTable[index].periods
			else return []
		},
	},
}
</script>

<style lang="less" scoped>
@gutter: 20px;
@zone: 20px;
@padding: 16px;

.vws {
	position: relative;
	padding: @gutter*2 @gutter @gutter @gutter*3;
	box-sizing: border-box;
	min-width: 754px;

	.table {
		position: relative;
		z-index: 8;
		width: 100%;
		margin-bottom: @gutter;
		border-collapse: collapse;
		border-spacing: 0;
		box-sizing: content-box;
		td,
		th {
			padding: 0;
		}
		td {
			position: relative;
			width: (100%/7);
			border-left: 1px solid #ccc;
			border-right: 1px solid #ccc;
		}

		&-day {
			width: 100%;
			height: @zone * 2 * 24;
			position: relative;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
		}
	}

	.grid {
		position: absolute;
		top: @gutter * 2;
		left: @gutter * 3;
		right: @gutter;
		box-sizing: content-box;
		font-weight: bold;
		color: rgba(0, 0, 0, 0.5);

		&-head {
			position: absolute;
			top: (-@gutter - 10px);
			left: 15px;
			right: 0;
			display: flex;
		}

		&-day {
			position: relative;
			display: flex;
			width: (100%/7);
			padding: 8px 0 4px 0;
			align-items: center;
			justify-content: center;
			font-size: 12px;

			&:hover,
			.buttons {
				.vws-day-remove,
				.vws-day-duplicate {
					display: block;
				}
			}
		}

		&-line {
			border-top: 1px solid #ccc;
			border-bottom: 1px solid transparent;
			position: relative;
			height: @zone*2 - 2;
			box-sizing: content-box;
			&:last-child {
				height: 0;
				border-bottom: none;
			}
			&:before {
				content: '';
				position: absolute;
				top: 50%;
				height: 1px;
				width: 100%;
				background: #eee;
			}
		}

		&-hour {
			position: absolute;
			top: (@zone / 2 - 3) * -1;
			left: (@gutter * 2.5) * -1;
			display: block;
			width: 42px;
			font-size: 11px;
			text-align: right;
		}
	}
}
</style>
