var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":15}},[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)}}},[_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":"12"}},[_c('a-form-item',{attrs:{"label":"Nome","has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
									'first_name',
									{
										initialValue: _vm.user.first_name,
										rules: [
											{
												required: true,
												message:
													'Por favor, informe um nome!',
											} ],
									} ]),expression:"[\n\t\t\t\t\t\t\t\t\t'first_name',\n\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\tinitialValue: user.first_name,\n\t\t\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\t\t\tmessage:\n\t\t\t\t\t\t\t\t\t\t\t\t\t'Por favor, informe um nome!',\n\t\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t]"}],attrs:{"placeholder":"Informe o seu nome"}})],1)],1),_c('a-col',{attrs:{"span":"12"}},[_c('a-form-item',{attrs:{"label":"Sobrenome","has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
									'last_name',
									{
										initialValue: _vm.user.last_name,
										rules: [
											{
												required: true,
												message:
													'Por favor, informe seu sobrenome!',
											} ],
									} ]),expression:"[\n\t\t\t\t\t\t\t\t\t'last_name',\n\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\tinitialValue: user.last_name,\n\t\t\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\t\t\tmessage:\n\t\t\t\t\t\t\t\t\t\t\t\t\t'Por favor, informe seu sobrenome!',\n\t\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t]"}],attrs:{"placeholder":"Informe o seu sobrenome"}})],1)],1)],1),_c('a-row',[_c('a-col',[_c('a-form-item',{attrs:{"label":"Email","has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
									'email',
									{
										initialValue: _vm.user.email,
										rules: [
											{
												type: 'email',
												message:
													'Este email não é válido!',
											} ],
									} ]),expression:"[\n\t\t\t\t\t\t\t\t\t'email',\n\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\tinitialValue: user.email,\n\t\t\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\t\ttype: 'email',\n\t\t\t\t\t\t\t\t\t\t\t\tmessage:\n\t\t\t\t\t\t\t\t\t\t\t\t\t'Este email não é válido!',\n\t\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t]"}],attrs:{"disabled":""}})],1)],1)],1),_c('a-row',{staticClass:"buttons",staticStyle:{"padding":"25px 0"},attrs:{"type":"flex","justify":"center"}},[_c('a-form-item',[_c('a-col',[_c('a-button',{staticStyle:{"width":"300px"},attrs:{"type":"primary","html-type":"submit","icon":"save"}},[_vm._v(" Salvar ")])],1)],1)],1)],1)],1),_c('a-col',{attrs:{"span":9}},[_c('a-row',{style:({ minHeight: '140px', padding: '10px 0 15px 0' })},[_c('span',{staticClass:"avatar-label"},[_vm._v("Avatar:")]),_c('div',{staticClass:"ant-upload-preview",on:{"click":function($event){return _vm.$refs.modal.setShowModal('50%')}}},[_c('div',{staticClass:"mask"},[_c('a-icon',{attrs:{"type":"edit"}})],1),_c('img',{attrs:{"src":_vm.preview,"icon":"user"}})]),_c('a-button',{attrs:{"type":"link","icon":"close","block":""},on:{"click":function($event){return _vm.setAvatar(null)}}},[_vm._v(" Remover imagem ")])],1)],1)],1),_c('AvatarModal',{ref:"modal",on:{"ok":_vm.setAvatar}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }