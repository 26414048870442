import { menuService } from '../services'
import { capitalizeFirstLetter } from '@/utils/utils'

const state = {
	loading: false,
	adding: false,
	sections: [],
	sectionSelected: undefined,
	loadingSubsections: false,
}

const actions = {
	async addSection({ commit, dispatch }, payload) {
		try {
			commit('SET_LOADING')

			payload.newSection.name = capitalizeFirstLetter(
				payload.newSection.name
			)

			const res = await menuService.addSection(payload)

			commit('ADD_SECTION', res)

			return res
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		} finally {
			commit('STOP_LOADING')
		}
	},
	async editSection({ commit, dispatch }, payload) {
		try {
			commit('SET_LOADING')

			payload.data.name = capitalizeFirstLetter(payload.data.name)

			const res = await menuService.updateSection(payload)

			commit('EDIT_SECTION', res)

			return res
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		} finally {
			commit('STOP_LOADING')
		}
	},
	switchSection({ commit, dispatch }, payload) {
		try {
			commit('SWITCH_SECTION', payload)

			menuService.switchSection(payload)
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		}
	},
	async removeSection({ commit, dispatch }, payload) {
		try {
			commit('SET_LOADING')

			await menuService.removeSection(payload)

			commit('REMOVE_SECTION', payload)
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		} finally {
			commit('STOP_LOADING')
		}
	},
	setCurrentSection({ commit }, sectionPosition) {
		commit('SET_CURRENT_SECTION', sectionPosition)
	},
	async fetchSections({ commit, dispatch, rootGetters }) {
		try {
			commit('SET_LOADING')

			const res = await menuService.getAllSections(
				rootGetters['merchants/selectedMerchantId']
			)

			commit('SET_SECTIONS', res)
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		} finally {
			commit('STOP_LOADING')
		}
	},
	async addItem({ commit, dispatch }, payload) {
		try {
			commit('SET_LOADING')

			const res = await menuService.addItem(payload)

			commit('ADD_ITEM', res)
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		} finally {
			commit('STOP_LOADING')
		}
	},
	async deleteItem({ commit, dispatch }, payload) {
		try {
			commit('SET_LOADING')

			const res = await menuService.deleteItem(payload)

			commit('REMOVE_ITEM', payload)

			return res
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		} finally {
			commit('STOP_LOADING')
		}
	},
	switchItem({ commit, dispatch }, payload) {
		try {
			commit('SWITCH_ITEM', payload)

			menuService.switchItem(payload)
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		}
	},
	async editItem({ commit, dispatch }, payload) {
		try {
			commit('SET_LOADING')

			const res = await menuService.editItem(payload)

			commit('UPDATE_ITEM', res)

			return res
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		} finally {
			commit('STOP_LOADING')
		}
	},
	async addSubsection({ commit, dispatch }, payload) {
		try {
			commit('SET_LOADING_SUBSECTION')

			const res = await menuService.addSubsection(payload)

			commit('ADD_SUBSECTION', res)

			return res
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		} finally {
			commit('STOP_LOADING_SUBSECTION')
		}
	},
	async removeSubsection({ commit, dispatch }, payload) {
		try {
			commit('SET_LOADING_SUBSECTION')
			await menuService.removeSubsection(payload)
			commit('REMOVE_SUBSECTION', payload)
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		} finally {
			commit('STOP_LOADING_SUBSECTION')
		}
	},
	async switchSubsection({ commit, dispatch }, payload) {
		try {
			commit('SWITCH_SUBSECTION', payload)

			menuService.switchSubsection(payload)
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		}
	},
	async updateSubsection({ commit, dispatch }, payload) {
		try {
			commit('SET_LOADING_SUBSECTION')

			payload.data = await menuService.updateSubsection(payload)

			commit('UPDATE_SUBSECTION', payload)

			return payload.data
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		} finally {
			commit('STOP_LOADING_SUBSECTION')
		}
	},
	async addSubitem({ commit, dispatch }, payload) {
		try {
			commit('SET_LOADING_SUBSECTION')

			payload.newSubitem = await menuService.addSubitem(payload)

			commit('ADD_SUBITEM', payload)

			return payload.newSubitem
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		} finally {
			commit('STOP_LOADING_SUBSECTION')
		}
	},
	switchSubitem({ commit, dispatch }, payload) {
		try {
			commit('SWITCH_SUBITEM', payload)

			menuService.switchSubitem(payload)
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		}
	},
	async removeSubitem({ commit, dispatch }, payload) {
		try {
			commit('SET_LOADING_SUBSECTION')

			await menuService.removeSubitem(payload)

			commit('REMOVE_SUBITEM', payload)
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		} finally {
			commit('STOP_LOADING_SUBSECTION')
		}
	},
	async updateSubitem({ commit, dispatch }, payload) {
		try {
			commit('SET_LOADING_SUBSECTION')

			payload.data = await menuService.updateSubitem(payload)

			commit('UPDATE_SUBITEM', payload)

			return payload.data
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		} finally {
			commit('STOP_LOADING_SUBSECTION')
		}
	},
	setAddingSections({ commit }, value) {
		commit('SET_ADDING_SECTIONS', value)
	},
	sortElements({ commit, dispatch }, payload) {
		try {
			switch (payload.elements) {
				case 'sections':
					commit('SORT_SECTIONS', payload)
					break
				case 'items':
					commit('SORT_ITEMS', payload)
					break
				case 'subsections':
					commit('SORT_SUBSECTIONS', payload)
					break
				case 'subitems':
					commit('SORT_SUBITEMS', payload)
					break
			}
			menuService.sortElements(payload)
		} catch (err) {
			dispatch('alert/error', err, { root: true })
		} finally {
			commit('STOP_LOADING')
		}
	},
}

const mutations = {
	SET_LOADING(state) {
		state.loading = true
	},
	STOP_LOADING(state) {
		state.loading = false
	},
	SET_LOADING_SUBSECTION(state) {
		state.loadingSubsections = true
	},
	STOP_LOADING_SUBSECTION(state) {
		state.loadingSubsections = false
	},
	SET_ADDING_SECTIONS(state, value) {
		state.adding = value
	},
	SET_SECTIONS(state, sections) {
		state.sections = sections
		if (state.sectionSelected === undefined && sections.length > 0)
			state.sectionSelected = sections[0]
		else
			state.sectionSelected = sections.find(
				(section) => section.id === state.sectionSelected.id
			)
	},
	ADD_SECTION(state, section) {
		section.items = []
		state.sections.push(section)

		state.sectionSelected = state.sections.find(
			(el) => el.id === section.id
		)
	},
	EDIT_SECTION(state, payload) {
		let { sections } = state
		let index = sections.findIndex((section) => section.id === payload.id)

		sections[index] = { ...sections[index], ...payload }
		state.sectionSelected = state.sections[index]
	},
	SWITCH_SECTION(state, payload) {
		const index = state.sections.findIndex(
			(section) => section.id === payload.sectionId
		)
		state.sections[index].is_visible = payload.is_visible
		state.sectionSelected.is_visible = payload.is_visible
	},
	REMOVE_SECTION(state, payload) {
		const { sectionId } = payload

		state.sections = state.sections.filter(
			(section) => section.id !== sectionId
		)

		state.sectionSelected =
			state.sections.length > 0 ? state.sections[0] : {}
	},
	SET_CURRENT_SECTION(state, sectionPosition) {
		state.sectionSelected = state.sections.find(
			(section) => section.position === sectionPosition
		)
	},
	ADD_ITEM(state, newItem) {
		const { sectionSelected } = state

		sectionSelected.items.push(newItem)
	},
	REMOVE_ITEM(state, payload) {
		const { sectionSelected } = state

		const index = state.sectionSelected.items.findIndex(
			(item) => item.id === payload.itemId
		)

		sectionSelected.items.splice(index, 1)
	},
	UPDATE_ITEM(state, data) {
		const index = state.sectionSelected.items.findIndex(
			(item) => item.id === data.id
		)

		for (const key in data) {
			state.sectionSelected.items[index][key] = data[key]
		}
	},
	SWITCH_ITEM(state, payload) {
		const index = state.sectionSelected.items.findIndex(
			(item) => item.id === payload.itemId
		)
		state.sectionSelected.items[index].is_available = payload.is_available
	},
	ADD_SUBSECTION(state, data) {
		const index = state.sectionSelected.items.findIndex(
			(item) => item.id === data.item_id
		)

		state.sectionSelected.items[index].subsections.push(data)
	},
	SWITCH_SUBSECTION(state, payload) {
		const { item_id, subsectionId, is_visible } = payload

		const itemIndex = state.sectionSelected.items.findIndex(
			(item) => item.id === item_id
		)

		const subsectionIndex = state.sectionSelected.items[
			itemIndex
		].subsections.findIndex((subsection) => subsection.id === subsectionId)

		state.sectionSelected.items[itemIndex].subsections[
			subsectionIndex
		].is_visible = is_visible
	},
	REMOVE_SUBSECTION(state, payload) {
		const { item_id, subsectionId } = payload

		const itemIndex = state.sectionSelected.items.findIndex(
			(item) => item.id === item_id
		)

		state.sectionSelected.items[
			itemIndex
		].subsections = state.sectionSelected.items[
			itemIndex
		].subsections.filter((subsection) => subsection.id !== subsectionId)
	},
	UPDATE_SUBSECTION(state, payload) {
		const { item_id, subsectionId, data } = payload

		const itemIndex = state.sectionSelected.items.findIndex(
			(item) => item.id === item_id
		)

		const subsectionIndex = state.sectionSelected.items[
			itemIndex
		].subsections.findIndex((subsection) => subsection.id === subsectionId)

		for (const key in data) {
			state.sectionSelected.items[itemIndex].subsections[subsectionIndex][
				key
			] = data[key]
		}
	},
	ADD_SUBITEM(state, payload) {
		const { itemId, newSubitem } = payload

		let itemIndex = state.sectionSelected.items.findIndex(
			(item) => item.id === itemId
		)

		let subsectionIndex = state.sectionSelected.items[
			itemIndex
		].subsections.findIndex(
			(subsection) => subsection.id === newSubitem.subsection_id
		)

		state.sectionSelected.items[itemIndex].subsections[
			subsectionIndex
		].subitems.push(newSubitem)
	},
	REMOVE_SUBITEM(state, payload) {
		const { itemId, subsectionId, index } = payload

		let itemIndex = state.sectionSelected.items.findIndex(
			(item) => item.id === itemId
		)

		let subsectionIndex = state.sectionSelected.items[
			itemIndex
		].subsections.findIndex((subsection) => subsection.id === subsectionId)

		state.sectionSelected.items[itemIndex].subsections[
			subsectionIndex
		].subitems.splice(index, 1)
	},
	SWITCH_SUBITEM(state, payload) {
		const { itemId, subsectionId, index, is_available } = payload

		let itemIndex = state.sectionSelected.items.findIndex(
			(item) => item.id === itemId
		)

		let subsectionIndex = state.sectionSelected.items[
			itemIndex
		].subsections.findIndex((subsection) => subsection.id === subsectionId)

		state.sectionSelected.items[itemIndex].subsections[
			subsectionIndex
		].subitems[index].is_available = is_available
	},
	UPDATE_SUBITEM(state, payload) {
		const { itemId, subsectionId, index, data } = payload

		let itemIndex = state.sectionSelected.items.findIndex(
			(item) => item.id === itemId
		)

		let subsectionIndex = state.sectionSelected.items[
			itemIndex
		].subsections.findIndex((subsection) => subsection.id === subsectionId)

		for (const key in data) {
			state.sectionSelected.items[itemIndex].subsections[
				subsectionIndex
			].subitems[index][key] = data[key]
		}
	},
	SORT_SECTIONS(state, payload) {
		const { positions } = payload

		positions.forEach((element) => {
			const index = state.sections.findIndex(
				(section) => section.id === element.id
			)
			state.sections[index].position = element.position
		})
	},
	SORT_ITEMS(state, payload) {
		const { positions } = payload

		positions.forEach((element) => {
			const index = state.sectionSelected.items.findIndex(
				(item) => item.id === element.id
			)
			state.sectionSelected.items[index].position = element.position
		})
	},
	SORT_SUBSECTIONS(state, payload) {
		const { positions, itemId } = payload
		const itemIndex = state.sectionSelected.items.findIndex(
			(item) => item.id === itemId
		)

		positions.forEach((element) => {
			const index = state.sectionSelected.items[
				itemIndex
			].subsections.findIndex(
				(subsection) => subsection.id === element.id
			)
			state.sectionSelected.items[itemIndex].subsections[index].position =
				element.position
		})
	},
	SORT_SUBITEMS(state, payload) {
		const { positions, itemId, subsectionId } = payload

		const itemIndex = state.sectionSelected.items.findIndex(
			(item) => item.id === itemId
		)
		const subsecIndex = state.sectionSelected.items[
			itemIndex
		].subsections.findIndex((subsection) => subsection.id === subsectionId)

		positions.forEach((element) => {
			const index = state.sectionSelected.items[itemIndex].subsections[
				subsecIndex
			].subitems.findIndex((subsection) => subsection.id === element.id)
			state.sectionSelected.items[itemIndex].subsections[
				subsecIndex
			].subitems[index].position = element.position
		})
	},
}

const getters = {
	isLoading: (state) => state.loading,
	addingSections: (state) => state.adding,
	isLoadingSubsections: (state) => state.loadingSubsections,
	allSections: (state) => state.sections,
	sectionsCount: (state) => state.sections.length,
	sectionSelected: (state) => {
		if (!state.sectionSelected && state.sections.length > 0) {
			state.sectionSelected = state.sections[0]
		}
		return state.sectionSelected
	},
}

export const menu = {
	namespaced: true,
	state,
	actions,
	mutations,
	getters,
}
